import environment from '@/environment'
import { CUSTOMER, CUSTOMERS } from '@/features/integration/mocks/customers'
import type { Customer, CustomersPage } from '@/features/integration/types'

export const CUSTOMERS_API_URL = `${environment.services.integrationsManagerApiUrl}/customers`
export const GET_CUSTOMERS_API_ID = 'GET_CUSTOMERS'
export const GET_CUSTOMER_API_ID = 'GET_CUSTOMER'

export async function getCustomers(): Promise<CustomersPage> {
  return Promise.resolve({
    customers: CUSTOMERS,
    currentPage: 0,
    totalCount: CUSTOMERS.length,
  })
}

export async function getCustomer(): Promise<Customer> {
  return Promise.resolve(CUSTOMER)
}

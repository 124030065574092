import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { upsertResourceSchedule } from '@/features/resource/endpoints/resourceSchedules'
import type { UpsertResourceSchedule } from '@/features/resource/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseUpsertResourceScheduleMutationResult = Omit<
  UseMutationResult<void, Error, UpsertResourceSchedule>,
  'mutateAsync'
> & {
  upsertResourceSchedule: UseMutationResult<void, Error, UpsertResourceSchedule>['mutateAsync']
}

export function useUpsertResourceScheduleMutation(
  options?: UseMutationOptions<void, UpsertResourceSchedule>,
): UseUpsertResourceScheduleMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: UpsertResourceSchedule) => upsertResourceSchedule(variables),
  })

  return { upsertResourceSchedule: mutateAsync, ...mutationResult }
}

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_RESOURCE_API_ID, getResource } from '@/features/resource/endpoints/resources'
import type { Resource, ResourceDetails } from '@/features/resource/types'
import type { UseQueryOptions } from '@/types/queries'

type ResourceQueryParams = {
  id: string
}
export type UseResourceQueryResult = Omit<UseQueryResult, 'data'> & {
  resource?: Resource
}

export function useResourceQuery(
  params: ResourceQueryParams,
  options?: UseQueryOptions<ResourceDetails>,
): UseResourceQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_RESOURCE_API_ID, params],
    queryFn: () => getResource(params.id),
  })

  return {
    resource: data?.resourceResponse,
    ...queryResult,
  }
}

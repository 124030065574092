import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent, Stack } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import FormDialogActions from '@/components/feedback/FormDialogActions'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { getCreateActivationGroupSchema } from '@/features/activationGroup/schemas'
import type { CreateActivationGroup } from '@/features/activationGroup/types'
import { ApiVolumeRangeUnit } from '@/features/activationGroup/types'
import { MarketProgram } from '@/features/bidding/constants'
import type { Portfolio } from '@/features/bidding/types/bid'

type CreateActivationGroupDialogFormProps = {
  portfolios: Portfolio[]
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: CreateActivationGroup) => void
}

/**
 * Contains all the logic related to the form to create a new activation group.
 *
 * It is used within the CreateActivationGroupDialog component.
 */
const CreateActivationGroupDialogForm = ({
  isLoading,
  onCancel,
  onSubmit,
  portfolios,
}: CreateActivationGroupDialogFormProps) => {
  const { t } = useTranslation()
  const form = useForm<CreateActivationGroup>({
    defaultValues: {
      name: '',
      portfolio: '',
      biddableVolumeRange: {
        min: 0,
        max: 0,
        unit: ApiVolumeRangeUnit.WATTS,
      },
    },
    resolver: zodResolver(getCreateActivationGroupSchema(t), {
      async: false,
    }),
  })

  function handleSubmit(newActivationGroup: CreateActivationGroup) {
    onSubmit(newActivationGroup)
  }

  return (
    <FormProvider {...form}>
      <form noValidate aria-label={t('activation_groups.create_form.title')} onSubmit={form.handleSubmit(handleSubmit)}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextFieldController required id="name" label={t('common.name')} name="name" />
          <SelectFieldController
            required
            id="portfolio"
            label={t('activation_groups.create_form.portfolio_label')}
            name="portfolio"
            options={
              portfolios?.map((portfolio) => ({
                value: portfolio.code,
                label: portfolio.code,
                id: portfolio.code,
              })) ?? []
            }
          />

          <SelectFieldController
            required
            id="market-program"
            label={t('common.market_program.label')}
            name="marketProgram"
            options={Object.values(MarketProgram).map((marketProgram) => ({
              value: marketProgram,
              label: t(`bidding.market_program.${marketProgram}`),
              id: marketProgram,
            }))}
          />
          <Stack flexDirection="column" gap={3}>
            <CustomTypography variant="h3">{t('activation_groups.create_form.bidding_limits_label')}</CustomTypography>

            <Stack flexDirection="column" gap={3}>
              <TextFieldController
                id="biddable-volume-range-min"
                label={t('activation_groups.create_form.biddable_volume_range_min_label')}
                name="biddableVolumeRange.min"
                type="number"
              />

              <TextFieldController
                id="biddable-volume-range-max"
                label={t('activation_groups.create_form.biddable_volume_range_max_label')}
                name="biddableVolumeRange.max"
                type="number"
              />

              <SelectFieldController
                fullWidth
                id="biddable-volume-range-unit"
                label={t('activation_groups.create_form.biddable_volume_range_unit_label')}
                name="biddableVolumeRange.unit"
                options={Object.values(ApiVolumeRangeUnit).map((unit) => ({
                  value: unit,
                  label: t(
                    `activation_groups.volume_range_units.${unit.toLowerCase() as Lowercase<ApiVolumeRangeUnit>}`,
                  ),
                  id: unit,
                }))}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <FormDialogActions isLoading={isLoading} onCancel={onCancel} />
      </form>
    </FormProvider>
  )
}

export default CreateActivationGroupDialogForm

import { roundWattsToNearest10KW } from '@/features/bidding/utils/calculations/convertToRoundedMw'

export default (
  totalPriceChunkOfferInWatts: number,
  offeredCapacityByActivationGroup: { activationGroupUuid: string; offered: number }[],
): { [activationGroupUuid: string]: number } => {
  const totalOfferedCapacityForPtuInWatts = offeredCapacityByActivationGroup.reduce(
    (total, val) => total + val.offered,
    0,
  )

  let remainingPriceChunkCapacityInWatts = totalPriceChunkOfferInWatts
  const result: { [activationGroupUuid: string]: number } = {}
  for (const activationGroupOfferedCapacity of offeredCapacityByActivationGroup) {
    const activationGroupBidRatio =
      totalOfferedCapacityForPtuInWatts !== 0
        ? activationGroupOfferedCapacity.offered / totalOfferedCapacityForPtuInWatts
        : 1 / Object.values(offeredCapacityByActivationGroup).length
    let allocatedPriceChunkCapacityInWatts = roundWattsToNearest10KW(
      totalPriceChunkOfferInWatts,
      activationGroupBidRatio,
    )
    if (allocatedPriceChunkCapacityInWatts > remainingPriceChunkCapacityInWatts) {
      // to avoid allocating a negative capacity value to an activation group
      allocatedPriceChunkCapacityInWatts = remainingPriceChunkCapacityInWatts
    }
    result[activationGroupOfferedCapacity.activationGroupUuid] = allocatedPriceChunkCapacityInWatts
    remainingPriceChunkCapacityInWatts -= allocatedPriceChunkCapacityInWatts
  }

  const totalSplitCapacity = Object.values(result).reduce((total, val) => total + val, 0)

  if (totalOfferedCapacityForPtuInWatts === 0) {
    // Adjust the allocation of the first activation group by the remaining rounding error
    // to ensure the total allocated capacity matches the total price chunk offer.
    // This adjustment in done to keep the offered value in memory and to show it to the user in the UI.
    const firstActivationGroup = Object.keys(result)[0]
    result[firstActivationGroup] += totalPriceChunkOfferInWatts - totalSplitCapacity
  } else {
    // Adjust the allocation of the first activation group with a non-zero offer by the remaining rounding error
    // to ensure the total allocated capacity matches the total price chunk offer.
    const remainingRoundingErrorToStillAllocate = totalPriceChunkOfferInWatts - totalSplitCapacity
    const firstActivationGroupWithOffer = offeredCapacityByActivationGroup.find(
      (activationGroup) => activationGroup.offered > 0,
    )
    if (firstActivationGroupWithOffer) {
      result[firstActivationGroupWithOffer.activationGroupUuid] += remainingRoundingErrorToStillAllocate
    }
  }

  return result
}

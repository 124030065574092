import { Grid2 as Grid, Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { INTERFACE_LANGUAGES } from '@/constants/interfaceLanguages'
import type { User } from '@/features/user/types/user'

type UserProfileAccountSectionProps = {
  user: Partial<User>
}

function UserProfileAccountSection({ user }: Readonly<UserProfileAccountSectionProps>) {
  const { t } = useTranslation()

  const userRole = user.role ?? null

  return (
    <Grid container mt={2} spacing={2}>
      <Grid size={{ md: 6, xs: 12 }}>
        <TextField disabled fullWidth label={t('common.username')} value={user.username} />
      </Grid>
      <Grid size={{ md: 6, xs: 12 }}>
        <TextField
          disabled
          fullWidth
          label={t('user_profile.settings.form.role_label')}
          value={userRole ? t(`users.role.${userRole}`) : ''}
        />
      </Grid>
      <Grid size={{ md: 6, xs: 12 }}>
        <TextFieldController label={t('user_profile.settings.form.given_name_label')} name="givenName" />
      </Grid>
      <Grid size={{ md: 6, xs: 12 }}>
        <TextFieldController label={t('user_profile.settings.form.family_name_label')} name="familyName" />
      </Grid>
      <Grid size={{ md: 6, xs: 12 }}>
        <Stack spacing={2}>
          <TextFieldController required label={t('user_profile.settings.form.email_label')} name="email" />

          <SelectFieldController
            id="interface-language"
            label={t('user_form.form.interface_language_label')}
            name="uiLanguage"
            options={Object.values(INTERFACE_LANGUAGES).map((lang) => ({
              id: lang.value,
              value: lang.value,
              label: t(`common.interface_language.${lang.value}`),
            }))}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default UserProfileAccountSection

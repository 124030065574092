import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import SecuritySectionWithOptions from '@/features/user/components/form/SecuritySectionWithOptions'
import SecuritySectionWithoutOptions from '@/features/user/components/form/SecuritySectionWithoutOptions'
import type { User } from '@/features/user/types/user'

type SecuritySectionProps = {
  user: Partial<User>
}

function SecuritySection({ user }: Readonly<SecuritySectionProps>) {
  const { isEnabled } = useFeatureToggle()

  // In case the feature toggle is not enabled, we use the same component for create and edit mode.
  if (!isEnabled('FLEXPORTAL_MFA_TOTP_ENABLED')) {
    return <SecuritySectionWithoutOptions />
  }

  return <SecuritySectionWithOptions user={user} />
}

export default SecuritySection

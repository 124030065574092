import { DateTime } from 'luxon'
import type { DateTime as DateTimeType } from 'luxon/src/datetime'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Period } from '@/constants/period'
import BaselineChart from '@/features/report/components/BaselineChart'
import {
  useInvalidateLiveXyBaselineQuery,
  useLiveXyBaselineQuery,
} from '@/features/report/hooks/useLiveXyBaselineQuery'
import { calculatePeriodStartTime } from '@/features/report/utils/utils'

const LIVE_LINE_COLOR = '#ff4646'

type LiveXyBaselineChartProps = {
  portfolio: string
  timezone: string
}

function LiveXyBaselineChart({ portfolio, timezone }: Readonly<LiveXyBaselineChartProps>) {
  const { t } = useTranslation()

  const [startTime, setStartTime] = useState<DateTimeType>(calculatePeriodStartTime(Period.PAST_24_HOURS))
  const [endTime, setEndTime] = useState<DateTimeType>(DateTime.now())

  const { isLoading, timeData, baselineSeries } = useLiveXyBaselineQuery({ startTime, endTime, portfolio })

  const invalidateLiveXyBaselineQuery = useInvalidateLiveXyBaselineQuery()

  useEffect(() => {
    invalidateLiveXyBaselineQuery()
  }, [startTime, endTime, portfolio])

  return (
    <BaselineChart
      baselineSeries={baselineSeries}
      endTime={endTime}
      infoText={t('reports.baseline.live.info')}
      initialPeriodSelection={Period.PAST_24_HOURS}
      isLoading={isLoading}
      lineColor={LIVE_LINE_COLOR}
      setEndTime={setEndTime}
      setStartTime={setStartTime}
      startTime={startTime}
      timeData={timeData}
      timezone={timezone}
      title={t(`reports.baseline.live.title`)}
    />
  )
}

export default LiveXyBaselineChart

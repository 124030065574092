import { zodResolver } from '@hookform/resolvers/zod'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, CardHeader, Stack, Tooltip, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CheckboxController from '@/components/inputs/CheckboxController'
import CustomButton from '@/components/inputs/CustomButton'
import TextFieldController from '@/components/inputs/TextFieldController'
import { GET_RESOURCE_API_ID, updateSteeringCapabilities } from '@/features/resource/endpoints/resources'
import { SteeringCapabilitiesSchema } from '@/features/resource/schemas'
import type { Resource, ResourceSteeringConfig, SteeringCapabilitiesSchemaType } from '@/features/resource/types'

export type SteeringCapabilitiesPanelProps = {
  resourceID: Resource['resourceID']
  steeringConfig: ResourceSteeringConfig | undefined
  onUpdateSteeringCapabilities: (data: SteeringCapabilitiesSchemaType) => void
}

const SteeringCapabilitiesTooltipContent = () => {
  const { t } = useTranslation()

  return (
    <div style={{ maxWidth: 300 }}>
      <Typography sx={{ color: 'white', fontWeight: 'bold', mb: 1 }} variant="body2">
        {t('resources.steering.steering_capabilities.tooltip.title')}
      </Typography>
      <Typography sx={{ color: 'white', mb: 1 }} variant="body2">
        {t('resources.steering.steering_capabilities.tooltip.description')}
      </Typography>
      <Typography sx={{ color: 'white' }} variant="body2">
        {t('resources.steering.steering_capabilities.tooltip.min_time_description')}
      </Typography>
    </div>
  )
}

const SteeringCapabilitiesPanel = ({
  steeringConfig,
  resourceID,
  onUpdateSteeringCapabilities,
}: SteeringCapabilitiesPanelProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState<boolean>(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (steeringConfig) {
      setValue('isReleaseControlSupported', steeringConfig.isReleaseControlSupported)
      setValue('minReleaseControlTimeInSeconds', steeringConfig.minReleaseControlTimeInSeconds)
    }
  }, [steeringConfig])

  const form = useForm<SteeringCapabilitiesSchemaType>({
    defaultValues: {
      isReleaseControlSupported: steeringConfig?.isReleaseControlSupported ?? false,
      minReleaseControlTimeInSeconds: steeringConfig?.minReleaseControlTimeInSeconds ?? null,
    },
    resolver: zodResolver(SteeringCapabilitiesSchema),
  })

  const { handleSubmit, watch, setValue } = form
  const isReleaseControlSupported = watch('isReleaseControlSupported')

  const updateSteeringCapabilitiesMutation = useMutation({
    mutationFn: (data: SteeringCapabilitiesSchemaType) => {
      return updateSteeringCapabilities(resourceID, data.isReleaseControlSupported, data.minReleaseControlTimeInSeconds)
    },
    onSuccess: (_, variables) => {
      onUpdateSteeringCapabilities(variables)
      queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceID }] })
      setEditMode(false)
    },
    onError: (error) => {
      console.error('Failed to update steering capabilities:', error)
    },
  })

  function onOpenEditMode() {
    setEditMode(true)
  }

  function onCloseEditMode() {
    setEditMode(false)
    form.reset()
  }

  function onSave(data: SteeringCapabilitiesSchemaType) {
    updateSteeringCapabilitiesMutation.mutate(data)
  }

  return (
    <>
      <CardHeader
        sx={{ paddingX: 0 }}
        title={
          <Stack alignItems="center" direction="row" spacing={1}>
            <CustomTypography variant="h6">{t('resources.steering.steering_capabilities.label')}</CustomTypography>
            <Tooltip arrow placement="right-start" title={<SteeringCapabilitiesTooltipContent />}>
              <HelpOutlineIcon fontSize="small" sx={{ color: 'black' }} />
            </Tooltip>
          </Stack>
        }
      />
      {editMode ? (
        <FormProvider {...form}>
          <form aria-label={t('resources.steering.steering_capabilities.label')} onSubmit={handleSubmit(onSave)}>
            <Stack alignItems={'flex-start'} direction={'column'} sx={{ gap: 3 }}>
              <CheckboxController
                disabled={steeringConfig?.steeringDefaultType === 'RELEASE_CONTROL'}
                label={t('resources.steering.steering_capabilities.supports_release_control')}
                name="isReleaseControlSupported"
                onChange={(e) => {
                  form.setValue('isReleaseControlSupported', e.target.checked)
                  if (!e.target.checked) {
                    form.setValue('minReleaseControlTimeInSeconds', null)
                  }
                }}
              />
              {steeringConfig?.steeringDefaultType === 'RELEASE_CONTROL' && (
                <Typography color="textSecondary" sx={{ ml: 3, fontStyle: 'italic' }} variant="body2">
                  {t('resources.steering.steering_capabilities.cannot_disable_release_control')}
                </Typography>
              )}
              {(isReleaseControlSupported || steeringConfig?.steeringDefaultType === 'RELEASE_CONTROL') && (
                <TextFieldController
                  label={t('resources.steering.steering_capabilities.min_release_control_time')}
                  name="minReleaseControlTimeInSeconds"
                  sx={{ width: 400 }}
                  type="number"
                  onChange={(e) =>
                    form.setValue(
                      'minReleaseControlTimeInSeconds',
                      e.target.value === '' ? null : Number(e.target.value),
                    )
                  }
                />
              )}
              <Box>
                <Stack direction={'row'} gap={2}>
                  <CustomButton
                    disabled={updateSteeringCapabilitiesMutation.isPending}
                    type="submit"
                    variant="contained"
                  >
                    {t('common.button.save')}
                  </CustomButton>
                  <CustomButton variant="outlined" onClick={onCloseEditMode}>
                    {t('common.button.cancel')}
                  </CustomButton>
                </Stack>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      ) : (
        <Stack alignItems={'flex-start'} direction={'column'} sx={{ gap: 3, paddingBottom: 3 }}>
          <CustomTypography sx={{ paddingX: 3 }} variant={'body1'}>
            {steeringConfig?.isReleaseControlSupported
              ? t('resources.steering.steering_capabilities.supports_release_control_message')
              : t('resources.steering.steering_capabilities.does_not_support_release_control_message')}
            {steeringConfig?.isReleaseControlSupported &&
              (steeringConfig?.minReleaseControlTimeInSeconds !== null
                ? t('resources.steering.steering_capabilities.min_release_control_time_message', {
                    seconds: steeringConfig.minReleaseControlTimeInSeconds,
                  })
                : t('resources.steering.steering_capabilities.no_min_release_control_time_message'))}
          </CustomTypography>
          <CustomButton variant={'outlined'} onClick={onOpenEditMode}>
            {t('resources.steering.steering_capabilities.edit')}
          </CustomButton>
        </Stack>
      )}
    </>
  )
}

export default SteeringCapabilitiesPanel

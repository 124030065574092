import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { USER_ROLES } from '@/constants/userRoles'
import type { BffActivation } from '@/features/activation/types/activation'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { GET_BFF_ACTIVATIONS_API_ID, getActivations } from '@/features/customer/endpoints/bffActivations'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import type { UseQueryOptions } from '@/types/queries'

type ActivationsFromBffQueryParams = {
  siteId: string
}
export type UseActivationsFromBffQueryResult = Omit<UseQueryResult, 'data'> & {
  bffActivations: BffActivation[] | null
}

export function useActivationsFromBffQuery(
  params: ActivationsFromBffQueryParams,
  options?: UseQueryOptions<BffActivation[]>,
): UseActivationsFromBffQueryResult {
  const { loggedInUser } = useAuth()
  const { isEnabled } = useFeatureToggle()

  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BFF_ACTIVATIONS_API_ID, params],
    queryFn: () => {
      if (
        loggedInUser?.role === USER_ROLES.PARTNER_ADMINISTRATORS.value ||
        !isEnabled('FLEXPORTAL_USE_ACTIVATIONS_BFF')
      )
        return []
      return getActivations(params.siteId)
    },
  })

  return { bffActivations: data, ...queryResult }
}

import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import PageHeader from '@/components/layouts/PageHeader'
import ExampleFeatureChart from '@/features/exampleFeature/components/ExampleFeatureChart'
import ExampleFeatureGrid from '@/features/exampleFeature/components/ExampleFeatureGrid'
import { useExampleFeatureQuery } from '@/features/exampleFeature/hooks/useExampleFeatureQuery'

const ExampleFeatureIndex = () => {
  const { t } = useTranslation()
  const { exampleFeatureValues } = useExampleFeatureQuery()

  return (
    <>
      <PageHeader isRoot pageTitle={t('component.page_header.example_feature')} />

      <MainContentContainer>
        <ExampleFeatureChart exampleFeatureValues={exampleFeatureValues ?? []} />
      </MainContentContainer>

      <ExampleFeatureGrid exampleFeatureValues={exampleFeatureValues ?? []} />
    </>
  )
}

export default ExampleFeatureIndex

import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import { MINUTE_m_SECONDS_s_FORMAT } from '@/constants/dateTimeFormats'
import type { Activation } from '@/features/activation/types/activation'
import { formatLocalizedDateTimeMedWithSeconds } from '@/features/activation/utils/formatLocalizedDateTimeMedWithSeconds'
import getDuration from '@/utils/time'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends ActivationDetailsToolbarProps {}
}

type ActivationDetailsToolbarProps = {
  activation?: Activation
  customerTimeZone: string
}

const DASH_VALUE = '-'

export const ActivationDetailsToolbar = ({ activation, customerTimeZone }: ActivationDetailsToolbarProps) => {
  const { t, i18n } = useTranslation()
  return (
    <Stack direction="row" marginBottom={2} spacing={2}>
      <LabeledTextField
        label={t('customer_details.activations.details.activation_began_at.label')}
        text={formatLocalizedDateTimeMedWithSeconds(customerTimeZone, i18n.language, activation?.startedAt)}
        variant={'filled'}
      />
      <LabeledTextField
        label={t('customer_details.activations.details.activation_ended_at.label')}
        text={formatLocalizedDateTimeMedWithSeconds(customerTimeZone, i18n.language, activation?.endedAt)}
        variant={'filled'}
      />
      <LabeledTextField
        label={t('customer_details.activations.details.duration.label')}
        text={formatActivationDurationSummary(activation?.startedAt, activation?.endedAt)}
        variant={'filled'}
      />
      <LabeledTextField
        label={t('customer_details.activations.details.market_program.label')}
        text={activation?.marketProgram ? t(`common.market_program.${activation.marketProgram}`) : DASH_VALUE}
        variant={'filled'}
      />
    </Stack>
  )
}

function formatActivationDurationSummary(startedAt?: string, endedAt?: string) {
  if (!startedAt || !endedAt) {
    return DASH_VALUE
  }
  return getDuration(startedAt, endedAt).toFormat(MINUTE_m_SECONDS_s_FORMAT).replace('00 mins. and ', '')
}

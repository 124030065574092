import type { CSSProperties } from 'react'

type CloudBackground2Props = {
  style?: CSSProperties
}

const GreyAndWhiteCloud = ({ style }: CloudBackground2Props) => {
  return (
    <svg fill="none" height="62" style={style} viewBox="0 0 251 62" width="251" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M250.87 59.5458C252.788 45.7203 233.122 34.347 218.346 33.1667C211.299 32.6014 203.981 33.6723 197.303 31.3634C182.839 26.3615 177.064 7.82067 162.949 1.91508C152.402 -2.50167 140.253 1.42942 130.024 6.53884C119.798 11.6463 109.689 18.0733 98.259 18.7998C94.9189 19.0128 91.4826 18.7401 88.0603 18.4674C81.302 17.93 74.6019 17.3926 68.8265 20.5912C62.349 24.1819 58.9548 31.5903 52.9808 35.9613C47.1373 40.2387 39.5143 41.1443 32.2645 41.5345C25.0148 41.9266 17.5062 42.0002 10.9043 44.9998C4.3045 47.9974 -1.15792 54.924 0.212194 62L250.87 59.5458Z"
        fill="#EEEEEE"
      />
      <path
        d="M233.937 40.8122C234.862 34.1233 225.383 28.6202 218.261 28.0492C214.863 27.7746 211.336 28.2919 208.117 27.1758C201.143 24.7545 198.359 15.7835 191.557 12.9265C186.47 10.7897 180.613 12.6917 175.683 15.1628C170.751 17.6338 165.879 20.7455 160.369 21.0956C155.579 21.402 150.373 19.6313 146.181 21.9631C143.057 23.7 141.421 27.2852 138.54 29.4001C135.724 31.4692 132.048 31.9089 128.554 32.0979C125.058 32.2869 121.44 32.3228 118.258 33.7732C115.074 35.2255 112.443 38.5779 113.102 42L233.937 40.8122Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}

export default GreyAndWhiteCloud

import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import { ActivationDetails } from '@/features/activation/pages/ActivationDetails'
import { Activations } from '@/features/activation/pages/Activations'
import NavigateToError from '@/features/error/pages/NavigateToError'

export default function ActivationRoutes() {
  const { t } = useTranslation()

  return (
    <Routes>
      <Route element={<Activations />} path="/" />
      <Route
        element={
          <>
            <PageHeader pageTag={'activation-details'} pageTitle={t('component.page_header.activation.details')} />
            <ActivationDetails />
          </>
        }
        path="/:id"
      />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}

import type { ExtraCriteriaWeekday } from '@/features/resource/types/spotOnForEboilers'

export const DAY_NAMES = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const DEFAULT_PERIODS = [
  {
    startTimeAt: '00:00',
    endTimeAt: '01:00',
  },
  {
    startTimeAt: '01:00',
    endTimeAt: '02:00',
  },
  {
    startTimeAt: '02:00',
    endTimeAt: '03:00',
  },
  {
    startTimeAt: '03:00',
    endTimeAt: '04:00',
  },
  {
    startTimeAt: '04:00',
    endTimeAt: '05:00',
  },
  {
    startTimeAt: '05:00',
    endTimeAt: '06:00',
  },
  {
    startTimeAt: '06:00',
    endTimeAt: '07:00',
  },
  {
    startTimeAt: '07:00',
    endTimeAt: '08:00',
  },
  {
    startTimeAt: '08:00',
    endTimeAt: '09:00',
  },
  {
    startTimeAt: '09:00',
    endTimeAt: '10:00',
  },
  {
    startTimeAt: '10:00',
    endTimeAt: '11:00',
  },
  {
    startTimeAt: '11:00',
    endTimeAt: '12:00',
  },
  {
    startTimeAt: '12:00',
    endTimeAt: '13:00',
  },
  {
    startTimeAt: '13:00',
    endTimeAt: '14:00',
  },
  {
    startTimeAt: '14:00',
    endTimeAt: '15:00',
  },
  {
    startTimeAt: '15:00',
    endTimeAt: '16:00',
  },
  {
    startTimeAt: '16:00',
    endTimeAt: '17:00',
  },
  {
    startTimeAt: '17:00',
    endTimeAt: '18:00',
  },
  {
    startTimeAt: '18:00',
    endTimeAt: '19:00',
  },
  {
    startTimeAt: '19:00',
    endTimeAt: '20:00',
  },
  {
    startTimeAt: '20:00',
    endTimeAt: '21:00',
  },
  {
    startTimeAt: '21:00',
    endTimeAt: '22:00',
  },
  {
    startTimeAt: '22:00',
    endTimeAt: '23:00',
  },
  {
    startTimeAt: '23:00',
    endTimeAt: '00:00',
  },
].map((item) => ({
  ...item,
  value: '',
}))

export const DEFAULT_EXTRA_CRITERIA_WEEKDAYS: ExtraCriteriaWeekday[] = [1, 2, 3, 4, 5, 6, 7].map((dayNumber) => ({
  dayNumber,
  periods: DEFAULT_PERIODS.map((period) => ({ ...period })), // Deep copy each object
}))

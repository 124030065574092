import type { AxiosError } from 'axios'
import axios from 'axios'

import environment from '@/environment'
import type {
  ApiCreateScheduledResource,
  ApiScheduledResource,
  Customer,
  ExtraCriteria,
  Schedule,
  ScheduleProgram,
} from '@/features/resource/types/spotOnForEboilers'

export const RESOURCE_SCHEDULE_BUILDER_API_URL = `${environment.services.resourceScheduleBuilderApiUrl}`

export const GET_SPOT_ON_SCHEDULE_PROGRAM_API_ID = 'GET_SPOT_ON_SCHEDULE_PROGRAM'
export const GET_SPOT_ON_CUSTOMERS_API_ID = 'GET_SPOT_ON_CUSTOMERS'
export const GET_SPOT_ON_SCHEDULED_RESOURCE_API_ID = 'GET_SPOT_ON_SCHEDULED_RESOURCE'
export const GET_SPOT_ON_CUSTOMER_API_ID = 'GET_SPOT_ON_CUSTOMER'
export const GET_SPOT_ON_SCHEDULES_API_ID = 'GET_SPOT_ON_SCHEDULES'
export const GET_SPOT_ON_SCHEDULE_API_ID = 'GET_SPOT_ON_SCHEDULE'

export async function getSpotOnScheduleProgram(): Promise<ScheduleProgram> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/spoton`
  const response = await axios.get(API_URL)

  return response.data
}

export async function getCustomers(scheduleProgramId: string): Promise<Customer[]> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/programs/${scheduleProgramId}/resource-owners`
  const response = await axios.get(API_URL)

  return response.data
}

export async function getCustomer(scheduleProgramId: string, customerId: string): Promise<Customer | null> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/programs/${scheduleProgramId}/resource-owners/${customerId}`
  try {
    const response = await axios.get(API_URL)
    return response.data
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return null
    }

    throw err
  }
}

export async function getExtraCriteria(scheduleProgramId: string, customerId: string): Promise<ExtraCriteria[]> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/programs/${scheduleProgramId}/resource-owners/${customerId}/extra-criteria`
  try {
    const response = await axios.get(API_URL)
    return response.data
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return []
    }

    throw err
  }
}

export async function updateExtraCriteria(
  scheduleProgramId: string,
  customerId: string,
  extraCriteria: ExtraCriteria,
): Promise<ExtraCriteria | null> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/programs/${scheduleProgramId}/resource-owners/${customerId}/extra-criteria/${extraCriteria.id!}`
  const response = await axios.put(API_URL, extraCriteria)

  return response.data
}

export async function getScheduledResource(resourceId: string): Promise<any> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/scheduled-resources/${resourceId}`
  try {
    const response = await axios.get(API_URL)

    return response.data
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return {
        trigger: null,
      }
    }

    throw err
  }
}

export async function createScheduledResource(
  newScheduledResource: ApiCreateScheduledResource,
): Promise<ApiScheduledResource> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/scheduled-resources`
  const response = await axios.post(API_URL, newScheduledResource)

  return response.data
}

export async function getSchedules(resourceId: string): Promise<Schedule[]> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/scheduled-resources/${resourceId}/schedules`

  try {
    const response = await axios.get(API_URL)

    return response.data
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return []
    }

    throw err
  }
}

export async function getSchedule(resourceId: string, activeDay: string): Promise<Schedule | null> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/scheduled-resources/${resourceId}/schedules/${activeDay}`

  try {
    const response = await axios.get(API_URL)

    return response.data
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return null
    }

    throw err
  }
}

export async function createSchedule({ activeDay, resourceId }): Promise<Schedule> {
  const API_URL = `${RESOURCE_SCHEDULE_BUILDER_API_URL}/scheduled-resources/${resourceId}/schedules/${activeDay}`
  const response = await axios.post(API_URL)

  return response.data
}

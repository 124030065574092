/* eslint-disable sonarjs/no-array-index-key */
import type { TableContainerProps } from '@mui/material'
import { Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper'

interface DataGridSkeletonProps {
  includeWrapper: boolean
  numberOfColumns: number
  numberOfRows: number
  withToolbar?: boolean
  sx?: TableContainerProps['sx']
}

const DEFAULT_ROW_SIZE = '56px'
const DEFAULT_ROUNDED_SKELETON_HEIGHT = '12.5px'

const ToolbarSkeleton = () => {
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        px: 2,
        height: DEFAULT_ROW_SIZE,
      }}
    >
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <Skeleton
          sx={{ '&.MuiSkeleton-root': { height: DEFAULT_ROUNDED_SKELETON_HEIGHT, width: '196px' } }}
          variant="rounded"
        />
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <Skeleton
            sx={{ '&.MuiSkeleton-root': { height: DEFAULT_ROUNDED_SKELETON_HEIGHT, width: '40px' } }}
            variant="rounded"
          />
          <Skeleton
            sx={{
              '&.MuiSkeleton-root': { height: DEFAULT_ROUNDED_SKELETON_HEIGHT, width: '106px' },
            }}
            variant="rounded"
          />
        </Stack>
        <Skeleton
          sx={{ '&.MuiSkeleton-root': { height: DEFAULT_ROUNDED_SKELETON_HEIGHT, width: '78px' } }}
          variant="rounded"
        />
        <Skeleton
          sx={{
            '&.MuiSkeleton-root': { height: '24px', width: '24px' },
          }}
          variant="circular"
        />
      </Stack>
    </Stack>
  )
}

const PaginationSkeleton = () => {
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        px: 2,
        height: DEFAULT_ROW_SIZE,
      }}
    >
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 3.25 }}>
        <Skeleton
          sx={{ '&.MuiSkeleton-root': { height: DEFAULT_ROUNDED_SKELETON_HEIGHT, width: '134px' } }}
          variant="rounded"
        />
        <Skeleton
          sx={{ '&.MuiSkeleton-root': { height: DEFAULT_ROUNDED_SKELETON_HEIGHT, width: '51px' } }}
          variant="rounded"
        />
        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          <Skeleton
            sx={{
              '&.MuiSkeleton-root': {
                height: '12px',
                width: '12px',
              },
            }}
            variant="circular"
          />
          <Skeleton
            sx={{
              '&.MuiSkeleton-root': {
                height: '12px',
                width: '12px',
              },
            }}
            variant="circular"
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

const DataGridSkeleton = ({
  includeWrapper,
  numberOfColumns,
  numberOfRows,
  withToolbar,
  sx,
}: DataGridSkeletonProps) => {
  return (
    <TableContainer component={includeWrapper ? Paper : 'div'} data-testid="data-grid-skeleton" sx={sx}>
      {withToolbar && <ToolbarSkeleton />}

      <Table>
        <TableHead>
          <TableRow sx={{ width: DEFAULT_ROW_SIZE }}>
            {Array.from({ length: numberOfColumns }).map((_, index) => (
              <TableCell key={index}>
                <Skeleton
                  sx={{ '&.MuiSkeleton-root': { height: DEFAULT_ROUNDED_SKELETON_HEIGHT, backgroundColor: '#D2D2D2' } }}
                  variant="rounded"
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
            <TableRow key={rowIndex} sx={{ width: DEFAULT_ROW_SIZE }}>
              {Array.from({ length: numberOfColumns }).map((_, columnIndex) => (
                <TableCell key={columnIndex}>
                  <Skeleton
                    sx={{ '&.MuiSkeleton-root': { height: DEFAULT_ROUNDED_SKELETON_HEIGHT } }}
                    variant="rounded"
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <PaginationSkeleton />
    </TableContainer>
  )
}

export default DataGridSkeleton

function calculatePriceLevel(weight, riskPreference, priceLower, priceUpper) {
  let adjustedWeight
  if (riskPreference >= 0) {
    adjustedWeight = weight ** (1 / (1 + riskPreference)) // Bias towards lower prices for risk-averse
  } else {
    adjustedWeight = weight ** (1 - riskPreference) // Bias towards higher prices for risk-seeking
  }
  const priceLevel: number = priceLower + adjustedWeight * (priceUpper - priceLower)

  return priceLevel
}

export function createPricingVolumeLadder(totalVolume, price, priceLower, priceUpper, riskPreference, numLevels = 5) {
  /**
   * Creates a pricing/volume ladder by allocating the total volume over different price points,
   * based on a single forecasted price with confidence intervals and risk preference.
   *
   * Parameters:
   * - totalVolume: The total volume to allocate.
   * - price: Forecasted marginal price.
   * - priceLower: Lower bound of the confidence interval for the price.
   * - priceUpper: Upper bound of the confidence interval for the price.
   * - riskPreference: Risk preference indicator ranging from -1 to 1.
   *     - -1: Highly risk-averse (focus on lower prices).
   *     - 0: Risk-neutral (use the forecasted price).
   *     - 1: Risk-seeking (focus on higher prices).
   * - numLevels: Number of levels in the pricing ladder (default is 5).
   *
   * Returns:
   * - pricingLadder: Array of objects { allocatedVolume, priceLevel } representing the pricing ladder.
   */

  totalVolume = Number(totalVolume)

  type PricingLadder = {
    level: number
    allocatedVolume: number
    priceLevel: number
  }

  riskPreference = riskPreference * -1

  // Validate inputs
  if (priceLower > priceUpper) {
    ;[priceLower, priceUpper] = [priceUpper, priceLower] // Swap if misordered
  }
  if (price < priceLower || price > priceUpper) {
    throw new Error('Forecasted price must be within the confidence interval.')
  }

  if (totalVolume <= 0) {
    return [
      {
        level: 1,
        allocatedVolume: totalVolume,
        priceLevel: [0],
      },
    ]
  }

  // Generate price levels within the confidence interval
  const priceLevels: number[] = []
  if (numLevels === 1) {
    const scaleValue = (x) => 1 - (x + 2) / 4
    priceLevels.push(calculatePriceLevel(scaleValue(riskPreference), riskPreference, priceLower, priceUpper))
  } else {
    for (let i = 0; i < numLevels; i++) {
      const weight = i / (numLevels - 1) // Weight from 0 to 1
      priceLevels.push(calculatePriceLevel(weight, riskPreference, priceLower, priceUpper))
    }
  }

  // Allocate volume to each price level
  // For simplicity, allocate equal volumes or adjust based on risk preference
  const allocatedVolumes: number[] = []
  const weights: number[] = []

  const a = 5 // Adjust 'a' as needed for smoother or steeper transitions
  const k = -riskPreference * a

  // Calculate weights using the exponential function
  let totalWeight = 0
  for (let i = 0; i < numLevels; i++) {
    const x = (i / (numLevels - 1) - 0.5) * 2 // x ranges from -1 to 1
    const weight = Math.exp(k * x)
    weights.push(weight)
    totalWeight += weight
  }

  // Normalize weights and allocate volumes
  if (numLevels === 1) {
    allocatedVolumes.push(totalVolume)
  } else {
    for (let i = 0; i < numLevels; i++) {
      const normalizedWeight = weights[i] / totalWeight
      const volume = totalVolume * normalizedWeight
      allocatedVolumes.push(volume)
    }
  }

  // Build the pricing ladder
  const pricingLadder: PricingLadder[] = []
  for (let i = 0; i < numLevels; i++) {
    pricingLadder.push({
      level: i + 1,
      allocatedVolume: allocatedVolumes[i],
      priceLevel: priceLevels[i],
    })
  }

  return pricingLadder
}

import { useQueries } from '@tanstack/react-query'
import type { DateTime } from 'luxon'

import type { Timeseries } from '@/features/bidding/endpoints/databricks'
import {
  getSolarActual,
  getSolarForecast,
  getSpotPriceActual,
  getSpotPriceForecast,
} from '@/features/bidding/endpoints/databricks'
import { getBidInsights, getCapacityInsights, getForecastInsights } from '@/features/bidding/endpoints/insights'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { Forecast } from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { ptusBetweenDates } from '@/features/bidding/utils/date/ptusBetweenDates'

const GET_BID_INSIGHTS_API_ID = 'GET_BID_INSIGHTS'
export const GET_CAPACITY_INSIGHTS_API_ID = 'GET_CAPACITY_INSIGHTS'
const GET_FORECAST_INSIGHTS_API_ID = 'GET_FORECAST_INSIGHTS'
const GET_SOLAR_ACTUAL_API_ID = 'GET_SOLAR_ACTUAL'
const GET_SOLAR_FORECAST_API_ID = 'GET_SOLAR_FORECAST'
const GET_SPOT_PRICE_ACTUAL_API_ID = 'GET_SPOT_PRICE_ACTUAL'
const GET_SPOT_PRICE_FORECAST_API_ID = 'GET_SPOT_PRICE_FORECAST'

export type InsightsData = {
  data: {
    capacityActual: (number | null)[]
    capacityOffered: (number | null)[]
    capacityForecast: (number | null)[]
    solarActual: (number | null)[]
    solarForecast: (number | null)[]
    spotPriceActual: (number | null)[]
    spotPriceForecast: (number | null)[]
    ptus: DateTime[]
  } | null
}

export type InsightsDataQueryParams = {
  activationGroupUuid: string
  priceArea: string
  fromDeliveryDay: MarketDate
  toDeliveryDay: MarketDate
}

export const useInsightsDataQuery = (params: InsightsDataQueryParams): InsightsData => {
  const { activationGroupUuid, priceArea, fromDeliveryDay, toDeliveryDay } = params
  const queryResults = useQueries({
    queries: [
      {
        queryKey: [GET_BID_INSIGHTS_API_ID, params],
        queryFn: () => getBidInsights(activationGroupUuid, fromDeliveryDay, toDeliveryDay),
      },
      {
        queryKey: [GET_CAPACITY_INSIGHTS_API_ID, params],
        queryFn: () => getCapacityInsights(params.activationGroupUuid, fromDeliveryDay, toDeliveryDay),
      },
      {
        queryKey: [GET_FORECAST_INSIGHTS_API_ID, params],
        queryFn: () => getForecastInsights(params.activationGroupUuid, fromDeliveryDay, toDeliveryDay),
      },
      {
        queryKey: [GET_SOLAR_ACTUAL_API_ID, params],
        queryFn: () => getSolarActual(priceArea, fromDeliveryDay, toDeliveryDay),
      },
      {
        queryKey: [GET_SOLAR_FORECAST_API_ID, params],
        queryFn: () => getSolarForecast(priceArea, fromDeliveryDay, toDeliveryDay),
      },
      {
        queryKey: [GET_SPOT_PRICE_ACTUAL_API_ID, params],
        queryFn: () => getSpotPriceActual(priceArea, fromDeliveryDay, toDeliveryDay),
      },
      {
        queryKey: [GET_SPOT_PRICE_FORECAST_API_ID, params],
        queryFn: () => getSpotPriceForecast(priceArea, fromDeliveryDay, toDeliveryDay),
      },
    ],
  })

  useErrorHandler(queryResults.some((result) => result.isError))

  const firstPtu = fromDeliveryDay.getStartOfDay()
  const lastPtu = toDeliveryDay.getEndOfDay().minus({ hour: 1 })
  const ptus = ptusBetweenDates(firstPtu, lastPtu)

  const bidPtus = (queryResults[0].data ?? []).flatMap((bid) => bid.offeredBid)
  const bids = toTimestampedValues(
    bidPtus,
    (bidPtu) => bidPtu.ptu.start,
    (bidPtu) => bidPtu.volume.quantity,
  )

  const actualCapacities = toTimestampedValues(
    queryResults[1].data ?? [],
    (capacity) => capacity.startDate,
    (capacity) => capacity.scheduledMaintainedCapacityWatts,
  )

  const forecastPtus = (queryResults[2].data ?? []).flatMap((forecast: Forecast) => forecast.forecastPtus)
  const forecasts = toTimestampedValues(
    forecastPtus,
    (forecastPtu) => forecastPtu.ptu.start,
    (forecastPtu) => forecastPtu.volume.quantity,
  )

  const solarActual = timeseriesToTimestampedValues(queryResults[3].data ?? [])
  const solarForecast = timeseriesToTimestampedValues(queryResults[4].data ?? [])
  const spotPriceActual = timeseriesToTimestampedValues(queryResults[5].data ?? [])
  const spotPriceForecast = timeseriesToTimestampedValues(queryResults[6].data ?? [])

  return {
    data: {
      capacityOffered: getValuesForPtus(bids, ptus),
      capacityActual: getValuesForPtus(actualCapacities, ptus),
      capacityForecast: getValuesForPtus(forecasts, ptus),
      solarActual: getValuesForPtus(solarActual, ptus),
      solarForecast: getValuesForPtus(solarForecast, ptus),
      spotPriceActual: getValuesForPtus(spotPriceActual, ptus),
      spotPriceForecast: getValuesForPtus(spotPriceForecast, ptus),
      ptus: ptus,
    },
  }
}

function getValuesForPtus(timestampedValues: Map<string, number>, ptus: DateTime[]) {
  return ptus.map((ptu) => timestampedValues.get(ptu.toUTC().toISO()!) ?? null)
}

function timeseriesToTimestampedValues(timeseries: Timeseries) {
  return toTimestampedValues(
    timeseries,
    (item) => item.utcTimestamp,
    (item) => item.value,
  )
}

function toTimestampedValues<T>(
  ls: T[],
  timestampGetter: (o: T) => DateTime,
  valueGetter: (o: T) => number,
): Map<string, number> {
  return ls.reduce((map, obj) => {
    map.set(timestampGetter(obj).toUTC().toISO()!, valueGetter(obj))
    return map
  }, new Map<string, number>())
}

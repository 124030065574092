import type { GridColDef } from '@mui/x-data-grid'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DURATION_FORMAT, HOUR_h_MINUTE_m_SECONDS_s_FORMAT } from '@/constants/dateTimeFormats'
import { ActivationDetailsToolbar } from '@/features/activation/components/ActivationDetailsToolbar'
import type { ActivatedResource } from '@/features/activation/types/activatedResource'
import {
  convertBffActivatedResourcesToStandard,
  convertMagActivatedResourcesToStandard,
  convertPresentActivationToStandard,
} from '@/features/activation/utils/converters'
import { useActivatedResourcesFromBffQuery } from '@/features/customer/hooks/useActivatedResourcesFromBffQuery'
import { useActivatedResourcesFromMagQuery } from '@/features/customer/hooks/useActivatedResourcesFromMagQuery'
import { useActivationFromBffQuery } from '@/features/customer/hooks/useActivationFromBffQuery'
import { useActivationFromMagQuery } from '@/features/customer/hooks/useActivationFromMagQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'
import type { Customer } from '@/features/customer/types/customer'
import getDuration, { convertToTimeZoneDateTime } from '@/utils/time'

const DASH_VALUE = '-'

function formatActivatedResourceDuration(startTime?: string, endTime?: string) {
  if (!startTime || !endTime) {
    return DASH_VALUE
  }
  return getDuration(startTime, endTime).toFormat(DURATION_FORMAT).replace('00h ', '')
}

function formatResourceActivationTime(customerTimeZone: string, iso?: string) {
  return iso ? convertToTimeZoneDateTime(customerTimeZone, iso).toFormat(HOUR_h_MINUTE_m_SECONDS_s_FORMAT) : DASH_VALUE
}

function getMarketPrograms(customer?: Customer) {
  return new Map(customer?.services?.map((mp) => [mp.id, mp.type]))
}

function isNumeric(activationId: any) {
  return !isNaN(Number(activationId))
}

export const CustomerActivationDetails = () => {
  const { customer, customerDetailsCommonBreadcrumbs, isFetchingCustomer, setPageConfig } = useCustomerDetails()
  const { t } = useTranslation()
  const { activationId } = useParams()

  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery({
    uuid: customer.uuid!,
  })

  const { magActivation } = useActivationFromMagQuery(
    {
      customerUuid: customer.uuid!,
      activationId: Number(activationId),
      location: customer.location,
    },
    { enabled: !!customer && !isFetchingCustomer && isNumeric(activationId) },
  )

  const { bffActivation } = useActivationFromBffQuery(
    {
      activationId: activationId!,
    },
    { enabled: !!customer && !isFetchingCustomer && !isNumeric(activationId) },
  )

  const activation = convertPresentActivationToStandard(
    magActivation,
    bffActivation,
    getMarketPrograms(customer ?? undefined),
  )

  const { magActivatedResources, isFetching: isFetchingMagActivatedResources } = useActivatedResourcesFromMagQuery(
    {
      location: customer ? customer.location : undefined,
      activationId: magActivation?.id,
      serviceId: magActivation?.serviceId,
      customerUuid: customer.uuid,
    },
    { enabled: !!magActivation && !!customer && !isFetchingCustomer },
  )
  const { bffActivatedResources, isFetching: isFetchingBffActivatedResources } = useActivatedResourcesFromBffQuery(
    {
      siteId: customer.uuid,
      activationId: activationId!,
    },
    { enabled: !!bffActivation && !!customer && !isFetchingCustomer },
  )

  const activatedResources = magActivatedResources
    ? convertMagActivatedResourcesToStandard(magActivatedResources)
    : convertBffActivatedResourcesToStandard(bffActivatedResources ?? [])

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.activation_details'),
      breadcrumbs: [
        ...customerDetailsCommonBreadcrumbs,
        {
          text: t('customer_details.tabs.activations'),
          to: `/customers/${customer.uuid}/activations`,
        },
      ],
      appBarContent: null,
      activeTab: CustomerDetailsRouteInformation.ACTIVATIONS.navigationPath,
    })
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'resourceName',
      headerName: t('customer_details.activations.details.resource.label'),
      flex: 1,
    },
    {
      field: 'sectionName',
      headerName: t('customer_details.activations.details.section.label'),
      flex: 1,
      valueGetter: (value: ActivatedResource['sectionName']) => {
        return value ?? DASH_VALUE
      },
    },
    {
      field: 'activatedAt',
      headerName: t('customer_details.activations.details.start_time.label'),
      flex: 1,
      valueFormatter: (value: ActivatedResource['activatedAt']) => {
        return formatResourceActivationTime(customerSettings?.localization?.timeZone ?? 'UTC', value)
      },
    },
    {
      field: 'deactivatedAt',
      headerName: t('customer_details.activations.details.end_time.label'),
      flex: 1,
      valueFormatter: (value: ActivatedResource['deactivatedAt']) => {
        return formatResourceActivationTime(customerSettings?.localization?.timeZone ?? 'UTC', value)
      },
    },
    {
      field: 'duration',
      headerName: t('customer_details.activations.details.duration.label'),
      flex: 1,
      valueGetter: (_, activatedResources: ActivatedResource) => {
        return formatActivatedResourceDuration(activatedResources.activatedAt, activatedResources.deactivatedAt)
      },
    },
  ]

  if (!customer || isFetchingCustomer || isFetchingCustomerSettings || (!magActivation && !bffActivation)) return null

  return (
    <CustomDataGrid
      columns={columns}
      getRowId={(row) => row.id + row.activatedAt}
      isLoading={isFetchingMagActivatedResources || isFetchingBffActivatedResources}
      rows={activatedResources ?? []}
      slotProps={{
        toolbar: {
          activation: activation,
          customerTimeZone: customerSettings?.localization?.timeZone ?? 'UTC',
        },
      }}
      slots={{
        toolbar: ActivationDetailsToolbar,
      }}
    />
  )
}

import { DialogContent } from '@mui/material'
import { t } from 'i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import type { ServiceRule } from '@/features/availability/types/serviceRule'
import { ServiceRuleDetailsForm } from '@/features/customer/components/availability/serviceRule/form/ServiceRuleDetailsForm'
import type { MarketProgramContract } from '@/features/customer/types/marketProgramContract'

interface ServiceRuleDetailsEditDialogProps {
  open: boolean
  onClose: () => void
  marketProgram: MarketProgramContract
  customerTimeZone: string
  customerLocation: string
  customerUuid: string
  serviceRule?: ServiceRule
}

export const ServiceRuleDetailsEditDialog = (props: ServiceRuleDetailsEditDialogProps) => {
  return (
    <CustomDialog
      open={props.open}
      size="large"
      title={t('customer_details.tabs.availability.availability_details.dialog.title.edit')}
      onClose={props.onClose}
    >
      <DialogContent>
        <ServiceRuleDetailsForm
          customerLocation={props.customerLocation}
          customerTimeZone={props.customerTimeZone}
          customerUuid={props.customerUuid}
          marketProgram={props.marketProgram}
          serviceRule={props.serviceRule}
          onClose={props.onClose}
        />
      </DialogContent>
    </CustomDialog>
  )
}

import { NumbersOutlined, SettingsSuggestOutlined } from '@mui/icons-material'
import { Box, FormControl, InputAdornment, Stack } from '@mui/material'
import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomButton from '@/components/inputs/CustomButton'
import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import FloatTextField from '@/components/inputs/FloatTextField'
import { ManualAcceptFlow, MAXIMUM_MW_PER_PTU } from '@/features/bidding/constants'
import { BulkFillMode } from '@/features/bidding/types/bid'
import { getFilterItemValue } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends AcceptBidVolumePtusDataGridToolbarProps {}
}

type AcceptBidVolumePtusDataGridToolbarProps = {
  onFillAll: (bulkMode: BulkFillMode, value: number | undefined) => void
  manualAcceptFlow: ManualAcceptFlow
}

const AcceptBidVolumePtusDataGridToolbar = ({
  onFillAll,
  manualAcceptFlow,
}: Readonly<AcceptBidVolumePtusDataGridToolbarProps>) => {
  const { t } = useTranslation()

  const [bulkFillMode, setBulkFillMode] = useState(BulkFillMode.OFFER_RATIO)
  const [bulkFillValue, setBulkFillValue] = useState<number | undefined>()

  const actions = (
    <>
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        <BulkFillTypeSelect
          bulkFillMode={bulkFillMode}
          manualAcceptFlow={manualAcceptFlow}
          setBulkFillMode={setBulkFillMode}
        />
        <FormControl>
          <BulkFillInputField bulkFillMode={bulkFillMode} setBulkFillValue={setBulkFillValue} />
        </FormControl>
      </Stack>

      <CustomButton
        disabled={bulkFillValue == undefined}
        variant="contained"
        onClick={() => onFillAll(bulkFillMode, bulkFillValue)}
      >
        {t('bidding.manual_accept.fill_all')}
      </CustomButton>
    </>
  )

  return <GridToolbar actions={actions} filters={<BidDateOutput />} />
}

const BidDateOutput = () => {
  const { t, i18n } = useTranslation()
  const apiRef = useGridApiContext()
  const filterModel = gridFilterModelSelector(apiRef)
  const deliveryDayValue = getFilterItemValue<DateTime>(filterModel, 'deliveryDay')

  return (
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
      <DatePicker
        defaultValue={deliveryDayValue}
        disabled={true}
        format="DD"
        label={t('bidding.manual_accept.bid_date')}
        sx={{ maxWidth: '160px' }}
      />
    </LocalizationProvider>
  )
}

const BulkFillTypeSelect = ({ bulkFillMode, setBulkFillMode, manualAcceptFlow }) => {
  const { t } = useTranslation()

  const acceptedRatioOption =
    manualAcceptFlow == ManualAcceptFlow.EDIT
      ? {
          id: BulkFillMode.ACCEPTED_RATIO,
          value: BulkFillMode.ACCEPTED_RATIO,
          label: t('bidding.manual_accept.accepted_ratio'),
          icon: <SettingsSuggestOutlined />,
        }
      : null

  const options = [
    {
      id: BulkFillMode.FORECAST_RATIO,
      value: BulkFillMode.FORECAST_RATIO,
      label: t('bidding.manual_accept.forecasted_ratio'),
      icon: <SettingsSuggestOutlined />,
    },
    {
      id: BulkFillMode.OFFER_RATIO,
      value: BulkFillMode.OFFER_RATIO,
      label: t('bidding.manual_accept.offered_ratio'),
      icon: <SettingsSuggestOutlined />,
    },
    acceptedRatioOption,
    {
      id: BulkFillMode.ABSOLUTE_VALUE,
      value: BulkFillMode.ABSOLUTE_VALUE,
      label: t('bidding.manual_accept.absolute_value'),
      icon: <NumbersOutlined />,
    },
  ].filter((option) => option != null) as SelectFieldOption<any>[]

  return (
    <CustomSelectField
      id="bulk-fill"
      label={t('bidding.manual_accept.bulk_fill')}
      options={options}
      value={bulkFillMode}
      onChange={(event) => setBulkFillMode(event.target.value)}
    />
  )
}

const BulkFillInputField = ({ bulkFillMode, setBulkFillValue }) => {
  const { t } = useTranslation()

  return (
    <Box width={100}>
      <FloatTextField
        max={MAXIMUM_MW_PER_PTU}
        min={0}
        textFieldProps={{
          InputProps: {
            endAdornment: (
              <InputAdornment position="start">
                {bulkFillMode == BulkFillMode.ABSOLUTE_VALUE ? 'MW' : '%'}
              </InputAdornment>
            ),
          },
          label: t('bidding.manual_accept.value'),
        }}
        onValueChange={setBulkFillValue}
      />
    </Box>
  )
}

export default AcceptBidVolumePtusDataGridToolbar

import type { GridValidRowModel } from '@mui/x-data-grid'

import type { FormattedRow } from '@/features/bessDashboard/types'

export interface Row extends GridValidRowModel {
  id: number
  ptu: number
  period: string
  lower_bound: number
  average_price: number
  upper_bound: number
  market_program_name: string
  market_bid: number
  allocated_mw: number
}

const config = {
  PERIOD: 'ds',
  ID: 'id',
  PTU: 'ptu',
  MARKET_PROGRAM_NAME: 'market_program_name',
  MARKET_BID: 'impact_level',
  AVERAGE_PRICE: 'average_price',
  LOWER_BOUND: 'lower_bound',
  UPPER_BOUND: 'upper_bound',
}

export function transformForecastData(forecasts: FormattedRow[]): Row[] {
  return forecasts.map((item) => ({
    allocated_mw: 0,
    average_price: Number(item.average_price),
    id: Number(item[config.ID]),
    lower_bound: Number(item[config.LOWER_BOUND]),
    market_bid: Number(item[config.MARKET_BID]),
    market_program_name: String(item[config.MARKET_PROGRAM_NAME]), // Ensuring this is a string
    period: String(item[config.PERIOD]), // Ensuring period is a string
    ptu: Number(item[config.PTU]),
    upper_bound: Number(item[config.UPPER_BOUND]),
    biddingLadder: [], // Initialize as an empty array
  }))
}

import type { ExtraCriteriaWeekday } from '@/features/resource/types/spotOnForEboilers'

export const transformDataForDataGrid = (values: ExtraCriteriaWeekday[]) => {
  const rows = Array.from({ length: 24 }, (_, rowIndex) => {
    const row: Record<string, any> = { id: rowIndex, ptu: '' }

    values.forEach((nc) => {
      const period = nc.periods[rowIndex]
      if (period) {
        row[nc.dayNumber] = period.value !== '' ? period.value : 0
        if (!row.ptu) {
          row.ptu = `${period.startTimeAt} - ${period.endTimeAt}`
        }
      } else {
        row[nc.dayNumber] = ''
      }
    })

    return row
  })

  return rows
}

import { zodResolver } from '@hookform/resolvers/zod'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Box,
  CardHeader,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomButton from '@/components/inputs/CustomButton'
import TextFieldController from '@/components/inputs/TextFieldController'
import { GET_RESOURCE_API_ID, updateSteeringDefaults } from '@/features/resource/endpoints/resources'
import { ResourceSteeringDefaultsSchema } from '@/features/resource/schemas'
import type { Resource, ResourceSteeringConfig, ResourceSteeringDefaultsSchemaType } from '@/features/resource/types'

export type ResourceSteeringDefaultsPanelProps = {
  resourceID: Resource['resourceID']
  steeringConfig: ResourceSteeringConfig | undefined
  onUpdateSteeringDefaults: (data: ResourceSteeringDefaultsSchemaType) => void
}

export type ResourceSteeringDefaultsFormProps = {
  steeringConfig: ResourceSteeringConfig | undefined
  closeEditMode: () => void
  onSave: (data: ResourceSteeringDefaultsSchemaType) => void
  isSaving: boolean
}

const SteeringDefaultTooltipContent = () => {
  const { t } = useTranslation()

  return (
    <div style={{ maxWidth: 300 }}>
      <Typography sx={{ color: 'white' }} variant="body2">
        {t('resources.steering.steering_defaults.tooltip.description')}
      </Typography>
      <Typography sx={{ color: 'white', fontWeight: 'bold', mb: 1 }} variant="body2">
        {t('resources.steering.steering_defaults.tooltip.actions')}
      </Typography>
      <List dense disablePadding>
        <ListItem disableGutters>
          <ListItemText
            primary={t('resources.steering.steering_defaults.tooltip.action1.title')}
            primaryTypographyProps={{ color: 'white', fontWeight: 'bold' }}
            secondary={t('resources.steering.steering_defaults.tooltip.action1.description')}
            secondaryTypographyProps={{ color: 'white' }}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary={t('resources.steering.steering_defaults.tooltip.action2.title')}
            primaryTypographyProps={{ color: 'white', fontWeight: 'bold' }}
            secondary={t('resources.steering.steering_defaults.tooltip.action2.description')}
            secondaryTypographyProps={{ color: 'white' }}
          />
        </ListItem>
      </List>
    </div>
  )
}

const SteeringDefaultsForm = ({
  steeringConfig,
  closeEditMode,
  onSave,
  isSaving,
}: ResourceSteeringDefaultsFormProps) => {
  const { t } = useTranslation()

  const form = useForm<ResourceSteeringDefaultsSchemaType>({
    defaultValues: {
      defaultConsumptionLevel: steeringConfig?.defaultConsumptionLevel ?? null,
      steeringDefaultType: steeringConfig?.steeringDefaultType ?? 'NONE',
    },
    resolver: zodResolver(ResourceSteeringDefaultsSchema),
  })

  const { handleSubmit, watch, control } = form
  const steeringDefaultType = watch('steeringDefaultType')

  function onCancel() {
    closeEditMode()
  }

  return (
    <FormProvider {...form}>
      <form aria-label={t('resources.steering.steering_defaults.label')} onSubmit={handleSubmit(onSave)}>
        <Stack alignItems={'flex-start'} direction={'column'} sx={{ gap: 3 }}>
          <Controller
            control={control}
            name="steeringDefaultType"
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  control={<Radio />}
                  label={t('resources.steering.steering_defaults.none')}
                  value="NONE"
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t('resources.steering.steering_defaults.set_to_default')}
                  value="SET_TO_DEFAULT_LEVEL"
                />
                <Tooltip
                  placement="right"
                  title={
                    !steeringConfig?.isReleaseControlSupported
                      ? t('resources.steering.steering_defaults.release_control_not_supported')
                      : ''
                  }
                >
                  <FormControlLabel
                    control={<Radio />}
                    disabled={!steeringConfig?.isReleaseControlSupported}
                    label={
                      <Stack alignItems="center" direction="row" spacing={0.5}>
                        <span>{t('resources.steering.steering_defaults.release_control')}</span>
                        {!steeringConfig?.isReleaseControlSupported && <InfoOutlinedIcon fontSize="small" />}
                      </Stack>
                    }
                    value="RELEASE_CONTROL"
                  />
                </Tooltip>
              </RadioGroup>
            )}
          />

          {steeringDefaultType === 'SET_TO_DEFAULT_LEVEL' && (
            <TextFieldController
              required
              label={t('resources.steering.steering_defaults.default_consumption')}
              name="defaultConsumptionLevel"
              sx={{ width: 300 }}
              type="number"
              onChange={(e) =>
                form.setValue('defaultConsumptionLevel', e.target.value === '' ? null : Number(e.target.value))
              }
            />
          )}
          <Box>
            <Stack direction={'row'} gap={2}>
              <CustomButton disabled={isSaving} type="submit" variant="contained">
                {t('common.button.save')}
              </CustomButton>
              <CustomButton variant="outlined" onClick={onCancel}>
                {t('common.button.cancel')}
              </CustomButton>
            </Stack>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}
const SteeringDefaultsPanel = ({
  steeringConfig,
  resourceID,
  onUpdateSteeringDefaults,
}: ResourceSteeringDefaultsPanelProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const updateSteeringDefaultsMutation = useMutation({
    mutationFn: (data: ResourceSteeringDefaultsSchemaType) => {
      const payload = {
        steeringDefaultType: data.steeringDefaultType,
        defaultConsumptionLevel:
          data.steeringDefaultType === 'SET_TO_DEFAULT_LEVEL' && data.defaultConsumptionLevel !== null
            ? Number(data.defaultConsumptionLevel)
            : null,
      }
      return updateSteeringDefaults(resourceID, payload.steeringDefaultType, payload.defaultConsumptionLevel)
    },
    onSuccess: (_, variables) => {
      onUpdateSteeringDefaults(variables)
      queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceID }] })
      setEditMode(false)
    },
    onError: (error) => {
      console.error('Failed to update steering defaults:', error)
    },
  })

  function onOpenEditMode() {
    setEditMode(true)
  }

  function onCloseEditMode() {
    setEditMode(false)
  }

  function onSave(data: ResourceSteeringDefaultsSchemaType) {
    updateSteeringDefaultsMutation.mutate(data)
  }

  function getSteeringDefault(steeringConfig?: ResourceSteeringConfig): string {
    switch (steeringConfig?.steeringDefaultType) {
      case 'NONE':
        return t('resources.steering.steering_defaults.none')
      case 'SET_TO_DEFAULT_LEVEL':
        return t('resources.steering.steering_defaults.set_to_default_value', {
          value: steeringConfig.defaultConsumptionLevel,
        })
      case 'RELEASE_CONTROL':
        return t('resources.steering.steering_defaults.release_control')
      default:
        return t('resources.steering.steering_defaults.none')
    }
  }

  return (
    <>
      <CardHeader
        sx={{ paddingX: 0 }}
        title={
          <Stack alignItems="center" direction="row" spacing={1}>
            <CustomTypography variant="h6">{t('resources.steering.steering_defaults.label')}</CustomTypography>
            <Tooltip arrow placement="right-start" title={<SteeringDefaultTooltipContent />}>
              <HelpOutlineIcon fontSize="small" sx={{ color: 'black' }} />
            </Tooltip>
          </Stack>
        }
      />
      {editMode && steeringConfig ? (
        <SteeringDefaultsForm
          closeEditMode={onCloseEditMode}
          isSaving={updateSteeringDefaultsMutation.isPending}
          steeringConfig={steeringConfig}
          onSave={onSave}
        />
      ) : (
        <Stack alignItems={'flex-start'} direction={'column'} sx={{ gap: 3, paddingBottom: 3 }}>
          <CustomTypography sx={{ paddingX: 3 }} variant={'body1'}>
            {getSteeringDefault(steeringConfig)}
          </CustomTypography>
          <CustomButton variant={'outlined'} onClick={onOpenEditMode}>
            {t('resources.steering.steering_defaults.edit')}
          </CustomButton>
        </Stack>
      )}
    </>
  )
}

export default SteeringDefaultsPanel

import { IconChevronLeft } from '@aws-amplify/ui-react/internal'
import { Box } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import CreateBidButton from '@/features/bidding/components/CreateBidButton'
import OfferedPriceVolumeOutOfRangeTooltip from '@/features/bidding/components/OfferedPriceVolumeOutOfRangeTooltip'
import HourlyPriceDataGrid, { updatePtuCapacities } from '@/features/bidding/components/price/offer/HourlyPriceDataGrid'
import useActivationGroupBidsQuery from '@/features/bidding/hooks/useActivationGroupBidsQuery'
import { useInvalidateGroupedBidsPageQuery } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import { useSaveBidsMutation } from '@/features/bidding/hooks/useSaveBidsMutation'
import type { Bid } from '@/features/bidding/types/bid'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import {
  buildBidCapacityLink,
  buildBidPriceConfirmationLink,
} from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import { buildHourlyPriceRowItems } from '@/features/bidding/utils/model/buildHourlyPriceRowItems'

type Props = {
  groupParams: GroupedBidParams
}

const BidOfferPrice = ({ groupParams }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activationGroupBids, isLoading } = useActivationGroupBidsQuery(groupParams)
  const [editableBids, setEditableBids] = useState<Bid[]>([])
  const { saveBids, isPending } = useSaveBidsMutation()
  const invalidateCachedQueryStateOfGroupedBids = useInvalidateGroupedBidsPageQuery()

  useEffect(() => {
    if (!isLoading && editableBids.length === 0) {
      setEditableBids(activationGroupBids)
    }
  }, [activationGroupBids])

  // This is called when the user clicks the "Create Bid" button to submit bids in OFFERED status for final validation.
  // The saving of DRAFT status bids is done in previous pages.
  // The user can navigate back and forth between these pages, which may cause the original ptuChunks to become out-of-sync.
  // This function recalculates all ptuChunk values in the OFFERED bids of every activation group before saving them.
  const handleOnSubmit = async () => {
    recalculateAllPriceChunksForEachActivationGroup(editableBids)

    await saveBids(editableBids)
    await invalidateCachedQueryStateOfGroupedBids()
    navigate(buildBidPriceConfirmationLink(groupParams))
  }

  const ptuRemainingVolumeIsNegative = anyPtuRemainingVolumeIsNegative(editableBids)

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ justifyContent: 'left' }}>
            <CountrySelector disabled />
          </Box>
        }
        breadcrumbItems={[
          { text: t('component.page_header.bidding'), to: '/bidding' },
          { text: t('component.page_header.bid.todo_list'), to: '/bidding/todo-list' },
          {
            text: t('component.page_header.bid.create.label', {
              portfolio: groupParams.portfolioCode,
              marketProgram: t(`bidding.market_program.${groupParams.marketProgram}`),
            }),
            to: buildBidCapacityLink(groupParams),
          },
        ]}
        pageTitle={t('bidding.create_bid.price_bid')}
      />

      <HourlyPriceDataGrid
        bids={editableBids}
        groupParams={groupParams}
        isLoading={isLoading}
        onBidsChange={setEditableBids}
      />

      <ButtonBar>
        <CustomButton
          key="backButton"
          startIcon={<IconChevronLeft />}
          variant={'outlined'}
          onClick={() => navigate(-1)}
        >
          {t('common.navigation.back')}
        </CustomButton>
        <OfferedPriceVolumeOutOfRangeTooltip disabled={!ptuRemainingVolumeIsNegative}>
          <CreateBidButton
            dialogProps={{
              description: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.description'),
              title: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.title'),
            }}
            disabled={isLoading || isPending || ptuRemainingVolumeIsNegative}
            onSubmit={handleOnSubmit}
          />
        </OfferedPriceVolumeOutOfRangeTooltip>
      </ButtonBar>
    </>
  )
}

function recalculateAllPriceChunksForEachActivationGroup(editableBids: Bid[]) {
  const rowItems = buildHourlyPriceRowItems(editableBids)
  rowItems.forEach((rowItem) => {
    rowItem.priceCapacities.forEach(async (priceCapacity) => {
      await updatePtuCapacities(editableBids, rowItem.ptuStart, priceCapacity.price, priceCapacity.capacityMw)
    })
  })
}

function anyPtuRemainingVolumeIsNegative(editableBids: Bid[]) {
  const rowItems = buildHourlyPriceRowItems(editableBids)
  return rowItems.some((rowItem) => {
    return rowItem.remainingMw < 0
  })
}

export default BidOfferPrice

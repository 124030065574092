import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { getGridDateOperators } from '@mui/x-data-grid/colDef/gridDateOperators'
import { getGridSingleSelectOperators } from '@mui/x-data-grid/colDef/gridSingleSelectOperators'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ChipList from '@/components/dataDisplay/ChipList'
import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { COLUMN_WITH_SMALL_ICON_WIDTH } from '@/constants/layout'
import { AVAILABILITY_STATUSES, AVAILABILITY_STATUSES_LIST } from '@/features/availability/types/availabilityStatus'
import type { Restriction } from '@/features/availability/types/restriction'
import {
  AvailabilityDataGridToolbar,
  DEFAULT_DATA_GRID_AVAILABLITY_FILTER_MODEL,
} from '@/features/customer/components/availability/datagrid/AvailabilityDataGridToolbar'
import { AvailabilityStatusChip } from '@/features/customer/components/availability/datagrid/AvailabilityStatusChip'
import { getGridDateIncludesOperators } from '@/features/customer/components/availability/datagrid/includesFilterOperators'
import { RestrictionDetailsDialog } from '@/features/customer/components/availability/restriction/RestrictionDetailsDialog'
import { RestrictionDetailsEditDialog } from '@/features/customer/components/availability/restriction/RestrictionDetailsEditDialog'
import type { Resource } from '@/features/customer/components/availability/types'
import { formatResourceName } from '@/features/customer/components/availability/utils'
import { useInvalidateRestrictionsQuery, useRestrictionsQuery } from '@/features/customer/hooks/useRestrictionsQuery'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import { convertToTimeZoneDateTime, formatDateTimeWithLocale } from '@/utils/time'

import { RestrictionDetailsCreateDialog } from './RestrictionDetailsCreateDialog'

interface RestrictionsDataGridProps {
  selectedMarketProgram: MarketProgram
  customerUuid: string
  customerTimeZone: string
  customerLocation: string
}

export const RestrictionsDataGrid = (props: RestrictionsDataGridProps) => {
  const { t, i18n } = useTranslation()
  const [selectedRestriction, setSelectedRestriction] = useState({} as Restriction)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [createFormDialogOpen, setCreateFormDialogOpen] = useState(false)
  const [editFormDialogOpen, setEditFormDialogOpen] = useState(false)
  const invalidateRestrictionsQuery = useInvalidateRestrictionsQuery()

  const handleOpen = (restriction: Restriction) => {
    setSelectedRestriction(restriction)
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
    setSelectedRestriction({} as Restriction)
  }

  const { restrictions, isFetching } = useRestrictionsQuery({
    customerUuid: props.customerUuid,
    location: props.customerLocation,
  })

  const handleCancelRestrictionSubmit = async () => {
    setSelectedRestriction({} as Restriction)
    setCreateFormDialogOpen(false)
    setEditFormDialogOpen(false)
    await invalidateRestrictionsQuery()
  }

  function getRestrictionColumns(t: TFunction, customerTimeZone: string): GridColDef<Restriction>[] {
    return [
      {
        field: 'startDate',
        headerName: t('customer_details.tabs.availability.unavailable_from'),
        flex: 1,
        type: 'date',
        filterOperators: [...getGridDateOperators(), ...getGridDateIncludesOperators(customerTimeZone)],
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'endDate',
        headerName: t('customer_details.tabs.availability.unavailable_to'),
        flex: 1,
        type: 'date',
        filterOperators: [...getGridDateOperators(), ...getGridDateIncludesOperators(customerTimeZone)],
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'timeOfDay',
        headerName: t('customer_details.tabs.availability.start_end_time'),
        flex: 1,
        sortable: false,
        valueGetter: (_, restriction: Restriction) => `${restriction.startTime} - ${restriction.endTime}`,
      },
      {
        field: 'type',
        headerName: t('customer_details.tabs.availability.recurrence'),
        flex: 1,
        valueFormatter: (value: Restriction['type']) =>
          value === 'repeating-daily'
            ? t('customer_details.tabs.availability.unavailability_data_grid.cells.recurrence.daily')
            : '—',
      },
      {
        field: 'resources',
        headerName: t('common.resources'),
        flex: 0.5,
        sortable: false,
        valueGetter: (_, restriction: Restriction) => restriction.relayWires?.map((wire) => formatResourceName(wire)),
        renderCell: (params: GridRenderCellParams<Restriction>) => {
          return (
            <ChipList
              chips={
                params.row.relayWires?.map((resource) => ({
                  id: resource.id?.toString() || '',
                  label: formatResourceName(resource),
                  sx: { cursor: 'inherit' },
                })) ?? []
              }
              color="secondary"
              limit={0}
              size="small"
            />
          )
        },
      },
      {
        field: 'updatedAt',
        headerName: t('customer_details.tabs.availability.last_updated'),
        flex: 1,
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        type: 'singleSelect',
        valueOptions: [...AVAILABILITY_STATUSES_LIST],
        sortComparator: (v1, v2) => {
          return AVAILABILITY_STATUSES[v1].sortingOrder - AVAILABILITY_STATUSES[v2].sortingOrder
        },
        filterOperators: [...getGridSingleSelectOperators()],
        renderCell: (params: GridRenderCellParams<Restriction>) => {
          return <AvailabilityStatusChip status={params.value} />
        },
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        width: COLUMN_WITH_SMALL_ICON_WIDTH,
        renderCell: (params: GridRenderCellParams<Restriction>) => {
          return (
            params.row.status !== 'expired' && (
              <CustomIconButton
                Icon={EditOutlinedIcon}
                aria-label="edit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedRestriction(params.row)
                  setEditFormDialogOpen(true)
                }}
              />
            )
          )
        },
      },
    ]
  }

  return (
    <>
      <CustomDataGrid
        disableGutterTop
        clickableRows={{
          onRowClick: (params) => {
            handleOpen(params.row)
          },
        }}
        columnVisibilityModel={{
          createdAt: false,
        }}
        columns={getRestrictionColumns(t, props.customerTimeZone)}
        includeWrapper={false}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'status',
                sort: 'asc',
              },
              {
                field: 'startDate',
                sort: 'desc',
              },
            ],
          },
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          filter: {
            filterModel: DEFAULT_DATA_GRID_AVAILABLITY_FILTER_MODEL,
          },
        }}
        isLoading={isFetching}
        rows={restrictions?.filter((restriction) => restriction.serviceId === props.selectedMarketProgram.id) ?? []}
        slotProps={{
          toolbar: {
            availableResources: getUniqueRestrictedResources(restrictions ?? []),
            mode: 'unavailability',
            customerTimeZone: props.customerTimeZone,
            onAddClick: () => setCreateFormDialogOpen(true),
          },
        }}
        slots={{
          toolbar: AvailabilityDataGridToolbar,
        }}
        sx={{ pt: 2 }}
      />

      <RestrictionDetailsDialog
        customerTimeZone={props.customerTimeZone}
        marketProgram={props.selectedMarketProgram}
        open={dialogOpen}
        restriction={selectedRestriction}
        onClose={handleClose}
      />
      <RestrictionDetailsCreateDialog
        customerLocation={props.customerLocation}
        customerTimeZone={props.customerTimeZone}
        customerUuid={props.customerUuid}
        marketProgram={props.selectedMarketProgram}
        open={createFormDialogOpen}
        onClose={() => handleCancelRestrictionSubmit()}
      />
      <RestrictionDetailsEditDialog
        customerLocation={props.customerLocation}
        customerTimeZone={props.customerTimeZone}
        customerUuid={props.customerUuid}
        marketProgram={props.selectedMarketProgram}
        open={editFormDialogOpen}
        restriction={selectedRestriction}
        onClose={() => handleCancelRestrictionSubmit()}
      />
    </>
  )
}

function getUniqueRestrictedResources(restrictions: Restriction[]) {
  const wires = restrictions.flatMap((restriction) => restriction.relayWires)
  const uniqueWires: Resource[] = []
  const seen = new Set()

  for (const wire of wires) {
    const key = `${wire.id}-${formatResourceName(wire)}`
    if (!seen.has(key)) {
      seen.add(key)
      uniqueWires.push({ id: wire.id, name: formatResourceName(wire) })
    }
  }

  return uniqueWires.sort((a, b) => a.name.localeCompare(b.name))
}

function getDateValueGetter(customerTimeZone: string) {
  return (value: string) => convertToTimeZoneDateTime(customerTimeZone, value)
}

function getDateFormatter(language: string) {
  return (value: DateTime) => formatDateTimeWithLocale(value, language)
}

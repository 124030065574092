import { CircularProgress, Grid2 as Grid } from '@mui/material'

function FullPageSpinner() {
  return (
    <Grid container alignItems="center" direction="row" justifyContent="center" sx={{ height: '100vh' }}>
      <CircularProgress title="Full page spinner" />
    </Grid>
  )
}

export default FullPageSpinner

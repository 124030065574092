import { useTheme } from '@mui/material/styles'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import type { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import type { DateRange } from '@mui/x-date-pickers-pro/models'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { DateTime } from 'luxon'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

type DateRangeFilterProps = {
  startTime: DateTime
  endTime: DateTime
  setStartTime: Dispatch<SetStateAction<DateTime>>
  setEndTime: Dispatch<SetStateAction<DateTime>>
}

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
  {
    label: 'This Week',
    getValue: () => {
      const today = DateTime.now()
      return [today.startOf('week'), today.endOf('week')]
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = DateTime.now()
      const prevWeek = today.minus({ days: 7 })
      return [prevWeek.startOf('week'), prevWeek.endOf('week')]
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = DateTime.now()
      return [today.minus({ days: 7 }), today.plus({ days: 2 })]
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = DateTime.now()
      return [today.startOf('month'), today.endOf('month')]
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = DateTime.now()
      return [today.minus({ days: 30 }), today.plus({ days: 2 })]
    },
  },
  {
    label: 'Forecasts',
    getValue: () => {
      const today = DateTime.now()
      return [today.minus({ days: 4 }), today.plus({ days: 4 })]
    },
  },
]

export default function SingleInputDateRangePicker({
  startTime,
  endTime,
  setStartTime,
  setEndTime,
}: Readonly<DateRangeFilterProps>) {
  const { i18n } = useTranslation()
  const theme = useTheme()

  const handleOnAccept = (newValue) => {
    const [newStartTime, newEndTime] = newValue

    // Do not set date time range if the difference is more than 24 hours
    // if (differenceInHours >= 24 && differenceInMinutes > 0) return

    setStartTime(newStartTime!)
    setEndTime(newEndTime!)
  }

  return (
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
      <DateRangePicker
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: { actions: [] },
        }}
        slots={{ field: SingleInputDateRangeField }}
        sx={{
          width: '320px',
          '& .MuiInputBase-root *': {
            color: theme.palette.common.white,
          },
          '& .MuiInputBase-root:not(.Mui-disabled):is(.Mui-focused), .MuiInputBase-root:not(.Mui-disabled):hover': {
            background: 'rgba(255, 255, 255, 0.12)',
          },

          '& .MuiInputBase-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            border: `2px solid rgba(255, 255, 255, 0.75)`,
          },

          '& .MuiInputBase-root:is(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            border: `2px solid white`,
          },

          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            color: 'rgba(255, 255, 255, 0.60)',
          },

          '& .MuiInputLabel-root:is(.MuiInputLabel-shrink)': {
            color: `${theme.palette.common.white}`,
          },
        }}
        value={[startTime, endTime]}
        onAccept={handleOnAccept}
      />
    </LocalizationProvider>
  )
}

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { FormHelperText, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface FormFieldErrorTextProps {
  message?: string
  showIcon?: boolean
}

export default function FormFieldErrorText({ message, showIcon }: Readonly<FormFieldErrorTextProps>) {
  const { t } = useTranslation()

  return (
    <FormHelperText error>
      <Stack alignItems="center" direction="row" gap={1}>
        {message && showIcon && (
          <ErrorOutlineOutlinedIcon fontSize="small" titleAccess={t('user_form.form.error_icon')} />
        )}
        {message}
      </Stack>
    </FormHelperText>
  )
}

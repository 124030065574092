import { DateTime } from 'luxon'

import type { Activation, BffActivation, MagActivation } from '@/features/activation/types/activation'
import { convertBffActivationToStandard, convertMagActivationToStandard } from '@/features/activation/utils/converters'
import { getMarketProgramTypes } from '@/features/activation/utils/getMarketProgramTypes'
import type { MarketProgram } from '@/features/customer/types/marketProgram'

export function mergeActivations(
  magActivations: MagActivation[] | undefined | null,
  bffActivations: BffActivation[] | undefined | null,
  marketPrograms: MarketProgram[] | undefined | null,
): Activation[] {
  return [
    ...(magActivations?.map((magActivation) =>
      convertMagActivationToStandard(magActivation, getMarketProgramTypes(marketPrograms).get(magActivation.serviceId)),
    ) ?? []),
    ...(bffActivations?.map((bffActivation) => convertBffActivationToStandard(bffActivation)) ?? []),
  ].sort(
    (a, b) =>
      (b.endedAt ? DateTime.fromISO(b.endedAt).toMillis() : 0) -
      (a.endedAt ? DateTime.fromISO(a.endedAt).toMillis() : 0),
  )
}

// https://mui.com/material-ui/guides/routing/#tabs
// You need to provide the routes in descendant order (leaf to root).
// This means that if you have nested routes, then the parent route should be provided last within the hierarchy.

const CONTACT_INFO = {
  label: 'customer_details.tabs.contact_info.title',
  navigationPath: 'customers/:customerUuid/contact-info',
  routePath: '/:customerUuid/contact-info',
}

const ACTIVATIONS = {
  label: 'customer_details.tabs.activations',
  navigationPath: 'customers/:customerUuid/activations',
  routePath: '/:customerUuid/activations',
}

const ACTIVATION_DETAILS = {
  label: 'customer_details.tabs.activation_details',
  navigationPath: 'customers/:customerUuid/activation/:activationId',
  routePath: '/:customerUuid/activation/:activationId',
}

const SETTINGS = {
  label: 'customer_details.tabs.settings',
  navigationPath: 'customers/:customerUuid/settings',
  routePath: '/:customerUuid/settings',
}

const AVAILABILITY = {
  label: 'customer_details.tabs.availability',
  navigationPath: 'customers/:customerUuid/availability',
  routePath: '/:customerUuid/availability',
}

const POWER_MEASUREMENTS = {
  label: 'customer_details.tabs.power_measurements',
  navigationPath: 'customers/:customerUuid/power-measurements',
  routePath: '/:customerUuid/power-measurements',
}

const RES_LEVEL_UNAVAILABILITY = {
  label: 'customer_details.tabs.unavailability',
  navigationPath: 'customers/:customerUuid/res-level-unavailability',
  routePath: '/:customerUuid/res-level-unavailability',
}

const REVENUE = {
  label: 'customer_details.tabs.revenue',
  navigationPath: 'customers/:customerUuid/revenue',
  routePath: '/:customerUuid/revenue',
}

const INDEX = {
  label: CONTACT_INFO.label,
  navigationPath: 'customers/:customerUuid',
}

export const CustomerDetailsRouteInformation = {
  CONTACT_INFO,
  ACTIVATION_DETAILS,
  ACTIVATIONS,
  SETTINGS,
  AVAILABILITY,
  POWER_MEASUREMENTS,
  RES_LEVEL_UNAVAILABILITY,
  REVENUE,
  INDEX,
} as const

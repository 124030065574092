import { DateTime } from 'luxon'

import { MONTH_DAY_AND_YEAR_FORMAT, ONLY_YEAR_FORMAT, SHORT_MONTH_AND_YEAR_FORMAT } from '@/constants/dateTimeFormats'
import type { Activation } from '@/features/activation/types/activation'
import type { ChartData } from '@/features/activation/types/activationsChartData'
import { ChartTimeUnit } from '@/features/activation/types/chartTimeUnit'
import type { MarketProgramType } from '@/types/marketProgramType'

export function getActivationsInTimeRange(activations: Activation[], toDate: DateTime): Activation[] {
  return activations.filter(
    (activation) => activation.startedAt && activation.endedAt && DateTime.fromISO(activation.endedAt) <= toDate,
  )
}

export function getDistinctMarketPrograms(activations: Activation[]): Set<MarketProgramType> {
  const nonDistinctMarketPrograms = activations
    .map((activation) => activation.marketProgram)
    .filter((item) => item) as MarketProgramType[]
  return new Set<MarketProgramType>(nonDistinctMarketPrograms)
}

export function countActivationsPerTimeLabelAndMarketProgram(
  timeUnit: ChartTimeUnit,
  activations: Activation[],
  marketPrograms: MarketProgramType[],
  fromDate: DateTime,
  toDate: DateTime,
): ChartData[] {
  const dataByTimeUnit = initializeMapByTimeUnit(timeUnit, marketPrograms, fromDate, toDate)

  activations?.forEach((activation) => {
    const activationTimeLabel = getTimeLabel(timeUnit, DateTime.fromISO(activation.endedAt as string))

    const dataForTimeLabel = dataByTimeUnit.get(activationTimeLabel)
    if (dataForTimeLabel !== undefined && activation.marketProgram !== undefined) {
      dataForTimeLabel[activation.marketProgram] += 1
    }
  })
  return Array.from(dataByTimeUnit.values())
}

function initializeMapByTimeUnit(
  timeUnit: ChartTimeUnit,
  marketPrograms: MarketProgramType[],
  fromDate: DateTime,
  toDate: DateTime,
): Map<string, ChartData> {
  const dataByTimeLabel = new Map<string, ChartData>()

  for (const timeLabel of getTimeLabelsInTimeRange(timeUnit, fromDate, toDate)) {
    const emptyChartData: ChartData = { timeLabel: timeLabel }
    marketPrograms.forEach((program) => (emptyChartData[program] = 0))
    dataByTimeLabel.set(timeLabel, emptyChartData)
  }
  return dataByTimeLabel
}

function getTimeLabel(timeUnit: ChartTimeUnit, date: DateTime) {
  switch (timeUnit) {
    case ChartTimeUnit.DAY:
      return date.toFormat(MONTH_DAY_AND_YEAR_FORMAT)
    case ChartTimeUnit.YEAR:
      return date.toFormat(ONLY_YEAR_FORMAT)
    default:
      return date.toFormat(SHORT_MONTH_AND_YEAR_FORMAT)
  }
}

function getTimeLabelsInTimeRange(timeUnit: ChartTimeUnit, fromDate: DateTime, toDate: DateTime): string[] {
  const timeLabelsInSelectedRange: string[] = []
  let date = fromDate
  while (date <= toDate) {
    const timeLabel = getTimeLabel(timeUnit, date)
    timeLabelsInSelectedRange.push(timeLabel)
    date = date.plus({ days: 1 })
  }
  return timeLabelsInSelectedRange
}

import { zodResolver } from '@hookform/resolvers/zod'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, CardHeader, Stack, Tooltip, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CheckboxController from '@/components/inputs/CheckboxController'
import CustomButton from '@/components/inputs/CustomButton'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import { GET_RESOURCE_API_ID, updateSteeringMultistep } from '@/features/resource/endpoints/resources'
import { ResourceSteeringMultistepSchema } from '@/features/resource/schemas'
import type { Resource, ResourceSteeringConfig, ResourceSteeringMultistepSchemaType } from '@/features/resource/types'

export type ResourceSteeringMultistepPanelProps = {
  resourceID: Resource['resourceID']
  steeringConfig: ResourceSteeringConfig | undefined
  onUpdateMultistepSettings: (data: ResourceSteeringMultistepSchemaType) => void
}

export type ResourceSteeringMultistepFormProps = {
  steeringConfig: ResourceSteeringConfig | undefined
  closeEditMode: () => void
  onSave: (data: ResourceSteeringMultistepSchemaType) => void
  isSaving: boolean
}

const SteeringMultistepTooltipContent = () => {
  const { t } = useTranslation()

  return (
    <div style={{ maxWidth: 300 }}>
      <Typography sx={{ color: 'white' }} variant="body2">
        {t('resources.steering.multistep.tooltip')}
      </Typography>
    </div>
  )
}

const SteeringMultistepForm = ({
  steeringConfig,
  closeEditMode,
  onSave,
  isSaving,
}: ResourceSteeringMultistepFormProps) => {
  const { t } = useTranslation()

  const form = useForm<ResourceSteeringMultistepSchemaType>({
    defaultValues: {
      isMultistepResource: steeringConfig?.isMultistepResource ?? false,
      multistepStrategy: steeringConfig?.multistepStrategy ?? null,
    },
    resolver: zodResolver(ResourceSteeringMultistepSchema),
  })

  const { handleSubmit, watch } = form
  const isMultistepResource = watch('isMultistepResource')

  function onCancel() {
    closeEditMode()
  }

  const multistepStrategyOptions = [
    {
      id: 'EXCLUSIVE',
      value: 'EXCLUSIVE',
      label: t('resources.steering.multistep.multistep_strategy.value.exclusive'),
    },
    {
      id: 'ADDITIVE',
      value: 'ADDITIVE',
      label: t('resources.steering.multistep.multistep_strategy.value.additive'),
      disabled: true,
    },
  ]

  return (
    <FormProvider {...form}>
      <form aria-label={t('resources.steering.multistep.label')} onSubmit={handleSubmit(onSave)}>
        <Stack alignItems={'flex-start'} direction={'column'} sx={{ gap: 3 }}>
          <CheckboxController label={t('resources.steering.multistep.is_multistep')} name="isMultistepResource" />
          {isMultistepResource && (
            <SelectFieldController
              fullWidth
              required
              id="multistep-strategy"
              label={t('resources.steering.multistep.multistep_strategy.label')}
              name="multistepStrategy"
              options={multistepStrategyOptions}
            />
          )}
          <Box>
            <Stack direction={'row'} gap={2}>
              <CustomButton disabled={isSaving} type="submit" variant="contained">
                {t('common.button.save')}
              </CustomButton>
              <CustomButton variant="outlined" onClick={onCancel}>
                {t('common.button.cancel')}
              </CustomButton>
            </Stack>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}

const SteeringMultistepPanel = ({
  resourceID,
  steeringConfig,
  onUpdateMultistepSettings,
}: ResourceSteeringMultistepPanelProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const updateMultistepSettingsMutation = useMutation({
    mutationFn: (data: ResourceSteeringMultistepSchemaType) => {
      const payload = {
        isMultistepResource: data.isMultistepResource,
        multistepStrategy: data.isMultistepResource ? data.multistepStrategy : null,
      }
      return updateSteeringMultistep(resourceID, payload.isMultistepResource, payload.multistepStrategy)
    },
    onSuccess: (_, variables) => {
      onUpdateMultistepSettings(variables)
      queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceID }] })
      setEditMode(false)
    },
    onError: (error) => {
      console.error('Failed to update multistep resource settings:', error)
    },
  })

  function onOpenEditMode() {
    setEditMode(true)
  }

  function onCloseEditMode() {
    setEditMode(false)
  }

  function onSave(data: ResourceSteeringMultistepSchemaType) {
    updateMultistepSettingsMutation.mutate(data)
  }

  function getMultistepString(steeringConfig?: ResourceSteeringConfig): string {
    return steeringConfig?.isMultistepResource
      ? t('resources.steering.multistep.is_multistep_description', { strategy: steeringConfig?.multistepStrategy })
      : t('resources.steering.multistep.is_not_multistep_description')
  }

  return (
    <>
      <CardHeader
        sx={{ paddingX: 0 }}
        title={
          <Stack alignItems="center" direction="row" spacing={1}>
            <CustomTypography variant="h6">{t('resources.steering.multistep.label')}</CustomTypography>
            <Tooltip arrow placement="right-start" title={<SteeringMultistepTooltipContent />}>
              <HelpOutlineIcon fontSize="small" sx={{ color: 'black' }} />
            </Tooltip>
          </Stack>
        }
      />
      {editMode && steeringConfig ? (
        <SteeringMultistepForm
          closeEditMode={onCloseEditMode}
          isSaving={false}
          steeringConfig={steeringConfig}
          onSave={onSave}
        />
      ) : (
        <Stack alignItems={'flex-start'} direction={'column'} sx={{ gap: 3, paddingBottom: 3 }}>
          <CustomTypography sx={{ paddingX: 3 }} variant={'body1'}>
            {getMultistepString(steeringConfig)}
          </CustomTypography>
          <CustomButton variant={'outlined'} onClick={onOpenEditMode}>
            {t('resources.steering.multistep.button')}
          </CustomButton>
        </Stack>
      )}
    </>
  )
}

export default SteeringMultistepPanel

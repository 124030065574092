import type { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { z } from 'zod'

import type { AvailabilityStatus } from '@/features/availability/types/availabilityStatus'
import type { Restriction } from '@/features/availability/types/restriction'
import { isBeforeOrEqual } from '@/utils/time'

const luxonDateTime = z.preprocess((arg) => {
  if (arg instanceof DateTime) {
    return arg.toJSDate()
  }
  return arg
}, z.date())

type RestrictionForm = Pick<Restriction, 'type' | 'serviceId'> & {
  id?: number
  startDate: DateTime
  endDate: DateTime
  startTime: DateTime
  endTime: DateTime
  allDay: boolean
  selectedResources: number[]
  location: string
  status?: AvailabilityStatus
}

const getRestrictionFormSchema = (t: TFunction) => {
  return z
    .object({
      type: z.enum(['non-repeating', 'repeating-daily']),
      startDate: luxonDateTime,
      endDate: luxonDateTime,
      startTime: luxonDateTime,
      endTime: luxonDateTime,
      location: z.string(),
      selectedResources: z.array(z.number()),
    })
    .refine(
      (schema) => {
        const startDate = DateTime.fromISO(schema.startDate.toISOString())
        const endDate = DateTime.fromISO(schema.endDate.toISOString())

        return isBeforeOrEqual(startDate, endDate, 'days')
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
        path: ['endDate'],
      },
    )
    .refine(
      (schema) => {
        if (schema.type === 'repeating-daily') {
          return schema.endTime.getTime() > schema.startTime.getTime()
        }

        if (schema.type === 'non-repeating' && schema.endDate.getDate() === schema.startDate.getDate()) {
          return schema.endTime.getTime() > schema.startTime.getTime()
        }

        return true
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_time_before_start_time'),
        path: ['endTime'],
      },
    )
}

// Resource level restriction form schema: duplicate right now, but we want to be flexible in the future

type ResourceLevelRestrictionForm = {
  id?: number
  startDate: DateTime
  endDate: DateTime
  startTime: DateTime
  endTime: DateTime
  allDay: boolean
  affectedResources: string[]
  location: string
  type: 'repeating-daily' | 'non-repeating'
  status?: AvailabilityStatus
  enabled?: boolean
}

const getResourceLevelRestrictionFormSchema = (t: TFunction) => {
  return z
    .object({
      type: z.enum(['non-repeating', 'repeating-daily']),
      startDate: luxonDateTime,
      endDate: luxonDateTime,
      startTime: luxonDateTime,
      endTime: luxonDateTime,
      location: z.string(),
      affectedResources: z.array(z.string()).min(1, {
        message: t('customer_details.tabs.res_level_unavailability.common.form.error.resources_required'),
      }),
    })
    .refine(
      (schema) => {
        const startDate = DateTime.fromISO(schema.startDate.toISOString())
        const endDate = DateTime.fromISO(schema.endDate.toISOString())

        return isBeforeOrEqual(startDate, endDate, 'days')
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
        path: ['endDate'],
      },
    )
    .refine(
      (schema) => {
        if (schema.type === 'repeating-daily') {
          return schema.endTime.getTime() > schema.startTime.getTime()
        }

        if (schema.type === 'non-repeating' && schema.endDate.getDate() === schema.startDate.getDate()) {
          return schema.endTime.getTime() > schema.startTime.getTime()
        }

        return true
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_time_before_start_time'),
        path: ['endTime'],
      },
    )
}

export {
  getResourceLevelRestrictionFormSchema,
  getRestrictionFormSchema,
  ResourceLevelRestrictionForm,
  RestrictionForm,
}

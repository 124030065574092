import { useMutation } from '@tanstack/react-query'

import { deleteResourceLevelRestriction } from '@/features/customer/endpoints/restrictions'

export type useDeleteResourceLevelRestrictionMutationResult = ReturnType<
  typeof useDeleteResourceLevelRestrictionMutation
>

export function useDeleteResourceLevelRestrictionMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; restrictionId: number }) =>
      deleteResourceLevelRestriction(variables.customerUuid, variables.restrictionId),
  })

  return {
    deleteResourceLevelRestriction: mutateAsync,
    ...mutationResult,
  }
}

import { FormGroup, Stack } from '@mui/material'
import type { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid'
import { GridFooter } from '@mui/x-data-grid'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CheckboxController from '@/components/inputs/CheckboxController'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import type { UserRole } from '@/constants/userRoles'
import { USER_ROLES } from '@/constants/userRoles'
import environment from '@/environment'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import type { Customer } from '@/features/customer/types/customer'
import type { Partner } from '@/features/partner/types/partner'
import FormFieldErrorText from '@/features/user/components/form/FormFieldErrorText'
import SelectedCustomersDataGridToolbar, {
  DEFAULT_DATA_GRID_SELECTED_CUSTOMERS_FILTER_MODEL,
} from '@/features/user/components/form/SelectedCustomersDataGridToolbar'
import type { User } from '@/features/user/types/user'

import { SelectedCustomers } from './SelectedCustomers'

interface InformationSectionProps {
  customers: Customer[]
  partners?: Partner[]
}

export default function CustomerAccessSection({ customers, partners = [] }: InformationSectionProps) {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()
  const { control, getValues, setValue, trigger, formState } = useFormContext<User>()
  const [selection, setSelection] = useState<GridRowSelectionModel>([])

  const partnerNames = [...new Set(partners?.map((partner): string => partner.name))]
  const locations = environment.allLocations

  const { role, allowedRoIds, partnerCode } = getValues()

  const isCustomerManager = hasRole(USER_ROLES.CUSTOMER_MANAGERS.value)
  const customersToShow: Customer[] = useMemo(() => {
    const allowedCustomers = isCustomerManager
      ? customers.filter(
          (customer) => customer.partner?.partnerCode === getValues('partnerCode') || !getValues('partnerCode'),
        )
      : customers
    return allowedCustomers.filter((customer) => customer.isActive)
  }, [isCustomerManager, customers, partnerCode])

  const isPartnerAdmin = loggedInUser?.role === USER_ROLES.PARTNER_ADMINISTRATORS.value
  const showExtraColumns = !isPartnerAdmin

  const isHidden = !(
    hasRole(USER_ROLES.RESOURCE_OWNERS.value) ||
    hasRole(USER_ROLES.ELECTRICIANS.value) ||
    hasRole(USER_ROLES.CUSTOMER_MANAGERS.value)
  )

  const shouldIsRestrictedBeHidden =
    hasRole(USER_ROLES.RESOURCE_OWNERS.value) || hasRole(USER_ROLES.CUSTOMER_MANAGERS.value)

  useEffect(() => {
    if (customersToShow.length > 0) {
      setSelection(getValues('allowedRoIds') || [])
    }
  }, [allowedRoIds, customersToShow])

  function hasRole(role: UserRole): boolean {
    return getValues('role') === role
  }

  function triggerCustomerValidation() {
    trigger('isRestricted')
  }

  const handleCustomerSelectionChange = (selectedCustomerUuids) => {
    if (!hasRole(USER_ROLES.CUSTOMER_MANAGERS.value)) {
      setValue('partnerCode', undefined)
    }
    setValue('allowedRoIds', selectedCustomerUuids)

    triggerCustomerValidation()
  }

  const handleDeselectByUuid = (uuidToDeselect: string) => {
    const selectedCustomerUuids = selection.filter((item) => item !== uuidToDeselect)
    handleCustomerSelectionChange(selectedCustomerUuids)
  }

  const columns: GridColDef<Customer>[] = [
    {
      field: 'name',
      headerName: t('common.name') || 'Name',
      flex: 1,
    },
    {
      field: 'partner',
      headerName: t('common.partner') || 'Partner',
      valueGetter: (_, customer: Customer) =>
        partners.find((p) => p.partnerCode === customer.partner?.partnerCode)?.name,
      type: 'singleSelect',
      valueOptions: partnerNames,
      flex: 1,
    },
    {
      field: 'location',
      headerName: t('common.location') || 'Location',
      type: 'singleSelect',
      valueOptions: locations,
      flex: 1,
    },
  ]

  const CustomDataGridFooter = () => (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <FormFieldErrorText showIcon message={formState.errors.isRestricted?.message} />
      <GridFooter
        sx={{
          border: 'none', // To delete double border.
        }}
      />
    </Stack>
  )

  return isHidden ? null : (
    <FormGroup>
      <Stack direction="column" gap={2}>
        <CustomTypography variant="h6">{t('user_form.form.title_customer_access')}</CustomTypography>
        {!shouldIsRestrictedBeHidden && (
          <FormGroup row>
            <CheckboxController
              checked={getValues('isRestricted')}
              controllerProps={{
                control,
              }}
              label={t('user_form.form.restricted_access_label')}
              name="isRestricted"
              showError={false}
              onChange={(event) => {
                setValue('isRestricted', event.target.checked)
                triggerCustomerValidation()
              }}
            />
          </FormGroup>
        )}
      </Stack>

      {getValues('isRestricted') ? (
        <>
          <SelectedCustomers customers={customersToShow} selection={selection} onRemove={handleDeselectByUuid} />

          <CustomDataGrid
            checkboxSelection
            keepNonExistentRowsSelected
            clickableRows={{
              onRowSelectionModelChange: handleCustomerSelectionChange,
            }}
            columnVisibilityModel={{
              // Hide partner and location columns for partner admin user, the other columns will remain visible
              partner: showExtraColumns,
              location: showExtraColumns,
            }}
            columns={columns}
            getRowId={(row) => row.uuid}
            includeWrapper={false}
            initialState={{
              pagination: {
                paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
              },
              filter: {
                filterModel: DEFAULT_DATA_GRID_SELECTED_CUSTOMERS_FILTER_MODEL,
              },
            }}
            rowSelection={{
              multiple: role === USER_ROLES.ELECTRICIANS.value || role === USER_ROLES.CUSTOMER_MANAGERS.value,
              model: selection,
            }}
            rows={customersToShow}
            slotProps={{
              toolbar: {
                locations,
                partnerNames,
                showExtraColumns,
              },
            }}
            slots={{
              toolbar: SelectedCustomersDataGridToolbar,
              footer: CustomDataGridFooter,
            }}
          />
        </>
      ) : (
        <FormFieldErrorText showIcon message={formState.errors.isRestricted?.message} />
      )}
    </FormGroup>
  )
}

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import { Menu } from '@mui/material'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useQueryClient } from '@tanstack/react-query'
import type { MouseEvent } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomAvatar from '@/components/dataDisplay/CustomAvatar'
import CustomTypography from '@/components/dataDisplay/CustomTypography'
import type { User } from '@/features/user/types/user'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

interface Props {
  user: User
  onNavigateToSettings: () => void
  onNavigateToPrivacyPolicy: () => void
  onLogout: () => void
}

const ChipStyled = styled(
  Chip,
  {},
)(({ theme }) => ({
  '&.MuiChip-root': {
    padding: theme.spacing(0.5),
    height: '40px',
    maxWidth: '250px',
    borderRadius: '100px',
  },

  '.MuiChip-avatar': {
    width: '32px',
    height: '32px',
    margin: 0,
  },

  '.MuiChip-label': {
    padding: theme.spacing(0.375, 0.75),
  },
}))

function ProfileMenu({ user, onNavigateToSettings, onNavigateToPrivacyPolicy, onLogout }: Readonly<Props>) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const userRole = user.role ? t(`users.role.${user.role}`) : null
  const queryClient = useQueryClient()
  const { mailto } = useWhitelabelConfigurationContext()

  function handleOpen(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleSettingsClick() {
    onNavigateToSettings()
    handleClose()
  }

  function handlePrivacyPolicyClick() {
    onNavigateToPrivacyPolicy()
    handleClose()
  }

  function handleLogoutClick() {
    // As we're logging out, we don't need to keep the cache for the queries. In that case, let's clear it and avoid
    // information exposed to other users.
    queryClient.clear()
    onLogout()
    handleClose()
  }

  return (
    <>
      <ChipStyled
        avatar={
          <CustomAvatar>
            <PersonIcon titleAccess={t('user_profile.avatar_label')} />
          </CustomAvatar>
        }
        color="secondary"
        label={t('user_profile.button_label', { username: user.username })}
        onClick={handleOpen}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        aria-label={t('user_profile.profile_menu.label')}
        open={open}
        slotProps={{ paper: { sx: { width: 'auto', mt: 1.5, paddingY: 1 } } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleClose}
      >
        <MenuItem sx={{ pointerEvents: 'none', cursor: 'default' }}>
          <Stack sx={{ flexDirection: 'column', width: '100%' }}>
            <CustomTypography noWrap color="text.primary" variant="body1">
              {user.username}
            </CustomTypography>
            {userRole !== null && (
              <CustomTypography color="text.secondary" variant="body2">
                {t('user_profile.profile_menu.role', { userRole })}
              </CustomTypography>
            )}
          </Stack>
        </MenuItem>

        <Divider />

        <MenuItem aria-label={t('user_profile.settings.title')} onClick={handleSettingsClick}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <CustomTypography color="text.primary">{t('user_profile.settings.title')}</CustomTypography>
        </MenuItem>
        <Link href={`mailto:${mailto?.support}`} underline="none">
          <MenuItem aria-label={t('user_profile.support.title')} onClick={handleClose}>
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <CustomTypography color="text.primary">{t('user_profile.support.title')}</CustomTypography>
          </MenuItem>
        </Link>
        <MenuItem aria-label={t('user_profile.settings.title')} onClick={handlePrivacyPolicyClick}>
          <ListItemIcon>
            <PrivacyTipOutlinedIcon />
          </ListItemIcon>
          <CustomTypography color="text.primary">{t('user_profile.privacy_policy.title')}</CustomTypography>
        </MenuItem>

        <Divider />

        <MenuItem aria-label={t('user_profile.sign_out.title')} onClick={handleLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>

          <CustomTypography color="text.primary">{t('user_profile.sign_out.title')}</CustomTypography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ProfileMenu

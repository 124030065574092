import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_SPOT_ON_SCHEDULES_API_ID, getSchedules } from '@/features/resource/endpoints/scheduleBuilder'
import type { Schedule } from '@/features/resource/types/spotOnForEboilers'
import type { UseQueryOptions } from '@/types/queries'

type UseSpotOnSchedulesQueryParams = {
  resourceId: string
}

export type UseSpotOnSchedulesQueryResult = Omit<UseQueryResult, 'data'> & {
  schedules: Schedule[]
}

export function useSpotOnSchedulesQuery(
  params: UseSpotOnSchedulesQueryParams,
  options?: UseQueryOptions<Schedule[]>,
): UseSpotOnSchedulesQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_SPOT_ON_SCHEDULES_API_ID, params],
    queryFn: () => getSchedules(params.resourceId),
  })

  return {
    schedules: data ?? [],
    ...queryResult,
  }
}

import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

// For now it's static, maybe in the future it will need to be fetched from an API.
const DISPLAYED_TIMEZONE = 'Europe/Helsinki'

function SpotOnEboilerDisplayedTimezone() {
  const { t } = useTranslation()

  return (
    <CustomTypography color="textSecondary" variant="body2">
      {t('spot_on_for_eboilers.components.displayed_timezone.text', { timezone: DISPLAYED_TIMEZONE })}
    </CustomTypography>
  )
}

export default SpotOnEboilerDisplayedTimezone

import axios from 'axios'

import environment from '@/environment'
import type { MagActivatedResource } from '@/features/activation/types/activatedResource'
import type { MagActivation } from '@/features/activation/types/activation'

function getCustomerApiUrl(customerUuid: string | undefined) {
  return `${environment.services.monolithGatewayManagerApiUrl}/customers/${customerUuid}`
}

export const GET_MAG_ACTIVATIONS_API_ID = 'GET_MAG_ACTIVATIONS'
export const GET_MAG_ACTIVATION_API_ID = 'GET_MAG_ACTIVATION'
export const GET_MAG_ACTIVATED_RESOURCES_API_ID = 'GET_MAG_ACTIVATED_RESOURCES'

export async function getActivations(
  customerUuid: string | undefined,
  location: string | undefined,
): Promise<MagActivation[]> {
  const CUSTOMER_API_URL = getCustomerApiUrl(customerUuid)
  const response = await axios.get(`${CUSTOMER_API_URL}/activations`, {
    params: {
      location,
    },
  })
  const activations = response.data

  if (!activations || activations.length === 0) {
    return []
  }

  return activations
}

export async function getActivation(
  customerUuid: string,
  activationId: number,
  location?: string | undefined,
): Promise<MagActivation> {
  const CUSTOMER_API_URL = getCustomerApiUrl(customerUuid)
  const response = await axios.get(`${CUSTOMER_API_URL}/activation/${activationId}`, {
    params: {
      location,
    },
  })
  return response.data
}

export async function getActivatedResources(
  customerUuid?: string,
  activationId?: number,
  serviceId?: number,
  location?: string,
): Promise<MagActivatedResource[]> {
  const CUSTOMER_API_URL = getCustomerApiUrl(customerUuid)
  const response = await axios.get<MagActivatedResource[]>(
    `${CUSTOMER_API_URL}/activations/${activationId}/resources`,
    {
      params: {
        location,
        serviceId,
      },
    },
  )
  const activatedResources = response.data

  if (!activatedResources || activatedResources.length === 0) {
    return []
  }

  return activatedResources.map((resource, index) => ({
    id: index,
    ...resource,
  }))
}

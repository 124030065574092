import { AddOutlined } from '@mui/icons-material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Alert, Box, CardHeader, Stack, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { GridRowParams } from '@mui/x-data-grid'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomTypography from '@/components/dataDisplay/CustomTypography'
import DataGridDetailPanelToggle from '@/components/dataDisplay/DataGridDetailPanelToggle'
import CustomButton from '@/components/inputs/CustomButton'
import SteeringRangeDetails from '@/features/resource/components/steering/SteeringRangeDetails'
import SteeringRangeForm from '@/features/resource/components/steering/SteeringRangeForm'
import SteeringRangesTooltipContent from '@/features/resource/components/steering/SteeringRangesTooltipContent'
import type { AddSteeringRangeRequest, UpdateSteeringRangeRequest } from '@/features/resource/endpoints/resources'
import {
  addSteeringRange,
  deleteSteeringRange,
  GET_RESOURCE_API_ID,
  updateSteeringRange,
} from '@/features/resource/endpoints/resources'
import type {
  Resource,
  ResourceSteeringConfig,
  ResourceSteeringRange,
  ResourceSteeringRangeEntry,
} from '@/features/resource/types'
import { convertToKilo } from '@/features/resource/utils/convertToKilo'

export type ResourceSteeringRangesPanelProps = {
  resourceID: Resource['resourceID']
  steeringConfig: ResourceSteeringConfig | undefined
}

export interface SteeringRangesDataGridProps {
  isMultistepResource: boolean
  resourceID: Resource['resourceID']
  steeringRanges: ResourceSteeringRange[]
  onUpdateRange: (rangeId: string, data: UpdateSteeringRangeRequest) => void
  onDeleteRange: (rangeId: string) => void
}

const StyledCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-row--detailPanelExpanded': {
    background: 'rgba(20, 132, 160, 0.08) !important',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDataGrid-row:hover:not(.MuiDataGrid-row--detailPanelExpanded)': {
    background: 'rgba(20, 132, 160, 0.04) !important',
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const SteeringRangesDataGrid = ({
  isMultistepResource,
  resourceID,
  steeringRanges,
  onUpdateRange,
  onDeleteRange,
}: SteeringRangesDataGridProps) => {
  const { t } = useTranslation()
  const [editingRange, setEditingRange] = useState<ResourceSteeringRange | null>(null)

  const convertToSteeringRangePattern = (steeringRange: ResourceSteeringRange): string => {
    const formatValue = (value: number | null) => {
      const kiloValue = convertToKilo(value)
      return kiloValue === null ? '-' : kiloValue.toString()
    }

    return `${formatValue(steeringRange.min.value)}; ${formatValue(steeringRange.max.value)}; ${formatValue(steeringRange.step.value)} kW`
  }

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <DataGridDetailPanelToggle params={params} />,
      sortable: false,
      flex: 0.4,
    },
    {
      field: 'name',
      headerName: t('resources.steering.steering_ranges.name'),
      flex: 2,
      // Temporary solution until we save name of the steering range
      renderCell: (params) => `Steering_range_${params.api.getAllRowIds().indexOf(params.id) + 1}`,
    },
    {
      field: 'dateCreatedAt',
      headerName: t('resources.steering.steering_ranges.date_created'),
      flex: 2,
      valueFormatter: (value: string) => (value ? DateTime.fromISO(value).toFormat('dd MMMM yyyy, HH:mm') : '-'),
    },
    {
      field: 'pattern',
      headerName: t('resources.steering.steering_ranges.pattern'),
      flex: 2,
      valueGetter: (_, row) => convertToSteeringRangePattern(row),
    },
  ]

  return (
    <StyledCustomDataGrid
      disableColumnSorting
      hideFooter
      hideFooterPagination
      hideFooterRowCount
      columns={columns}
      getDetailPanelContent={({ row }: GridRowParams<ResourceSteeringRange>) =>
        editingRange?.id === row.id ? (
          <SteeringRangeForm
            initialData={row}
            isMultistepResource={isMultistepResource}
            resourceID={resourceID}
            onCancel={() => setEditingRange(null)}
            onDelete={() => onDeleteRange(row.id)}
            onSave={(data) => {
              onUpdateRange(row.id, data)
              setEditingRange(null)
            }}
          />
        ) : (
          <SteeringRangeDetails
            isMultistepResource={isMultistepResource}
            resourceSteeringRange={row}
            onEdit={() => setEditingRange(row)}
          />
        )
      }
      getDetailPanelHeight={() => 'auto'}
      getRowId={(row: ResourceSteeringRange) => row.id}
      includeWrapper={false}
      rows={steeringRanges}
    />
  )
}

const SteeringRangesPanel = ({ steeringConfig, resourceID }: ResourceSteeringRangesPanelProps) => {
  const { t } = useTranslation()
  const [isAddingNewRange, setIsAddingNewRange] = useState(false)
  const [localSteeringRanges, setLocalSteeringRanges] = useState<ResourceSteeringRange[]>([])
  const queryClient = useQueryClient()

  useEffect(() => {
    if (steeringConfig) {
      setLocalSteeringRanges(steeringConfig.steeringRanges)
    }
  }, [steeringConfig])

  const addSteeringRangeMutation = useMutation({
    mutationFn: (data: AddSteeringRangeRequest) => addSteeringRange(resourceID, data),
    onMutate: (newRangeData) => {
      const tempId = 'temp-' + Date.now()
      const newRange: ResourceSteeringRange = {
        id: tempId,
        dateCreatedAt: new Date().toISOString(),
        max: { id: `${tempId}-max`, ...newRangeData.max },
        min: { id: `${tempId}-min`, ...newRangeData.min },
        step: { id: `${tempId}-step`, ...newRangeData.step },
        multistepMappings: [],
      }
      setLocalSteeringRanges((prev) => [...prev, newRange])
      setIsAddingNewRange(false)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceID }] })
    },
  })

  const updateSteeringRangeMutation = useMutation({
    mutationFn: ({ rangeId, data }: { rangeId: string; data: UpdateSteeringRangeRequest }) =>
      updateSteeringRange(resourceID, rangeId, data),
    onMutate: ({ rangeId, data }) => {
      setLocalSteeringRanges((prev) =>
        prev.map((range) => {
          if (range.id === rangeId) {
            const updateEntry = (
              current: ResourceSteeringRangeEntry,
              update: Partial<ResourceSteeringRangeEntry>,
            ): ResourceSteeringRangeEntry => ({
              ...current,
              ...update,
              id: current.id,
            })

            return {
              ...range,
              max: updateEntry(range.max, data.max),
              min: updateEntry(range.min, data.min),
              step: updateEntry(range.step, data.step),
            }
          }
          return range
        }),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceID }] })
    },
  })

  const deleteSteeringRangeMutation = useMutation({
    mutationFn: (rangeId: string) => deleteSteeringRange(resourceID, rangeId),
    onMutate: (rangeId) => {
      setLocalSteeringRanges((prev) => prev.filter((range) => range.id !== rangeId))
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceID }] })
    },
  })

  const handleAddSteeringRange = () => setIsAddingNewRange(true)

  return (
    <Stack>
      <CardHeader
        title={
          <Stack alignItems="center" direction="row" spacing={1}>
            <CustomTypography variant="h6">{t('resources.steering.steering_ranges.label')}</CustomTypography>
            <Tooltip arrow placement="right-start" title={<SteeringRangesTooltipContent />}>
              <HelpOutlineIcon fontSize="small" sx={{ color: 'black' }} />
            </Tooltip>
          </Stack>
        }
      />
      {localSteeringRanges.length > 0 ? (
        <SteeringRangesDataGrid
          isMultistepResource={steeringConfig?.isMultistepResource ?? false}
          resourceID={resourceID}
          steeringRanges={localSteeringRanges}
          onDeleteRange={(rangeId) => deleteSteeringRangeMutation.mutate(rangeId)}
          onUpdateRange={(rangeId, data) => updateSteeringRangeMutation.mutate({ rangeId, data })}
        />
      ) : (
        !isAddingNewRange && <Alert severity="info">{t('resources.steering.steering_ranges.info_empty')}</Alert>
      )}
      {isAddingNewRange && (
        <Box mt={2}>
          <SteeringRangeForm
            isMultistepResource={steeringConfig?.isMultistepResource ?? false}
            resourceID={resourceID}
            onCancel={() => setIsAddingNewRange(false)}
            onSave={(data) => addSteeringRangeMutation.mutate(data)}
          />
        </Box>
      )}
      <Box display="flex" justifyContent="flex-start" mt={2}>
        <CustomButton
          disabled={isAddingNewRange}
          startIcon={<AddOutlined />}
          sx={{ mt: 2 }}
          variant="outlined"
          onClick={handleAddSteeringRange}
        >
          {t('resources.steering.steering_ranges.add')}
        </CustomButton>
      </Box>
    </Stack>
  )
}

export default SteeringRangesPanel

import { zodResolver } from '@hookform/resolvers/zod'
import SearchIcon from '@mui/icons-material/Search'
import SendIcon from '@mui/icons-material/Send'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'
import TextFieldController from '@/components/inputs/TextFieldController'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import PageHeader from '@/components/layouts/PageHeader'
import { useAlertContext } from '@/contexts/AlertContext'
import { getResourceSchedule } from '@/features/resource/endpoints/resourceSchedules'
import { useUpsertResourceScheduleMutation } from '@/features/resource/hooks/useUpsertResourceScheduleMutation'
import { getUpsertResourceScheduleSchema } from '@/features/resource/schemas'
import type { UpsertResourceSchedule } from '@/features/resource/types'
import { errorHandler } from '@/utils/errorHandler'

function ResourceSchedulerPage() {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const form = useForm<UpsertResourceSchedule>({
    defaultValues: {
      scheduleJson: '',
    },
    resolver: zodResolver(getUpsertResourceScheduleSchema(t)),
  })

  const { upsertResourceSchedule, isPending } = useUpsertResourceScheduleMutation()

  const [resourceIds, setResourceIds] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [scheduleResponse, setScheduleResponse] = useState('')

  async function handleSubmit() {
    try {
      const formData = form.getValues()
      const scheduleArray = formData.scheduleJson

      await upsertResourceSchedule(scheduleArray)

      pushAlert({
        message: t('resources.scheduler.upsert_schedule_form.success_message'),
        severity: 'success',
      })
    } catch (err) {
      const error = errorHandler(
        err,
        t('resources.scheduler.upsert_schedule_form.upsert_schedule_generic_error_message'),
      )

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  async function handleViewSchedule() {
    try {
      const result = await getResourceSchedule(
        resourceIds.split(','), // Assuming resource IDs are comma-separated
        startTime,
        endTime,
      )

      setScheduleResponse(JSON.stringify(result, null, 2)) // Format JSON response for better readability
      pushAlert({
        message: t('resources.scheduler.view_schedule_form.success_message'),
        severity: 'success',
      })
    } catch (err) {
      const error = errorHandler(err, t('resources.scheduler.view_schedule_form.view_schedule_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <>
      <PageHeader isRoot pageTitle={t('resources.scheduler.scheduler_page_title')} />

      <MainContentContainer>
        <FormProvider {...form}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Left Side: JSON input and Submit button */}
            <div style={{ width: '45%' }}>
              <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
                <TextFieldController
                  fullWidth
                  multiline
                  required
                  label={t('resources.scheduler.upsert_schedule_form.schedule_json_label')}
                  name="scheduleJson"
                  placeholder={t('resources.scheduler.upsert_schedule_form.schedule_json_placeholder')}
                  sx={{ marginBottom: 2 }}
                  onChange={(event) => {
                    form.setValue('scheduleJson', event.target.value)
                  }}
                />
                <CustomButton
                  color="primary"
                  disabled={isPending}
                  startIcon={<SendIcon />}
                  type="submit"
                  variant="contained"
                >
                  {t('resources.scheduler.upsert_schedule_form.upsert_button')}
                </CustomButton>
              </form>
            </div>

            {/* Vertical Line Separator */}
            <div style={{ width: '1px', backgroundColor: '#ccc', margin: '0 20px' }} />

            {/* Right Side: Resource IDs, Start Time, End Time, Query button, and Schedule Response */}
            <div style={{ width: '50%' }}>
              <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <TextFieldController
                  label="Resource IDs"
                  name="resourceIds"
                  placeholder="Enter resource IDs (comma-separated)"
                  style={{ flex: 1 }}
                  value={resourceIds}
                  onChange={(event) => setResourceIds(event.target.value)}
                />
                <TextFieldController
                  label="Start Time (UTC)"
                  name="startTime"
                  placeholder="YYYY-MM-DDTHH:MM:SSZ"
                  style={{ flex: 1 }}
                  value={startTime}
                  onChange={(event) => setStartTime(event.target.value)}
                />
                <TextFieldController
                  label="End Time (UTC)"
                  name="endTime"
                  placeholder="YYYY-MM-DDTHH:MM:SSZ"
                  style={{ flex: 1 }}
                  value={endTime}
                  onChange={(event) => setEndTime(event.target.value)}
                />
              </div>

              <CustomButton
                color="secondary"
                startIcon={<SearchIcon />}
                variant="contained"
                onClick={handleViewSchedule}
              >
                {t('resources.scheduler.view_schedule_form.view_button')}
              </CustomButton>

              {/* Display Schedule Response */}
              <TextFieldController
                fullWidth
                multiline
                label="Schedule Response"
                name="scheduleResponse"
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                sx={{ marginTop: 2 }}
                value={scheduleResponse}
              />
            </div>
          </div>
        </FormProvider>
      </MainContentContainer>
    </>
  )
}

export default ResourceSchedulerPage

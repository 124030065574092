import { Stack } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'

type ReportsAppBarProps = {
  portfolioOptions: string[]
  selectedPortfolio: string
  setSelectedPortfolio: Dispatch<SetStateAction<string | undefined>>
}

function ReportsAppBar({ portfolioOptions, selectedPortfolio, setSelectedPortfolio }: Readonly<ReportsAppBarProps>) {
  const { t, i18n } = useTranslation()

  return (
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <CustomSelectField
          label={t('common.portfolio')}
          options={portfolioOptions.map((portfolio) => ({
            id: portfolio,
            label: portfolio,
            value: portfolio,
          }))}
          size="medium"
          value={selectedPortfolio}
          variant="outlinedWhite"
          onChange={(event) => setSelectedPortfolio(event.target.value)}
        />
      </Stack>
    </LocalizationProvider>
  )
}

export default ReportsAppBar

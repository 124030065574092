import { Typography } from '@mui/material'
import React from 'react'
import type { FieldErrors } from 'react-hook-form'

type ErrorMessageProps = {
  errors: FieldErrors
  name: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errors, name }) => {
  const fieldError = errors[name]
  if (!fieldError) return null

  const error = 'value' in fieldError || 'message' in fieldError ? fieldError : fieldError?.root
  if (!error?.message) return null

  return (
    <Typography color="error" variant="caption">
      {error.message as string}
    </Typography>
  )
}

export default ErrorMessage

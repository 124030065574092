import { Box } from '@mui/material'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { load as loadFonts } from 'webfontloader'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import { usePageMetadata } from '@/components/layouts/PageMetadataContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { FONT_WEIGHTS } from '@/features/whitelabel/common/typography'
import { DEFAULT_WHITELABEL_CONFIG } from '@/features/whitelabel/default'
import { useWhitelabelConfigurationQuery } from '@/features/whitelabel/hooks/useWhitelabelConfigurationQuery'
import type { WhitelabelConfiguration } from '@/features/whitelabel/types/whitelabelConfiguration'

type WhitelabelProviderProps = {
  children: ReactNode
}

const WhitelabelContext = createContext<WhitelabelConfiguration>(DEFAULT_WHITELABEL_CONFIG)

function ThemeProvider(props: Readonly<WhitelabelProviderProps>) {
  const { isStarting, isLogged } = useAuth()
  const { whitelabelConfiguration, isPending, refetch } = useWhitelabelConfigurationQuery(
    { hostname: window.location.hostname, isAuthenticated: isLogged },
    { enabled: !isStarting },
  )
  const { setFavicon, setBrandName } = usePageMetadata()

  const [config, setConfig] = useState<WhitelabelConfiguration>(DEFAULT_WHITELABEL_CONFIG)
  const [isLoadingConfig, setIsLoadingConfig] = useState(true)

  useEffect(() => {
    if (whitelabelConfiguration && !isPending) {
      setConfig(whitelabelConfiguration)
      setFavicon(whitelabelConfiguration.branding.favicon)
      setBrandName(whitelabelConfiguration.branding.name)
      loadFonts({
        google: {
          families: [
            `${whitelabelConfiguration.typography.main}:${FONT_WEIGHTS}`,
            `${whitelabelConfiguration.typography.heading}:${FONT_WEIGHTS}`,
            `${whitelabelConfiguration.typography.fallback}:${FONT_WEIGHTS}`,
          ],
        },
      })
      setIsLoadingConfig(false)
    }
  }, [whitelabelConfiguration, isPending])

  useEffect(() => {
    if (isLogged) {
      refetch()
    }
  }, [isLogged, refetch])

  return (
    <WhitelabelContext.Provider value={config}>
      {isLoadingConfig ? <FullPageSpinner /> : null}
      <Box style={{ display: isLoadingConfig ? 'none' : 'block' }}>{props.children}</Box>
    </WhitelabelContext.Provider>
  )
}

const useWhitelabelConfigurationContext = () => useContext(WhitelabelContext)

export { ThemeProvider, useWhitelabelConfigurationContext, WhitelabelContext }

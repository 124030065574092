import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createScheduledResource } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiCreateScheduledResource } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseCreateSpotOnScheduledResourceMutationResult = Omit<
  UseMutationResult<ApiCreateScheduledResource, Error, ApiCreateScheduledResource>,
  'mutateAsync'
> & {
  createScheduledResource: UseMutationResult<
    ApiCreateScheduledResource,
    Error,
    ApiCreateScheduledResource
  >['mutateAsync']
}

export function useCreateSpotOnScheduledResourceMutation(
  options?: UseMutationOptions<ApiCreateScheduledResource, ApiCreateScheduledResource>,
): UseCreateSpotOnScheduledResourceMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiCreateScheduledResource) => createScheduledResource(variables),
  })

  return { createScheduledResource: mutateAsync, ...mutationResult }
}

export enum Period {
  ALL_TIME,
  PAST_12_MONTHS,
  PAST_MONTH,
  PAST_HOUR,
  PAST_3_HOURS,
  PAST_6_HOURS,
  PAST_12_HOURS,
  PAST_24_HOURS,
  PAST_48_HOURS,
  NEXT_24_HOURS,
  NEXT_48_HOURS,
  NEXT_72_HOURS,
  CUSTOM,
}

export type Month =
  | 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december'
export const MONTHS: Record<number, Month> = {
  1: 'january',
  2: 'february',
  3: 'march',
  4: 'april',
  5: 'may',
  6: 'june',
  7: 'july',
  8: 'august',
  9: 'september',
  10: 'october',
  11: 'november',
  12: 'december',
}

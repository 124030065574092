import { Card } from '@mui/material'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import CustomCardContent from '@/components/layouts/CustomCardContent'
import { ManualSteeringForm } from '@/features/resource/components/steering/ManualSteeringForm'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

const ResourceSteeringPage = () => {
  const { resourceUuid } = useParams()
  const { setActivePage } = useResourceDetails()

  const { steeringConfig } = useResourceWithPollingQuery({ id: resourceUuid! })

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_MANUAL_STEERING.routePath)
  }, [setActivePage])

  if (!steeringConfig) return null

  return (
    <Card sx={{ marginY: 3 }}>
      <CustomCardContent>
        <ManualSteeringForm
          isReleaseControlSupported={steeringConfig?.isReleaseControlSupported ?? false}
          resourceId={resourceUuid!}
          steeringDefaultNotDefined={steeringConfig?.steeringDefaultType === 'NONE'}
          onSteeringComplete={() => {}}
        />
      </CustomCardContent>
    </Card>
  )
}

export default ResourceSteeringPage

import { DateTime } from 'luxon'
import type { DateTime as DateTimeType } from 'luxon/src/datetime'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Period } from '@/constants/period'
import BaselineChart from '@/features/report/components/BaselineChart'
import {
  useForecastXyBaselineQuery,
  useInvalidateForecastXyBaselineQuery,
} from '@/features/report/hooks/useForecastXyBaselineQuery'
import { calculatePeriodEndTime } from '@/features/report/utils/utils'

const FORECAST_LINE_COLOR = '#5ab1ef'

type ForecastXyBaselineChartProps = {
  portfolio: string
  timezone: string
}

function ForecastXyBaselineChart({ portfolio, timezone }: Readonly<ForecastXyBaselineChartProps>) {
  const { t } = useTranslation()

  const [startTime, setStartTime] = useState<DateTimeType>(DateTime.now())
  const [endTime, setEndTime] = useState<DateTimeType>(calculatePeriodEndTime(Period.NEXT_24_HOURS))

  const { isLoading, timeData, baselineSeries } = useForecastXyBaselineQuery({ startTime, endTime, portfolio })

  const invalidateForecastXyBaselineQuery = useInvalidateForecastXyBaselineQuery()

  useEffect(() => {
    invalidateForecastXyBaselineQuery()
  }, [startTime, endTime, portfolio])

  return (
    <BaselineChart
      allowedEndTimeInFuture
      baselineSeries={baselineSeries}
      endTime={endTime}
      infoText={t('reports.baseline.predicted.info')}
      initialPeriodSelection={Period.NEXT_24_HOURS}
      isLoading={isLoading}
      lineColor={FORECAST_LINE_COLOR}
      lineType="dashed"
      setEndTime={setEndTime}
      setStartTime={setStartTime}
      startTime={startTime}
      timeData={timeData}
      timezone={timezone}
      title={t(`reports.baseline.predicted.title`)}
    />
  )
}

export default ForecastXyBaselineChart

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import type { MenuItemProps } from '@mui/material'
import { FormHelperText, Tooltip } from '@mui/material'
import type { FormControlProps } from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import type { SelectProps } from '@mui/material/Select'
import Select from '@mui/material/Select'
import type { ForwardedRef, ReactNode } from 'react'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import CustomFormControl from '@/components/inputs/CustomFormControl'

export type SelectFieldOption<V> = MenuItemProps & {
  id: string
  value: V
  icon?: ReactNode
  label: string
  tooltip?: string
  disabled?: boolean
}

export type CustomSelectFieldProps<V> = Omit<SelectProps<V>, 'variant'> & {
  size?: FormControlProps['size']
  error?: boolean
  helperText?: string
  options: SelectFieldOption<V>[]
  variant?: 'outlined' | 'outlinedWhite'
  showAllOption?: boolean
}

/**
 * It modifies the MUI SelectField component to add some Sympower customizations:
 *
 * - Adds a new 'outlinedWhite' variant.
 * - Restrict the variant prop to 'outlined' and 'outlinedWhite',
 *
 */
function CustomSelectField<V extends string | ReadonlyArray<string> | number | undefined>(
  {
    fullWidth,
    helperText,
    showAllOption,
    variant = 'outlined',
    disabled,
    color,
    value,
    required,
    ...props
  }: CustomSelectFieldProps<V>,
  ref: ForwardedRef<HTMLSelectElement>,
) {
  const { error, sx, id, label, options, size } = props
  const { t } = useTranslation()

  const labelId = `${id}-label`
  const defaultOptions: SelectFieldOption<string>[] = showAllOption
    ? [{ id: 'all', value: '', label: t('common.all') }]
    : []
  const commonItemStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  }

  return (
    <CustomFormControl
      color={color}
      disabled={disabled}
      error={Boolean(error)}
      fullWidth={fullWidth}
      required={required}
      size={size}
      sx={sx}
      variant={variant}
    >
      <InputLabel id={labelId} shrink={showAllOption}>
        {label}
      </InputLabel>

      <Select
        {...props}
        ref={ref}
        color={color}
        disabled={disabled}
        displayEmpty={showAllOption}
        fullWidth={fullWidth}
        labelId={labelId}
        required={required}
        sx={{
          ...sx,
          '& .MuiSelect-select': commonItemStyles,
          // We need to set maxWidth to 100% to remove the label border
          legend: showAllOption ? { maxWidth: '100%' } : '',
        }}
        value={value}
        variant={variant === 'outlinedWhite' ? 'outlined' : variant}
      >
        {[...defaultOptions, ...options].map(({ id, value, label, icon, tooltip, disabled = false, ...props }) => (
          <MenuItem {...props} key={id} disabled={disabled} sx={commonItemStyles} value={value}>
            <>
              {icon}
              {label}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <HelpOutlineIcon fontSize="small" sx={{ color: '#000' }} />
                </Tooltip>
              )}
            </>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </CustomFormControl>
  )
}

export default forwardRef(CustomSelectField) as typeof CustomSelectField

import type { UseQueryResult } from '@tanstack/react-query'
import { useIsFetching, useQuery } from '@tanstack/react-query'

import type { MarketProgram } from '@/features/bidding/constants'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { BidVolumes } from '@/features/bidding/types/bid'
import {
  CALCULATE_BASIS_FOR_NEW_BID_API_ID,
  calculateBasisForNewBid,
} from '@/features/bidding/utils/calculations/calculateBasisForNewBid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import type { UseQueryOptions } from '@/types/queries'

type CalculateBidBasisQueryParams = {
  date: MarketDate
  portfolioCode: string
  marketProgram: MarketProgram
  activationGroupUuid: string
}
export type UseCalculateBidBasisQueryResult = Omit<UseQueryResult, 'data'> & {
  bidBasis?: BidVolumes
}

export function useCalculateBidBasisQuery(
  params: CalculateBidBasisQueryParams,
  options?: UseQueryOptions<BidVolumes>,
): UseCalculateBidBasisQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [CALCULATE_BASIS_FOR_NEW_BID_API_ID, params],
    queryFn: () =>
      calculateBasisForNewBid(params.date, params.portfolioCode, params.marketProgram, params.activationGroupUuid),
  })

  useErrorHandler(queryResult.isError)

  return { bidBasis: data, ...queryResult }
}

export function useIsCalculatingBidBasis(): boolean {
  const calculateBidBasisFetchingCount = useIsFetching({ queryKey: [CALCULATE_BASIS_FOR_NEW_BID_API_ID] })

  return calculateBidBasisFetchingCount > 0
}

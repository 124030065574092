import { Stack, TextField } from '@mui/material'
import type { ChangeEvent, FocusEvent } from 'react'
import { useEffect, useRef } from 'react'

import CustomTypography from './CustomTypography'

interface DataGridTextFieldProps {
  value: string
  readonlyField?: boolean
  selectValueOnFocus?: boolean
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
}

const DataGridTextField = ({
  value,
  selectValueOnFocus,
  onChange,
  onBlur,
}: Omit<DataGridTextFieldProps, 'readonlyField'>) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    inputRef.current?.focus()

    if (selectValueOnFocus) {
      inputRef.current?.select()
    }
  }, [])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value)

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => onBlur?.(event.target.value)

  return (
    <TextField
      focused
      fullWidth
      color="primary"
      inputRef={inputRef}
      slotProps={{
        input: {
          sx: { fontSize: '0.875rem' },
        },
      }}
      type="text"
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

/**
 * This component is useful when you want to use a TextField as a cell editor in a DataGrid.
 *
 * If you need an editable cell, you need to include the renderCell as well as the renderEditCell props of the DataGrid.
 *
 * For example:
 *
 * renderCell: (params: GridRenderCellParams<PtuVolumes>) => (
 *   <DataGridEditableTextField readonlyField value={params.formattedValue} />
 * ),
 * renderEditCell: (params: GridRenderEditCellParams<PtuVolumes>) => (
 *   <DataGridEditableTextField value={params.value} onChange={onAcceptedChange(params)} />
 * ),
 */
const DataGridEditableTextField = ({
  value,
  readonlyField,
  selectValueOnFocus,
  onChange,
  onBlur,
}: DataGridTextFieldProps) => {
  if (readonlyField) {
    return (
      <Stack
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: '100%',
        }}
      >
        <CustomTypography
          sx={{
            padding: '9.5px 14px',
            borderRadius: '4px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            width: '100%',
            backgroundColor: 'white',
          }}
          tabIndex={0}
          variant="body2"
        >
          {value}
        </CustomTypography>
      </Stack>
    )
  }

  return <DataGridTextField selectValueOnFocus={selectValueOnFocus} value={value} onBlur={onBlur} onChange={onChange} />
}

export default DataGridEditableTextField

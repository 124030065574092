import axios from 'axios'

import environment from '@/environment'
import type { WhitelabelConfiguration } from '@/features/whitelabel/types/whitelabelConfiguration'

export type GetWhitelabelConfigurationResponse = {
  partnerCode?: string
  publicConfig: Omit<WhitelabelConfiguration, 'partnerCode' | 'mailto'>
  protectedConfig?: Pick<WhitelabelConfiguration, 'mailto'>
}

export async function getPublicWhitelabelConfiguration(hostname: string): Promise<GetWhitelabelConfigurationResponse> {
  const response = await axios.get(`${environment.services.whitelabelConfigManagerPublicApiUrl}/${hostname}`)
  return {
    partnerCode: response.data.partnerCode,
    publicConfig: JSON.parse(response.data.publicConfig),
    protectedConfig: JSON.parse(response.data.protectedConfig),
  }
}

export async function getProtectedWhitelabelConfiguration(
  hostname: string,
): Promise<GetWhitelabelConfigurationResponse> {
  const response = await axios.get(`${environment.services.whitelabelConfigManagerApiUrl}/${hostname}`)
  return {
    partnerCode: response.data.partnerCode,
    publicConfig: JSON.parse(response.data.publicConfig),
    protectedConfig: JSON.parse(response.data.protectedConfig),
  }
}

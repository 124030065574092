import type { GridColDef, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid'
import { GridFilterInputDate } from '@mui/x-data-grid'
import { DateTime } from 'luxon'

function createFilterOperator(
  label: string,
  value: string,
  condition: (cellValue: DateTime, filterValue: DateTime, startDate: DateTime, endDate: DateTime) => boolean,
  customerTimeZone: string,
  dateTimeZone: string,
): GridFilterOperator {
  return {
    label,
    value,
    getApplyFilterFn: ({ field, value: filterValue, operator }: GridFilterItem, column: GridColDef) => {
      if (!field || !filterValue || !operator) {
        return null
      }

      return (paramValue, row) => {
        if (column.type !== 'date' || !paramValue) {
          return false
        }

        // Normalize all dates to start of day in customer timezone
        const startDate = DateTime.fromISO(row.startDate, { zone: dateTimeZone })
          .setZone(customerTimeZone)
          .startOf('day')

        const endDate = DateTime.fromISO(row.endDate, { zone: dateTimeZone }).setZone(customerTimeZone).startOf('day')

        const filterDateTime = DateTime.fromISO(filterValue, { zone: customerTimeZone }).startOf('day')

        if (!startDate.isValid || !endDate.isValid) {
          return false
        }

        return condition((paramValue as DateTime).startOf('day'), filterDateTime, startDate, endDate)
      }
    },
    InputComponentProps: {
      type: 'date',
    },
    InputComponent: GridFilterInputDate,
  }
}

export function getGridDateIncludesOperators(
  customerTimeZone: string,
  dateTimeZone: string = 'UTC', // represents the timezone of the date time values got from the backend
): GridFilterOperator[] {
  return [
    createFilterOperator(
      'includes',
      'includes',
      (_cellValue, filterValue, startDate, endDate) => {
        return filterValue >= startDate && filterValue <= endDate
      },
      customerTimeZone,
      dateTimeZone,
    ),
    createFilterOperator(
      'includes or after',
      'includesOrAfter',
      (_cellValue, filterValue, startDate) => {
        return startDate >= filterValue
      },
      customerTimeZone,
      dateTimeZone,
    ),
    createFilterOperator(
      'includes or before',
      'includesOrBefore',
      (_cellValue, filterValue, _startDate, endDate) => {
        return endDate <= filterValue
      },
      customerTimeZone,
      dateTimeZone,
    ),
  ]
}

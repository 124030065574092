import type { MfaSettings } from '@/features/user/types/user'

// All the validations for the mfaSettings are done in the user schema. This function does not validate anything.
// It is used exclusively to keep consistency with the preferredOption and the is*Enabled properties.
export function getNewPayloadToUpdateMfaSettings(payload: MfaSettings): MfaSettings {
  if (payload.preferredOption === 'sms') {
    return {
      isSmsEnabled: true,
      isTotpEnabled: false,
      preferredOption: 'sms',
    }
  }

  if (payload.preferredOption === 'totp') {
    return {
      isSmsEnabled: false,
      isTotpEnabled: true,
      preferredOption: 'totp',
    }
  }

  return {
    isSmsEnabled: false,
    isTotpEnabled: false,
    preferredOption: null,
  }
}

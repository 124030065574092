import { DateTime } from 'luxon'

import { Period } from '@/constants/period'
import type { TimeRange } from '@/features/activation/types/timeRange'

export function calculatePeriodStartAndEndTime(
  period: Period,
  currentTimeRange: TimeRange,
  dateOfFirstActivation: DateTime | undefined,
  timezone: string,
): TimeRange {
  const zonedNow = DateTime.now().setZone(timezone)

  let startTime: DateTime
  switch (period) {
    case Period.ALL_TIME:
      startTime = dateOfFirstActivation ? dateOfFirstActivation.setZone(timezone) : zonedNow.minus({ years: 1 })
      break
    case Period.PAST_MONTH:
      startTime = zonedNow.minus({ months: 1 })
      break
    case Period.PAST_24_HOURS:
      startTime = zonedNow.minus({ hours: 24 })
      break
    case Period.PAST_12_MONTHS:
      startTime = zonedNow.minus({ years: 1 })
      break
    default:
      return currentTimeRange
  }
  return { from: startTime, to: zonedNow }
}

export function formatDateTimeForPeriodSelection(dateTime: DateTime) {
  return dateTime.toFormat('dd-MM-yyyy, HH:mm')
}

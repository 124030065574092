import type { DateTime } from 'luxon'

import type { Bid } from '@/features/bidding/types/bid'
import { convertToRoundedMw, round } from '@/features/bidding/utils/calculations/convertToRoundedMw'

export type PriceCapacity = { price: number; capacityMw: number }

export const getPtuPriceCapacitiesFromBids = (ptuStart: DateTime, bids: Bid[]): PriceCapacity[] => {
  const bidPriceCapacities = bids.flatMap((bid) => getPtuPriceCapacitiesFromBid(ptuStart, bid))
  const prices = bidPriceCapacities.map((priceCapacity) => priceCapacity.price)
  const uniquePrices = Array.from(new Set(prices)).sort((a, b) => a - b)

  return uniquePrices.map((price) => ({
    price: price,
    capacityMw: round(bidPriceCapacities.reduce((total, pc) => total + (pc.price === price ? pc.capacityMw : 0), 0)),
  }))
}

const getPtuPriceCapacitiesFromBid = (ptuStart: DateTime, bid: Bid) => {
  return (
    bid.offeredBid
      .find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))!
      .ptuChunks?.map((priceChunk) => ({
        price: priceChunk.offeredPrice,
        capacityMw: convertToRoundedMw(priceChunk.offeredVolume.quantity),
      })) || []
  )
}

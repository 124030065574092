import { EuroOutlined } from '@mui/icons-material'
import { Box, FormControl, InputAdornment, Stack } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomButton from '@/components/inputs/CustomButton'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import FloatTextField from '@/components/inputs/FloatTextField'
import { DEFAULT_CURRENCY, MAXIMUM_MW_PER_PTU } from '@/features/bidding/constants'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

/**
 * Module augmentation is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends HourlyPriceDataGridToolbarProps {}
}

type HourlyPriceDataGridToolbarProps = {
  onFill: (value: number | undefined, priceColumn: number | undefined) => void
  onSetPriceColumnsClick: () => void
  deliveryDay: MarketDate
  priceColumns: number[]
}

function HourlyPriceDataGridToolbar({
  onFill,
  onSetPriceColumnsClick,
  deliveryDay,
  priceColumns,
}: Readonly<HourlyPriceDataGridToolbarProps>) {
  const { t, i18n } = useTranslation()

  const [bulkFillSelectedColumn, setBulkFillSelectedColumn] = useState<number | undefined>()
  const [bulkFillValue, setBulkFillValue] = useState<number | undefined>()

  return (
    <GridToolbar
      actions={
        <>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <FormControl>
              <BulkFillInputField setBulkFillValue={setBulkFillValue} />
            </FormControl>
            <BulkFillColumnsSelect
              bulkFillSelectedColumn={bulkFillSelectedColumn}
              priceColumns={priceColumns}
              setBulkFillSelectedColumn={setBulkFillSelectedColumn}
            />
          </Stack>
          <CustomButton
            data-testid="fill-column-button"
            disabled={bulkFillValue == undefined || bulkFillSelectedColumn == undefined}
            variant="contained"
            onClick={() => onFill(bulkFillValue, bulkFillSelectedColumn)}
          >
            {t('bidding.create_bid.fill')}
          </CustomButton>
          <CustomButton
            data-testid="price-column-button"
            startIcon={<EuroOutlined />}
            variant="outlined"
            onClick={onSetPriceColumnsClick}
          >
            {t('bidding.create_bid.set_price_columns')}
          </CustomButton>
        </>
      }
      filters={
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <DatePicker
            disabled
            defaultValue={deliveryDay.getStartOfDay()}
            format="DD"
            label={t('bidding.create_bid.bid_date')}
            sx={{ width: 180 }}
          />
        </LocalizationProvider>
      }
    />
  )
}

function BulkFillInputField({ setBulkFillValue }) {
  return (
    <Box width={100}>
      <FloatTextField
        max={MAXIMUM_MW_PER_PTU}
        min={0}
        textFieldProps={{
          InputProps: {
            endAdornment: <InputAdornment position="start">{'MW'}</InputAdornment>,
          },
          inputProps: { 'data-testid': 'bulk-fill-value' },
        }}
        onValueChange={setBulkFillValue}
      />
    </Box>
  )
}

function BulkFillColumnsSelect({ bulkFillSelectedColumn, setBulkFillSelectedColumn, priceColumns }) {
  const { t, i18n } = useTranslation()

  const options = priceColumns.map((price: number) => {
    const formattedPrice = new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: DEFAULT_CURRENCY,
    }).format(price)

    return {
      id: price,
      value: price,
      label: formattedPrice,
    }
  })
  return (
    <CustomSelectField
      data-testid="bulk-fill-columns"
      id="bulk-fill-columns"
      label={t('bidding.create_bid.column')}
      options={options}
      sx={{ minWidth: 100 }}
      value={bulkFillSelectedColumn ?? ''}
      onChange={(event) => setBulkFillSelectedColumn(event.target.value)}
    />
  )
}

export default HourlyPriceDataGridToolbar

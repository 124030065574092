import { Stack } from '@mui/material'
import type { DateTime } from 'luxon'
import type { Dispatch, SetStateAction } from 'react'

import type { Period } from '@/constants/period'
import ActivationTimeRangeFilter from '@/features/activation/components/ActivationTimeRangeFilter'
import { ExportCsvButton } from '@/features/activation/components/ExportCsvButton'
import type { Activation } from '@/features/activation/types/activation'
import type { TimeRange } from '@/features/activation/types/timeRange'
import type { MarketProgramType } from '@/types/marketProgramType'

type ActivationsAppBarProps = {
  isLoading?: boolean
  dateOfFirstActivation: DateTime | undefined
  periodSelection: Period
  timeRange: TimeRange
  setTimeRange: Dispatch<SetStateAction<TimeRange>>
  setPeriodSelection: Dispatch<SetStateAction<Period>>
  activationsData: Activation[] | undefined
  marketProgramsTypes: Map<number | undefined, MarketProgramType | undefined>
  timezone?: string
}

function ActivationsAppBar({
  isLoading,
  dateOfFirstActivation,
  periodSelection,
  setPeriodSelection,
  timeRange,
  setTimeRange,
  activationsData,
  timezone,
}: Readonly<ActivationsAppBarProps>) {
  if (isLoading) return null

  return (
    <Stack flexDirection={'row'} justifyContent="space-between" sx={{ width: '100%' }}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <ActivationTimeRangeFilter
          dateOfFirstActivation={dateOfFirstActivation}
          periodSelection={periodSelection}
          setPeriodSelection={setPeriodSelection}
          setTimeRange={setTimeRange}
          timeRange={timeRange}
          timezone={timezone}
        />
      </Stack>
      <ExportCsvButton data={activationsData ?? []} timezone={timezone} />
    </Stack>
  )
}

export default ActivationsAppBar

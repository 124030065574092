import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import type { ReactElement } from 'react'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

type OfferedPriceVolumeOutOfRangeTooltipProps = {
  children: ReactElement
  disabled: boolean
}

const OfferedPriceVolumeOutOfRangeTooltip = forwardRef<HTMLDivElement, OfferedPriceVolumeOutOfRangeTooltipProps>(
  function OfferedPriceVolumeOutOfRangeTooltip(props, ref) {
    const { children, disabled } = props

    return (
      <Tooltip disableHoverListener={disabled} title={<TooltipTitle />}>
        <div ref={ref}>{children}</div>
      </Tooltip>
    )
  },
)

function TooltipTitle() {
  const { t } = useTranslation()

  return (
    <Typography aria-label="tooltip-content" style={{ fontSize: '14px' }}>
      {t('bidding.dialog.offered_price_volume_out_of_range.description')}
    </Typography>
  )
}

export default OfferedPriceVolumeOutOfRangeTooltip

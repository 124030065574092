import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import {
  GET_SPOT_ON_SCHEDULED_RESOURCE_API_ID,
  getScheduledResource,
} from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiScheduledResource } from '@/features/resource/types/spotOnForEboilers'
import type { UseQueryOptions } from '@/types/queries'

type UseSpotOnScheduledResourceQueryParams = {
  resourceId: string
}

export type UseSpotOnScheduledResourceQueryResult = Omit<UseQueryResult, 'data'> & {
  scheduledResource: ApiScheduledResource | null
}

export function useSpotOnScheduledResourceQuery(
  params: UseSpotOnScheduledResourceQueryParams,
  options?: UseQueryOptions<ApiScheduledResource>,
): UseSpotOnScheduledResourceQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_SPOT_ON_SCHEDULED_RESOURCE_API_ID, params],
    queryFn: () => getScheduledResource(params.resourceId),
  })

  return {
    scheduledResource: data ?? null,
    ...queryResult,
  }
}

import { DialogContent } from '@mui/material'
import { t } from 'i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import type { ResourceLevelRestriction } from '@/features/availability/types/restriction'
import { ResourceLevelRestrictionDetailsForm } from '@/features/customer/components/availability/restriction/form/resourceLevelUnavailaibility/ResourceLevelRestrictionDetailsForm'

interface ResourceLevelRestrictionDetailsEditDialogProps {
  open: boolean
  onClose: () => void
  customerTimeZone: string
  customerLocation: string
  customerUuid: string
  restriction: ResourceLevelRestriction
}

export const ResourceLevelRestrictionDetailsEditDialog = (props: ResourceLevelRestrictionDetailsEditDialogProps) => {
  return (
    <CustomDialog
      open={props.open}
      size="large"
      title={t('customer_details.tabs.availability.unavailability_form.edit_dialog.title')}
      onClose={props.onClose}
    >
      <DialogContent>
        <ResourceLevelRestrictionDetailsForm
          customerLocation={props.customerLocation}
          customerTimeZone={props.customerTimeZone}
          customerUuid={props.customerUuid}
          restriction={props.restriction}
          onClose={props.onClose}
        />
      </DialogContent>
    </CustomDialog>
  )
}

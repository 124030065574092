import { WATTS_IN_MW } from '@/constants/units'
import { decimalAdjust } from '@/features/bidding/utils/calculations/decimalAdjust'

export const convertToRoundedMw = (watts: number): number => round(watts / WATTS_IN_MW)

export const round = (number: number) => (number % 1 === 0 ? number : Math.round(number * 100) / 100)

export const roundWattsToNearest10KW = (watts: number, ratio: number = 1): number => {
  // Calculate the proportion of the total capacity that an activation group has
  const proportion = watts * ratio
  // First convert the proportion to megawatts using decimalAdjust so to avoid floating point errors
  const mwProportion = decimalAdjust('round', proportion / WATTS_IN_MW, -2)
  // Convert back to watts and return the result as a number using decimalAdjust so to avoid floating point errors
  return decimalAdjust('round', mwProportion * WATTS_IN_MW, -2)
}

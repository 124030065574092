import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid2,
  Link,
  Tooltip,
} from '@mui/material'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

import { useAlertContext } from '@/contexts/AlertContext'
import environment from '@/environment'
import { decommissionResource, republishResource, resyncResource } from '@/features/resource/endpoints/resources'
import { errorHandler } from '@/utils/errorHandler'

const ResourceSupportToolboxCard = ({ resourceId }) => {
  const { pushAlert } = useAlertContext()

  const { dashboards, environmentName } = environment

  // Opensearch/Kibana constants to match the ENV with UUID
  const openSearchDataSet = {
    //graylog_*, the query won't work without wrapping index with single quotes
    staging: "'18873440-41c8-11ea-bd98-b18e89b7b7f6'",
    //platform-production_*, the query won't work when the index is quoted
    production: 'f6cfeae0-41c7-11ea-bd98-b18e89b7b7f6',
  }

  // Memoize kibana URL based on resourceId
  const openSearchUrl = useMemo(() => {
    const baseUrl = `${dashboards.kibanaBaseUrl}/app/data-explorer/discover#?_a=(metadata:(indexPattern:~env-placeholder~,view:discover))&_q=(query:(language:kuery,query:'message:"${resourceId}"'))`
    return environmentName === 'production'
      ? baseUrl.replace('~env-placeholder~', openSearchDataSet.production)
      : baseUrl.replace('~env-placeholder~', openSearchDataSet.staging)
  }, [resourceId])

  // Memoize grafana URL based on resourceId
  const grafanaUrl = useMemo(() => {
    const baseUrl = `${dashboards.grafanaBaseUrl}/d/ee3thy3tqhqtcc/resource-insight?orgId=1&from=now-1h&to=now&timezone=utc&var-environment=~env-placeholder~&var-resourceId=${resourceId}`
    return environmentName === 'production'
      ? baseUrl.replace('~env-placeholder~', 'production')
      : baseUrl.replace('~env-placeholder~', 'staging')
  }, [resourceId])

  // State to manage the toggle switches for republish options, with all flags set to true by default
  const [flags, setFlags] = useState({
    enableConfigRepublish: true,
    enableSteeringRepublish: true,
    enableSteerabilityAndBatteryRepublish: true,
  })

  // Functions for buttons
  const handleDecommission = async () => {
    try {
      await decommissionResource(resourceId)
    } catch (err) {
      const error = errorHandler(err, t('resources.support_toolbox.errors.decommission_error'))
      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  const handleResync = async () => {
    try {
      await resyncResource(resourceId)
    } catch (err) {
      const error = errorHandler(err, t('resources.support_toolbox.errors.resync_error'))
      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  const handleRepublish = async () => {
    try {
      const requestPayload = {
        config: flags.enableConfigRepublish,
        steering: flags.enableSteeringRepublish,
        steerabilityAndBattery: flags.enableSteerabilityAndBatteryRepublish,
      }
      await republishResource(resourceId, requestPayload)
    } catch (err) {
      const error = errorHandler(err, t('resources.support_toolbox.errors.republish_error'))
      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <Card sx={{ marginY: 3, marginLeft: 'auto', marginRight: 'auto' }}>
      <CardHeader title={t('resources.support_toolbox.title')} />
      <CardContent>
        <Grid2 container spacing={3}>
          {/* Left Column */}
          <Grid2 component="div" sx={{ xs: 12, md: 6 }}>
            <Box sx={{ marginBottom: 2 }}>
              <Link href={grafanaUrl} rel="noopener noreferrer" target="_blank">
                {t('resources.support_toolbox.grafana_link_label')}
              </Link>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <Link href={openSearchUrl} rel="noopener noreferrer" target="_blank">
                {t('resources.support_toolbox.kibana_link_label')}
              </Link>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <Button fullWidth color="secondary" variant="contained" onClick={handleDecommission}>
                {t('resources.support_toolbox.decommission_button_label')}
              </Button>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <Tooltip arrow title="This operation is asynchronous, and the effect will not be immediate">
                <Button fullWidth color="secondary" variant="contained" onClick={handleResync}>
                  {t('resources.support_toolbox.resync_button_label')}
                </Button>
              </Tooltip>
            </Box>
          </Grid2>

          {/* Right Column */}
          <Grid2 component="div" sx={{ xs: 12, md: 6 }}>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                padding: 2,
                marginBottom: 2,
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flags.enableConfigRepublish}
                      color="primary"
                      onChange={() =>
                        setFlags((prevFlags) => ({
                          ...prevFlags,
                          enableConfigRepublish: !prevFlags.enableConfigRepublish,
                        }))
                      }
                    />
                  }
                  label={t('resources.support_toolbox.republish.config_label')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flags.enableSteeringRepublish}
                      color="primary"
                      onChange={() =>
                        setFlags((prevFlags) => ({
                          ...prevFlags,
                          enableSteeringRepublish: !prevFlags.enableSteeringRepublish,
                        }))
                      }
                    />
                  }
                  label={t('resources.support_toolbox.republish.steering_label')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flags.enableSteerabilityAndBatteryRepublish}
                      color="primary"
                      onChange={() =>
                        setFlags((prevFlags) => ({
                          ...prevFlags,
                          enableSteerabilityAndBatteryRepublish: !prevFlags.enableSteerabilityAndBatteryRepublish,
                        }))
                      }
                    />
                  }
                  label={t('resources.support_toolbox.republish.steerability_and_battery_label')}
                />
              </FormGroup>
              <Tooltip arrow title="This operation is asynchronous, and the effect will not be immediate">
                <Button fullWidth color="secondary" variant="contained" onClick={handleRepublish}>
                  {t('resources.support_toolbox.republish.button_label')}
                </Button>
              </Tooltip>
            </Box>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  )
}

export default ResourceSupportToolboxCard

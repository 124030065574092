import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

export function NoPortfolio() {
  const { t } = useTranslation()
  return (
    <Stack mt={2}>
      <CustomTypography variant="body1">{t('reports.no_portfolios')}</CustomTypography>
    </Stack>
  )
}

import axios from 'axios'

import { MARKET_TIMEZONE } from '@/features/bidding/constants'
import { PORTFOLIO_MANAGER_API_URL } from '@/features/bidding/endpoints/bids'
import type { ApiBidWithContext } from '@/features/bidding/types/api'
import type { Bid, Forecast } from '@/features/bidding/types/bid'
import type { HistoricalCapacity } from '@/features/bidding/types/historicalCapacity'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import {
  bidWithContextWithLuxonDates,
  forecastWithLuxonDates,
  toInternalBid,
  toInternalForecast,
  toLuxonDateTime,
} from '@/features/bidding/utils/model/modelConversion'

export const getForecastInsights = async (
  activationGroupUuid: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Forecast[] | null> => {
  const response: Forecast[] = await axios
    .get(`${PORTFOLIO_MANAGER_API_URL}/insights/forecast`, {
      params: {
        activationGroupUuid: activationGroupUuid,
        fromDeliveryDay: fromDeliveryDay.toISODate(),
        toDeliveryDay: toDeliveryDay.toISODate(),
      },
    })
    .then((response) =>
      response.data
        .map((forecast: any) => forecastWithLuxonDates(forecast))
        .map((forecast: any) => toInternalForecast(forecast)),
    )
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null // Not found
      }
      throw error
    })

  if (!response || response.length === 0) {
    return null
  }

  return response
}

export const getBidInsights = async (
  activationGroupUuid: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Bid[] | null> => {
  const response = await axios
    .get(`${PORTFOLIO_MANAGER_API_URL}/insights/bid`, {
      params: {
        activationGroupUuid: activationGroupUuid,
        fromDeliveryDay: fromDeliveryDay.toISODate(),
        toDeliveryDay: toDeliveryDay.toISODate(),
      },
    })
    .then((response) =>
      response.data
        .map((bidWithContext: any) => bidWithContextWithLuxonDates(bidWithContext))
        .map((bidWithContext: ApiBidWithContext) => toInternalBid(bidWithContext)),
    )
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null // Not found
      }
      throw error
    })
  if (!response || response.length === 0) {
    return null
  }

  return response
}

export const getCapacityInsights = async (
  activationGroupUuid: string,
  startDate: MarketDate,
  endDate: MarketDate,
): Promise<HistoricalCapacity[] | null> => {
  const response = await axios
    .get(`${PORTFOLIO_MANAGER_API_URL}/insights/capacity`, {
      params: {
        activationGroupUuid: activationGroupUuid,
        startDate: startDate.toISODate(),
        endDate: endDate.toISODate(),
      },
    })
    .then((response) =>
      response.data.map((historicalCapacity: any) => toHistoricalCapacityWithLuxonDateTime(historicalCapacity)),
    )
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null // Not found
      }
      throw error
    })
  if (!response || response.length === 0) {
    return null
  }

  return response
}

const toHistoricalCapacityWithLuxonDateTime = (data: any) => {
  return {
    ...data,
    startDate: toLuxonDateTime(data.startDate, MARKET_TIMEZONE),
  } as HistoricalCapacity
}

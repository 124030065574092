import Favicon from '@/assets/favicon-sympower.png'
import ColoredLogo from '@/assets/logo-sympower-blue.png'
import MonochromeLogo from '@/assets/logo-sympower-white.svg'
import type { WhitelabelConfiguration } from '@/features/whitelabel/types/whitelabelConfiguration'

export const DEFAULT_WHITELABEL_CONFIG: WhitelabelConfiguration = {
  partnerCode: 'SYMPOWER',
  palette: {
    primary: '#1484A0',
    secondary: '#204A64',
    background: '#F5F5F5',
    text: '#000000',
    success: '#2E7D32',
    warning: '#F57C00',
    error: '#D32F2F',
    sidebar: {
      background: '#163346',
      text: '#F5F5F5',
    },
  },
  typography: {
    main: 'Roboto',
    heading: 'Quicksand',
    fallback: 'sans-serif',
  },
  branding: {
    name: 'FlexPortal',
    coloredLogo: ColoredLogo as string,
    monochromeLogo: MonochromeLogo as string,
    favicon: Favicon as string,
  },
  mailto: {
    support: 'support@sympower.net',
    feedback: 'feedback@sympower.net',
  },
}

import { Route, Routes } from 'react-router-dom'

import NavigateToError from '@/features/error/pages/NavigateToError'
import { Reports } from '@/features/report/pages/Reports'

export default function ReportRoutes() {
  return (
    <Routes>
      <Route element={<Reports />} path="/" />
      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}

import { Block, DoneOutline, NumbersOutlined, SettingsSuggestOutlined } from '@mui/icons-material'
import { Box, FormControl, InputAdornment, Stack } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomButton from '@/components/inputs/CustomButton'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import FloatTextField from '@/components/inputs/FloatTextField'
import { MAXIMUM_MW_PER_PTU } from '@/features/bidding/constants'
import { BulkFillMode } from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends OfferedBidPtusDataGridToolbarProps {}
}

type OfferedBidPtusDataGridToolbarProps = {
  onFillAll: (bulkMode: BulkFillMode, value: number | undefined) => void
  saveDraftCurrentValues: () => void
  deliveryDay: MarketDate
}

const OfferedBidPtusDataGridToolbar = ({
  onFillAll,
  saveDraftCurrentValues,
  deliveryDay,
}: Readonly<OfferedBidPtusDataGridToolbarProps>) => {
  const { t, i18n } = useTranslation()

  const [bulkFillMode, setBulkFillMode] = useState(BulkFillMode.FORECAST_RATIO)
  const [bulkFillValue, setBulkFillValue] = useState<number | undefined>()

  const actions = (
    <>
      <SaveDraftCurrentValuesButton saveDraftCurrentValues={saveDraftCurrentValues} />
      <OfferNoCapacityButton onFillAll={onFillAll} />

      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        <BulkFillTypeSelect bulkFillMode={bulkFillMode} setBulkFillMode={setBulkFillMode} />
        <FormControl>
          <BulkFillInputField bulkFillMode={bulkFillMode} setBulkFillValue={setBulkFillValue} />
        </FormControl>
      </Stack>

      <CustomButton
        disabled={bulkFillValue == undefined}
        variant="contained"
        onClick={() => onFillAll(bulkFillMode, bulkFillValue)}
      >
        {t('bidding.create_bid.fill_all')}
      </CustomButton>
    </>
  )

  return (
    <GridToolbar
      actions={actions}
      filters={
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <DatePicker
            disabled
            defaultValue={deliveryDay.getStartOfDay()}
            format="DD"
            label={t('bidding.create_bid.bid_date')}
          />
        </LocalizationProvider>
      }
    />
  )
}

const SaveDraftCurrentValuesButton = ({ saveDraftCurrentValues }) => {
  const { t } = useTranslation()

  return (
    <CustomIconButton
      Icon={DoneOutline}
      aria-label={t('bidding.create_bid.save_draft_current_values_button_label')}
      data-testid="save-draft-current-values-button"
      iconProps={{ titleAccess: t('bidding.create_bid.save_draft_current_values_button_label') }}
      onClick={() => saveDraftCurrentValues()}
    />
  )
}

const OfferNoCapacityButton = ({ onFillAll }) => {
  const { t } = useTranslation()

  return (
    <CustomIconButton
      Icon={Block}
      aria-label={t('bidding.create_bid.offer_no_capacity_button_label')}
      data-testid="offer-no-capacity-button"
      iconProps={{ titleAccess: t('bidding.create_bid.offer_no_capacity_button_label') }}
      onClick={() => onFillAll(BulkFillMode.ABSOLUTE_VALUE, 0)}
    />
  )
}

const BulkFillTypeSelect = ({ bulkFillMode, setBulkFillMode }) => {
  const { t } = useTranslation()

  return (
    <CustomSelectField
      data-testid="bulk-fill-type"
      id="bulk-fill"
      label={t('bidding.create_bid.bulk_fill')}
      options={[
        {
          id: BulkFillMode.FORECAST_RATIO,
          value: BulkFillMode.FORECAST_RATIO,
          label: t('bidding.create_bid.forecasted_ratio'),
          icon: <SettingsSuggestOutlined />,
        },
        {
          id: BulkFillMode.ABSOLUTE_VALUE,
          value: BulkFillMode.ABSOLUTE_VALUE,
          label: t('bidding.create_bid.absolute_value'),
          icon: <NumbersOutlined />,
        },
      ]}
      value={bulkFillMode}
      onChange={(event) => setBulkFillMode(event.target.value)}
    />
  )
}

const BulkFillInputField = ({ bulkFillMode, setBulkFillValue }) => (
  <Box width={120}>
    <FloatTextField
      max={MAXIMUM_MW_PER_PTU}
      min={0}
      textFieldProps={{
        InputProps: {
          endAdornment: (
            <InputAdornment position="start">{bulkFillMode == BulkFillMode.FORECAST_RATIO ? '%' : 'MW'}</InputAdornment>
          ),
        },
        inputProps: { 'data-testid': 'bulk-fill-value' },
        label: 'Value',
      }}
      onValueChange={setBulkFillValue}
    />
  </Box>
)

export default OfferedBidPtusDataGridToolbar

import axios from 'axios'
import { DateTime } from 'luxon'

import environment from '@/environment'
import type { Column, FormattedRow, Response } from '@/features/bessDashboard/types'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

export type Timeseries = {
  utcTimestamp: DateTime
  value: number
}[]

enum Table {
  SOLAR_ACTUALS = 'SOLAR_ACTUALS',
  SPOT_PRICE_ACTUALS = 'SPOT_PRICE_ACTUALS',
  SOLAR_FORECASTS = 'SOLAR_FORECASTS',
  SPOT_PRICE_FORECASTS = 'SPOT_PRICE_FORECASTS',
}

export async function getSolarActual(
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Timeseries> {
  return await getBiddingInsights(Table.SOLAR_ACTUALS, priceArea, fromDeliveryDay, toDeliveryDay)
}

export async function getSpotPriceActual(
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Timeseries> {
  return await getBiddingInsights(Table.SPOT_PRICE_ACTUALS, priceArea, fromDeliveryDay, toDeliveryDay)
}

export async function getSolarForecast(
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Timeseries> {
  return await getBiddingInsights(Table.SOLAR_FORECASTS, priceArea, fromDeliveryDay, toDeliveryDay)
}

export async function getSpotPriceForecast(
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Timeseries> {
  return await getBiddingInsights(Table.SPOT_PRICE_FORECASTS, priceArea, fromDeliveryDay, toDeliveryDay)
}

/**
 *  Actuals have the following properties:
 *    utc_timestamp: timestamp
 *    price_area: string
 *    value: number
 *
 *  Forecasts have the following properties:
 *    utc_timestamp: timestamp
 *    price_area: string
 *    value: number
 *    issue_date: timestamp
 *  For each forecasted timestamp we can have multiple forecasts based on the issue_date (when the forecast was calculated).
 *  For each forecasted timestamp we need to get the latest forecast available, that is,
 *  for each utc_timestamp we need to select the row with the latest issue_date.
 */
async function getBiddingInsights(
  table: string,
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
) {
  const fromTimestamp = fromDeliveryDay.getStartOfDay().toUTC().toISO()!
  const toTimestamp = toDeliveryDay.getEndOfDay().toUTC().toISO()!

  return requestTimeseries(table, priceArea, fromTimestamp, toTimestamp)
}

async function requestTimeseries(table: string, priceArea: string, fromTimestamp: string, toTimestamp: string) {
  const response: Response | undefined = await axios.get(
    `${environment.services.biddingInsightsApiUrl}?table=${table}&priceArea=${priceArea}&fromTimestamp=${fromTimestamp}&toTimestamp=${toTimestamp}`,
  )
  const rows = toFormattedRows(response)
  return toTimeseries(rows)
}

function toTimeseries(rows: FormattedRow[]): Timeseries {
  return rows.map((row) => ({
    utcTimestamp: toUtcDateTime(row['utc_timestamp'] as string),
    value: row['value'] as number,
  }))
}

function toFormattedRows(response: any | undefined): FormattedRow[] {
  if (!response) {
    return [] // Return an empty array if response is undefined
  }

  const content = response.data

  const { columns } = content.manifest.schema
  const data_array = content.result?.data_array ?? []

  return data_array.map((row: string[]) => {
    const formattedRow: FormattedRow = {}
    columns.forEach((col: Column, index: number) => {
      formattedRow[col.name] = row[index]
    })
    return formattedRow
  })
}

function toUtcDateTime(text: string) {
  return DateTime.fromISO(text, { zone: 'UTC' })
}

import axios from 'axios'

import environment from '@/environment'
import type {
  ManualSteeringCommand,
  Resource,
  ResourceDetails,
  ResourceLifecycleStage,
  ResourceMetadata,
  ResourceMultistepStrategyType,
  ResourceSteeringDefaultType,
  ResourceType,
} from '@/features/resource/types'
import type { MarketProgramType } from '@/types/marketProgramType'

export const GET_RESOURCES_API_ID = 'GET_RESOURCES'
export const GET_RESOURCE_API_ID = 'GET_RESOURCE'
export const POLL_RESOURCES_API_ID = 'POLL_RESOURCES'
export const GET_BATTERY_INFO_API_ID = 'GET_BATTERY_INFO'

export type ResourcesResponse = { data: Resource[]; meta: ResourceMetadata }
export type PollResourcesResponse = { data: Resource[] | null }

type Filters = {
  customerId?: string
  resourceType?: ResourceType
  countryCode?: string
  marketProgram?: MarketProgramType
  lifecycleStage?: ResourceLifecycleStage
}

// Name means the name of the resource
export type SortProperty = 'resourceName' | 'customerName' | 'estimatedPower'

export type SortDirection = 'asc' | 'desc'

/**
 * The format of the source will be "property,direction"
 *
 * Property can be name or customerName or estimatedPower
 * Direction can be asc or desc
 *
 * By default, the API is sorting resources by resource name ascending
 */
export type Sort = {
  property: SortProperty
  direction: SortDirection
}

type Pagination = {
  limit?: number
  skip?: number
}

export type UpdateResourcePayload = {
  resourceName: string
  resourceType: ResourceType
}

export interface AddSteeringRangeRequest {
  max: SteeringRangeEntryRequest
  min: SteeringRangeEntryRequest
  step: SteeringRangeEntryRequest
  multistepMappings: MultistepMappingRequest[] | null
}

export interface UpdateSteeringRangeRequest {
  max: SteeringRangeEntryRequest
  min: SteeringRangeEntryRequest
  step: SteeringRangeEntryRequest
  multistepMappings: MultistepMappingRequest[] | null
}

export interface SteeringRangeEntryRequest {
  valueType: 'ABSOLUTE' | 'DYNAMIC'
  value: number | null
  minSecondsOnThisLevel: number | null
  maxSecondsOnThisLevel: number | null
}

export interface MultistepMappingRequest {
  stepValue: number | null
  controlPortId: string | null
}

export interface BatteryInfoResponse {
  controlPortId: string
  stateOfHealth: number
  stateOfCharge: number
  energyToFullCharge: number
  maxUsablePower: number
  minUsablePower: number
  energyToFullDischarge: number
  ratedEnergy: number
  sumUsableEnergy: number
}

export interface RepublishResourceRequestAndResponse {
  config: boolean
  steering: boolean
  steerabilityAndBattery: boolean
}

const BASE_RESOURCES_API_URL = `${environment.services.resourcesManagerApiUrl}/resources`

export const parseSort = (sort: Sort): string => {
  return `${sort.property},${sort.direction}`
}

/**
 * We just send the filters that content a value. If not of them have a value, we don't send any filter at all.
 */
function parseFilters(filters: Filters): string | null {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(filters)) {
    if (value) {
      params.append(key, value)
    }
  }

  const searchParams = params.toString()

  return searchParams.length > 0 ? searchParams : null
}

export async function getResources({
  filters,
  pagination,
  sort,
}: {
  filters?: Filters
  pagination?: Pagination
  sort?: Sort
}): Promise<ResourcesResponse> {
  const parsedFilters = filters ? parseFilters(filters) : undefined

  const response = await axios.get<ResourcesResponse>(`${BASE_RESOURCES_API_URL}`, {
    params: {
      ...pagination,
      sort: sort ? parseSort(sort) : undefined,
      filters: parsedFilters ?? undefined,
    },
  })

  return response.data
}

export async function updateResource(resourceId: string, payload: UpdateResourcePayload): Promise<Resource> {
  const response = await axios.patch<Resource>(`${BASE_RESOURCES_API_URL}/${resourceId}`, payload)

  return response.data
}

// This is the etag that we will send to the API to get the resources that have been updated
let etag = ''

/**
 * The reason why this function returns an object instead of a promise is because pollResourcesBuilder will manage to
 * update the etag internally when it is necessary.
 */

export async function pollResources({
  resourceIds,
  sort,
}: {
  resourceIds: string[]
  sort?: Sort
}): Promise<PollResourcesResponse | null> {
  const params = new URLSearchParams()

  resourceIds.forEach((id) => params.append('resourceIds', id))

  if (sort) {
    params.append('sort', parseSort(sort))
  }

  const response = await axios.get<PollResourcesResponse | string>(`${BASE_RESOURCES_API_URL}/poll`, {
    params,
    headers: {
      'If-None-Match': etag,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })

  if (response.status === 200 && typeof response.data !== 'string') {
    etag = response.headers.etag

    return { ...response.data }
  }

  return null
}
export async function getResource(resourceId: string): Promise<ResourceDetails> {
  const response = await axios.get(`${BASE_RESOURCES_API_URL}/${resourceId}`)
  return response.data
}

export const updateSteeringDefaults = async (
  resourceId: string,
  steeringDefaultType: ResourceSteeringDefaultType,
  defaultConsumptionLevel: number | null,
) => {
  const response = await axios.patch(`${BASE_RESOURCES_API_URL}/${resourceId}/steering-defaults`, {
    steeringDefaultType,
    defaultConsumptionLevel,
  })
  return response.data
}

export async function addSteeringRange(resourceId: string, payload: AddSteeringRangeRequest): Promise<void> {
  await axios.post(`${BASE_RESOURCES_API_URL}/${resourceId}/steering-ranges`, payload)
}

export async function updateSteeringRange(
  resourceId: string,
  rangeId: string,
  payload: UpdateSteeringRangeRequest,
): Promise<void> {
  await axios.put(`${BASE_RESOURCES_API_URL}/${resourceId}/steering-ranges/${rangeId}`, payload)
}

export async function deleteSteeringRange(resourceId: string, rangeId: string): Promise<void> {
  await axios.delete(`${BASE_RESOURCES_API_URL}/${resourceId}/steering-ranges/${rangeId}`)
}

export async function getBatteryInfo(controlPortId: string): Promise<BatteryInfoResponse> {
  const response = await axios.get<BatteryInfoResponse>(
    `${BASE_RESOURCES_API_URL}/batteries/${controlPortId}/battery-info`,
  )
  return response.data
}

export async function updateBatterySoC(resourceId: string, desiredSoC: number): Promise<void> {
  await axios.patch(`${BASE_RESOURCES_API_URL}/${resourceId}/battery-soc`, { desiredSoC })
}

export async function steerResource(resourceId: string, manualSteeringCommand: ManualSteeringCommand): Promise<void> {
  await axios.patch(`${BASE_RESOURCES_API_URL}/${resourceId}/manual-steering`, manualSteeringCommand)
}

export const updateSteeringCapabilities = async (
  resourceId: string,
  isReleaseControlSupported: boolean,
  minReleaseControlTimeInSeconds: number | null,
) => {
  const response = await axios.patch(`${BASE_RESOURCES_API_URL}/${resourceId}/steering-capabilities`, {
    isReleaseControlSupported,
    minReleaseControlTimeInSeconds,
  })
  return response.data
}

export async function decommissionResource(resourceId: string): Promise<void> {
  await axios.post(`${BASE_RESOURCES_API_URL}/${resourceId}/decommission`)
}

export async function republishResource(
  resourceId: string,
  request: RepublishResourceRequestAndResponse,
): Promise<RepublishResourceRequestAndResponse> {
  const response = await axios.post<RepublishResourceRequestAndResponse>(
    `${BASE_RESOURCES_API_URL}/${resourceId}/republish`,
    request,
  )
  return response.data
}

export async function resyncResource(resourceId: string): Promise<void> {
  await axios.post(`${BASE_RESOURCES_API_URL}/${resourceId}/resync`)
}

export const updateSteeringMultistep = async (
  resourceId: string,
  isMultistepResource: boolean,
  multistepStrategy: ResourceMultistepStrategyType | null,
): Promise<any> => {
  const response = await axios.patch(`${BASE_RESOURCES_API_URL}/${resourceId}/multistep-settings`, {
    isMultistepResource,
    multistepStrategy,
  })
  return response.data
}

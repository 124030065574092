import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomButton from '@/components/inputs/CustomButton'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

type ErrorPageLayoutProps = {
  statusCode: number
  title: string
  description?: string
  solutions: string[]
  image: {
    alt: string
    src: string
  }
}

/**
 * We are using 'a' elements instead of Link components because this component could be rendered when there is a JS error, so
 * we want to avoid using any Javascript to navigate
 */
const ErrorPageLayout = ({ statusCode, title, description, solutions, image }: ErrorPageLayoutProps) => {
  const { t } = useTranslation()
  const { branding } = useWhitelabelConfigurationContext()

  return (
    <Stack
      sx={{
        backgroundColor: 'linear-gradient(0deg, rgba(20, 132, 160, 0.04) 0%, rgba(20, 132, 160, 0.04) 100%), #FFF',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
      }}
    >
      <Stack component="header" paddingTop={2} paddingX={2}>
        <a href="/">
          <img alt={branding.name + ' Logo'} height="50px" src={branding.coloredLogo} width="auto" />
        </a>
      </Stack>

      <Stack
        sx={{
          flexDirection: 'row',
          flex: 1,
          marginX: 'auto',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 5,
            paddingRight: 7,
          }}
        >
          <Stack sx={{ flexDirection: 'column', gap: 3 }}>
            <CustomTypography
              component="h1"
              sx={{
                color: 'grey.300',
                fontSize: '12.5rem',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '200px',
                letterSpacing: '0.46px',
              }}
            >
              {statusCode}
            </CustomTypography>

            <Stack sx={{ flexDirection: 'column', gap: 1 }}>
              <CustomTypography
                component="h2"
                sx={{
                  color: 'text.primary',
                  fontFamily: 'Quicksand',
                  fontSize: '2.25rem',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '150%',
                  letterSpacing: '-0.8px',
                }}
              >
                {title}
              </CustomTypography>

              {Boolean(description) && (
                <CustomTypography
                  component="h3"
                  sx={{
                    color: 'text.primary',
                    fontFamily: 'Quicksand',
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '150%',
                    letterSpacing: '-0.8px',
                  }}
                >
                  {description}
                </CustomTypography>
              )}
            </Stack>

            <Box
              sx={{
                a: {
                  color: 'primary.A400',
                },
              }}
            >
              <CustomTypography
                sx={{
                  paddingBottom: 1,
                  color: 'text.primary',
                  fontFamily: 'Quicksand',
                  fontSize: '1.25rem',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '200%',
                  letterSpacing: '0.15px',
                }}
              >
                {t('component.error_page.solutions_title')}
              </CustomTypography>

              <Box component="ul" sx={{ margin: 0, padding: 0, paddingLeft: 2.3 }}>
                {solutions.map((solution) => (
                  <Box key={solution} component="li" sx={{ margin: 0, padding: 0 }}>
                    <CustomTypography
                      dangerouslySetInnerHTML={{ __html: solution }}
                      sx={{
                        color: 'text.primary',
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '200%',
                        letterSpacing: '0.15px',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Stack>

          <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
            <a href="/">
              <CustomButton color="primary" endIcon={<KeyboardArrowRightOutlinedIcon />} variant="contained">
                {t('component.error_page.back_to_portal')}
              </CustomButton>
            </a>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ height: '700px', width: '700px' }}>
            <img alt={image.alt} height="100%" src={image.src} width="100%" />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ErrorPageLayout

import { useEffect } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import SpotOnEboilerConfigurationForm from '@/features/resource/components/SpotOnEboilerConfigurationForm'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceSpotOnEboilerDetails } from '@/features/resource/pages/ResourceSpotOnEboilerDetailsPage'

function SpotOnEboilerConfigurationPage() {
  const { resource, scheduledResource, setActivePage } = useResourceSpotOnEboilerDetails()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_CONFIGURATION.routePath)
  }, [])

  return (
    <MainContentContainer sx={{ marginBottom: 3 }}>
      <CustomTypography gutterBottom variant="h3">
        {resource?.resourceName}
      </CustomTypography>
      <SpotOnEboilerConfigurationForm resource={resource} scheduledResource={scheduledResource} />
    </MainContentContainer>
  )
}

export default SpotOnEboilerConfigurationPage

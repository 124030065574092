import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Dialog, DialogActions, DialogContent, Link } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import BottomCircle from '@/components/icons/BottomCircle'
import TopCircle from '@/components/icons/TopCircle'
import CustomButton from '@/components/inputs/CustomButton'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

const WELCOME_DIALOG_SHOWN = 'welcomeDialogShown'

const WelcomeDialog = () => {
  const { loggedInUser } = useAuth()
  const { t } = useTranslation()
  const { branding, palette, typography, mailto } = useWhitelabelConfigurationContext()

  function getKeyWithUserId() {
    return WELCOME_DIALOG_SHOWN + loggedInUser?.id
  }

  const [showWelcomeDialog, setShowWelcomeDialog] = useState(localStorage.getItem(getKeyWithUserId()) !== 'true')

  function handleClose() {
    return () => {
      setShowWelcomeDialog(false)
      localStorage.setItem(getKeyWithUserId(), 'true')
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" open={showWelcomeDialog} role="alertdialog" onClose={handleClose()}>
      <Box>
        <TopCircle
          color={palette.primary}
          style={{
            position: 'absolute',
            top: 0,
            height: '100px',
            width: '258px',
            right: '44px',
          }}
        />
        <BottomCircle
          color={palette.secondary ?? palette.primary}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
          }}
        />
      </Box>
      <DialogContent
        sx={{
          m: 4,
          zIndex: 1,
        }}
      >
        <img alt={`Logo ${branding.name}`} src={branding.coloredLogo} style={{ margin: '0 auto', width: '300px' }} />
        <CustomTypography
          color={'primary'}
          fontFamily={`${typography.heading}, ${typography.main}, ${typography.fallback}`}
          fontSize={36}
          fontWeight={'bold'}
          letterSpacing={-0.8}
          sx={{ mt: 4 }}
        >
          {t('component.welcome_dialog.title', { applicationName: branding.name })}
        </CustomTypography>
        <CustomTypography fontSize={16} letterSpacing={0.15} lineHeight={'32px'} sx={{ mt: 4 }}>
          {t('component.welcome_dialog.thank_you_intro')}
        </CustomTypography>

        <CustomTypography fontSize={16} letterSpacing={0.15} lineHeight={'32px'} sx={{ mt: 4 }}>
          {t('component.welcome_dialog.send_feedback')}
          <Link color="primary" href={`mailto:${mailto?.feedback}`}>
            {mailto?.feedback}
          </Link>
          .
        </CustomTypography>

        <CustomTypography
          color={'primary'}
          fontFamily={'Quicksand, Roboto, sans-serif'}
          fontSize={20}
          fontWeight={600}
          letterSpacing={0.15}
          sx={{ mt: 4 }}
        >
          {t('component.welcome_dialog.together')}
        </CustomTypography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end' }} sx={{ m: 4 }}>
        <CustomButton endIcon={<KeyboardArrowRightIcon />} variant="contained" onClick={handleClose()}>
          {t('component.welcome_dialog.button')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default WelcomeDialog

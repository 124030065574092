import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import type { GridColDef, GridRowParams, GridSortModel } from '@mui/x-data-grid'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_FILTER_MODEL, DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { COLUMN_WITH_MEDIUM_ICON_WIDTH } from '@/constants/layout'
import ActivationGroupsDataGridToolbar from '@/features/activationGroup/components/ActivationGroupsDataGridToolbar'
import ActivationGroupStateChip from '@/features/activationGroup/components/ActivationGroupStateChip'
import CreateActivationGroupDialog from '@/features/activationGroup/components/CreateActivationGroupDialog'
import UpdateActivationGroupDialog from '@/features/activationGroup/components/UpdateActivationGroupDialog'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import type { ActivationGroup } from '@/features/activationGroup/types'
import { ApiVolumeRangeUnit } from '@/features/activationGroup/types'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

const DEFAULT_SORT_MODEL: GridSortModel = [{ field: 'marketProgram', sort: 'desc' }]
type ActivationGroupRenderCell = GridRenderCellParams<ActivationGroup>

const ActivationGroupsDataGrid = () => {
  const { t } = useTranslation()
  const { activationGroups, isLoading } = useActivationGroupsQuery()
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [activationGroupBeingEdited, setActivationGroupBeingEdited] = useState<ActivationGroup | null>(null)

  const columns: GridColDef<ActivationGroup>[] = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 2,
      sortable: true,
    },
    {
      field: 'code',
      headerName: t('activation_groups.table.header.name'),
      flex: 2,
      sortable: true,
    },
    {
      field: 'state',
      headerName: t('activation_groups.table.header.state'),
      flex: 1,
      renderCell: ({ row: { state } }: ActivationGroupRenderCell) => (
        <ActivationGroupStateChip state={state ?? 'UNKNOWN'} />
      ),
    },
    {
      field: 'portfolio.code',
      headerName: t('activation_groups.table.header.portfolio_code'),
      flex: 1,

      valueGetter: (_, activationGroup: ActivationGroup) => activationGroup.portfolio.code,
    },
    {
      field: 'marketProgram',
      headerName: t('common.market_program.label'),
      flex: 1,
      sortable: true,
      valueGetter: (value: ActivationGroup['marketProgram']) => t(`bidding.market_program.${value}`),
    },
    {
      field: 'biddableVolumeRange.min',
      headerName: t('activation_groups.table.header.min_capacity'),
      flex: 1,
      valueGetter: (_, { biddableVolumeRange }: ActivationGroup) => biddableVolumeRange,
      valueFormatter: (value: ActivationGroup['biddableVolumeRange']) => {
        if (!value) return '-'

        return convertEnergyPower(value.min, value.unit === ApiVolumeRangeUnit.WATTS ? 'kilowatts' : 'kilowatt_hours')
      },
    },
    {
      field: 'biddableVolumeRange.max',
      headerName: t('activation_groups.table.header.max_capacity'),
      flex: 1,
      valueGetter: (_, { biddableVolumeRange }: ActivationGroup) => biddableVolumeRange,
      valueFormatter: (value: ActivationGroup['biddableVolumeRange']) => {
        if (!value) return '-'

        return convertEnergyPower(value.max, value.unit === ApiVolumeRangeUnit.WATTS ? 'kilowatts' : 'kilowatt_hours')
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      width: COLUMN_WITH_MEDIUM_ICON_WIDTH,
      renderCell: (params: GridRenderCellParams<ActivationGroup>) => (
        // We cannot use a <Link> here, as we have already a <Link> wrapping every row, which would raise a warning, as:
        // <Link><Link></Link></Link> is not allowed. Then, we need to preventDefault and use the onClick handler.
        // The only caveat is that we're not allowed to open in a new tab using the <IconButton>.
        <IconButton
          sx={{ display: 'inline-flex' }}
          onClick={(event) => {
            event.preventDefault()
            setActivationGroupBeingEdited(params.row)
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ]

  function handleOpenCreateDialog() {
    setOpenCreateDialog(true)
  }

  function handleCloseCreateDialog() {
    setOpenCreateDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        aria-label={t('activation_groups.table.title')}
        clickableRows={{
          navigateTo: ({ row }: GridRowParams<ActivationGroup>) =>
            ActivationGroupRouteInformation.ACTIVATION_GROUP_DETAILS.navigationPath(row.uuid),
        }}
        columns={columns}
        getRowId={(row) => row.uuid}
        initialState={{
          filter: {
            filterModel: DEFAULT_DATA_GRID_FILTER_MODEL,
          },
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          sorting: {
            sortModel: DEFAULT_SORT_MODEL,
          },
        }}
        isLoading={isLoading}
        rows={activationGroups ?? []}
        slotProps={{ toolbar: { onAddNewActivationGroup: handleOpenCreateDialog, activationGroups } }}
        slots={{ toolbar: ActivationGroupsDataGridToolbar }}
      />

      <CreateActivationGroupDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
      {activationGroupBeingEdited && (
        <UpdateActivationGroupDialog
          activationGroup={activationGroupBeingEdited}
          onClose={() => setActivationGroupBeingEdited(null)}
        />
      )}
    </>
  )
}

export default ActivationGroupsDataGrid

import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RevenueContent } from '@/features/customer/components/revenue/RevenueContent'
import { RevenueMarketProgramTabs } from '@/features/customer/components/revenue/RevenueMarketProgramTabs'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import { useRevenuePeriodQuery } from '@/features/customer/hooks/useRevenuePeriodQuery'
import { useRevenueTimeSeriesQuery } from '@/features/customer/hooks/useRevenueTimeSeriesQuery'
import CustomerRevenueAppBar from '@/features/customer/pages/components/CustomerRevenueAppBar'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { RevenueDataRow, RevenuePeriod } from '@/features/customer/types/revenue'
import { getYearsBetweenDates } from '@/features/customer/utils/revenueUtils/dateUtils'
import { formatRevenueTimeSeries } from '@/features/customer/utils/revenueUtils/formatRevenueTimeSeries'
import type { MarketProgramType } from '@/types/marketProgramType'

export const CustomerRevenue = () => {
  const { customer, customerDetailsCommonBreadcrumbs, setPageConfig, isFetchingCustomer } = useCustomerDetails()
  const { t } = useTranslation()

  const { customerSettings } = useCustomerSettingsQuery(
    { uuid: customer.uuid! },
    { enabled: Boolean(customer.uuid) && !isFetchingCustomer },
  )
  const timeZone = customerSettings?.localization.timeZone ?? DateTime.utc().zoneName

  const { revenuePeriods, isFetching: isFetchingRevenuePeriod } = useRevenuePeriodQuery(
    { customerUuid: customer.uuid! },
    { enabled: Boolean(customer.uuid) && !isFetchingCustomer },
  )

  const relevantMarketPrograms = getMarketProgramsOptions()
  const [marketProgramSelection, setMarketProgramSelection] = useState<MarketProgram>()

  const [yearOptions, setYearOptions] = useState<string[]>([])
  const [yearSelection, setYearSelection] = useState<string>('')

  useEffect(() => {
    if (!marketProgramSelection) {
      setMarketProgramSelection(relevantMarketPrograms.length > 0 ? relevantMarketPrograms[0] : undefined)
    }
  }, [revenuePeriods])

  useEffect(() => {
    const availableYears = getAvailableYearsForMarketProgram(revenuePeriods ?? [], marketProgramSelection)
    setYearOptions(availableYears)
    if (availableYears.length > 0) {
      setYearSelection(
        yearSelection === '' || !availableYears.includes(yearSelection) ? availableYears[0] : yearSelection,
      )
    }
  }, [revenuePeriods, marketProgramSelection])

  const revenueTimeSeriesStartTime = getRevenueTimeSeriesStartTime()
  const revenueTimeSeriesEndTime = getRevenueTimeSeriesEndTime()

  function getRevenueTimeSeriesStartTime() {
    if (revenuePeriods && timeZone && marketProgramSelection && yearSelection) {
      const firstDayOfYearForCustomer = DateTime.fromObject(
        { year: parseInt(yearSelection), month: 1, day: 1 },
        { zone: timeZone },
      )
      const firstDayOfYearForCustomerUtc = firstDayOfYearForCustomer.setZone(DateTime.utc().zone)
      const calculatedSince = DateTime.fromISO(
        revenuePeriods.find((period) => period.serviceId === marketProgramSelection.id)!.calculatedSince,
      )
      return calculatedSince > firstDayOfYearForCustomerUtc ? calculatedSince : firstDayOfYearForCustomerUtc
    }
  }

  function getRevenueTimeSeriesEndTime() {
    if (revenuePeriods && timeZone && marketProgramSelection && yearSelection) {
      const firstDayOfNextYearForCustomer = DateTime.fromObject(
        { year: parseInt(yearSelection) + 1, month: 1, day: 1 },
        { zone: timeZone },
      )
      const firstDayOfNextYearForCustomerUtc = firstDayOfNextYearForCustomer.setZone(DateTime.utc().zone)
      const calculatedUntil = DateTime.fromISO(
        revenuePeriods.find((period) => period.serviceId === marketProgramSelection.id)!.calculatedUntil,
      )
      const calculatedUntilExclusive = calculatedUntil.plus({ hour: 1 })
      return calculatedUntilExclusive < firstDayOfNextYearForCustomerUtc
        ? calculatedUntilExclusive
        : firstDayOfNextYearForCustomerUtc
    }
  }

  const { revenueTimeSeries, isFetching: isLoadingRevenueTimeSeries } = useRevenueTimeSeriesQuery(
    {
      customerUuid: customer.uuid!,
      serviceId: marketProgramSelection?.id ?? 0,
      startTime: revenueTimeSeriesStartTime ?? DateTime.local({ zone: timeZone }),
      endTime: revenueTimeSeriesEndTime ?? DateTime.local({ zone: timeZone }),
    },
    {
      enabled:
        !isFetchingCustomer &&
        !isFetchingRevenuePeriod &&
        Boolean(revenueTimeSeriesStartTime) &&
        Boolean(revenueTimeSeriesEndTime),
    },
  )

  const revenueData: RevenueDataRow[] = useMemo(() => {
    return formatRevenueTimeSeries(revenueTimeSeries, timeZone)
  }, [revenueTimeSeries, timeZone])

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.revenue.title'),
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      appBarContent: (
        <CustomerRevenueAppBar
          availableYears={yearOptions}
          customerName={customer.name ?? ''}
          revenueData={revenueData}
          selectedMarketProgramName={marketProgramSelection?.name ?? ''}
          setYearSelection={setYearSelection}
          yearSelection={yearSelection}
        />
      ),
      activeTab: CustomerDetailsRouteInformation.REVENUE.navigationPath,
    })
  }, [yearOptions, revenueData, customer.name, marketProgramSelection?.name, yearSelection])

  if (!customer) return null
  if (!isFetchingRevenuePeriod && (!revenuePeriods || revenuePeriods.length === 0))
    return (
      <Typography align={'center'} sx={{ m: 4 }}>
        {t('customer_revenue.no_revenue_data')}
      </Typography>
    )

  function getAvailableYearsForMarketProgram(revenuePeriods: RevenuePeriod[], marketProgram?: MarketProgram): string[] {
    const years: string[] = []
    revenuePeriods
      .filter((period) => period.serviceId === marketProgram?.id)
      .forEach((period) => {
        years.push(...getYearsBetweenDates(period.calculatedSince, period.calculatedUntil, timeZone).map(String))
      })
    return years.sort((a, b) => parseInt(b) - parseInt(a))
  }

  const handleMarketProgramChange = (newValue: MarketProgramType) => {
    const newMarketProgram = relevantMarketPrograms.find((program) => program.type === newValue)!
    setMarketProgramSelection(newMarketProgram)
    const availableYears = getAvailableYearsForMarketProgram(revenuePeriods ?? [], newMarketProgram)
    setYearOptions(availableYears)
    setYearSelection(availableYears[0])
  }

  function getMarketProgramsOptions() {
    return (
      customer?.services?.filter((service) => revenuePeriods?.find((period) => period.serviceId === service.id)) ?? []
    )
  }

  return (
    <Stack direction="column" mb={2} mt={2} spacing={3} sx={{ width: '100%' }}>
      <RevenueMarketProgramTabs
        handleMarketProgramChange={handleMarketProgramChange}
        marketProgramSelection={marketProgramSelection}
        marketProgramsOptions={relevantMarketPrograms}
      />
      <RevenueContent
        isLoadingRevenueTimeSeries={isLoadingRevenueTimeSeries}
        revenueData={revenueData}
        selectedYear={yearSelection}
      />
    </Stack>
  )
}

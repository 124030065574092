import { alpha, Badge, Chip, Stack, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import ResourceIcon from '@/features/resource/components/ResourceIcon'
import type { ResourceType } from '@/features/resource/types'
import { getResourceTypeTranslation } from '@/features/resource/utils/resourceTypes'

type ResourceTypeChipProps = {
  resourceType: ResourceType
  size?: 'small' | 'medium'
  label?: string
  count?: number
  alert?: boolean
}

function ResourceTypeChip({
  resourceType,
  label,
  count,
  size = 'medium',
  alert = false,
}: Readonly<ResourceTypeChipProps>) {
  const { t } = useTranslation()
  const theme = useTheme()

  const showChipContent = Boolean(label ?? count !== undefined)
  const counterSize = size === 'small' ? '18px' : '24px'
  const wrapperHeight = size === 'small' ? '30px' : '36px'

  return (
    <Tooltip placement="top" title={getResourceTypeTranslation(resourceType, t)}>
      <Badge badgeContent="alert" color="error" invisible={!alert} variant="dot">
        <Chip
          icon={<ResourceIcon resourceType={resourceType} size={size} />}
          label={
            showChipContent && (
              <Stack alignItems="center" flexDirection="row" gap={0.75}>
                {label && (
                  <CustomTypography sx={{ fontSize: '0.81rem' }} variant="body2">
                    {label}
                  </CustomTypography>
                )}

                {count && (
                  <Stack
                    sx={{
                      width: counterSize,
                      height: counterSize,
                      backgroundColor: '#638093',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '3px',
                    }}
                  >
                    <CustomTypography sx={{ color: 'white' }}>{count}</CustomTypography>
                  </Stack>
                )}
              </Stack>
            )
          }
          size={size}
          sx={{
            borderRadius: '5px',
            height: wrapperHeight,
            '&.MuiChip-root': {
              paddingX: theme.spacing(0.75),
              paddingY: theme.spacing(0.375),
              backgroundColor: alpha(theme.palette.secondary.light, 0.08),
            },
            '& .MuiChip-label': {
              paddingX: theme.spacing(0.75),
              display: !showChipContent ? 'none' : 'inherit',
            },
          }}
          variant="filled"
        />
      </Badge>
    </Tooltip>
  )
}

export default ResourceTypeChip

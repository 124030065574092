import type { MarketProgram } from '@/features/bidding/constants'
import { Status } from '@/features/bidding/constants'
import { findBid, findForecast } from '@/features/bidding/endpoints/bids'
import type { BidPtu, BidVolumes, ForecastPtu, OfferedAndForecastPtu } from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

export const CALCULATE_BASIS_FOR_NEW_BID_API_ID = 'CALCULATE_BASIS_FOR_NEW_BID'

const DEFAULT_CAPACITY_WATTS = 1000000

export const calculateBasisForNewBid = async (
  date: MarketDate,
  portfolioCode: string,
  marketProgram: MarketProgram,
  activationGroupUuid: string,
): Promise<BidVolumes> => {
  const foundBid = await findBid(date, portfolioCode, marketProgram, activationGroupUuid)
  const foundForecast = await findForecast(date, activationGroupUuid)

  if (foundBid && foundForecast) {
    return {
      version: calculateOfferedBidVersion(foundBid.version, foundBid.status),
      offeredBid: foundBid.offeredBid,
      forecast: foundForecast.forecastPtus,
      acceptedBid: foundBid.acceptedBid,
    }
  }

  if (foundBid) {
    return {
      version: calculateOfferedBidVersion(foundBid.version, foundBid.status),
      offeredBid: foundBid.offeredBid,
      acceptedBid: foundBid.acceptedBid,
    }
  }

  if (foundForecast) {
    const forecastPtus = foundForecast.forecastPtus
    const offeredBidPtus = buildDefaultOfferedBid(date).map((offerPtu: BidPtu) => {
      const forecastPtu = forecastPtus.find((forecastPtu) => forecastPtu.ptu.start.equals(offerPtu.ptu.start))
      return {
        ptu: offerPtu.ptu,
        volume: { quantity: forecastPtu?.volume.quantity ?? offerPtu.volume.quantity, unit: 'WATTS' },
        ptuChunks: [],
      }
    })

    return {
      version: 1,
      offeredBid: offeredBidPtus,
      forecast: forecastPtus,
    }
  }

  return {
    version: 1,
    offeredBid: buildDefaultOfferedBid(date),
  }
}

const calculateOfferedBidVersion = (latestVersion?: number, latestStatus?: Status) => {
  if (!latestVersion) return 1
  if (latestStatus === Status.DRAFT) return latestVersion
  return latestVersion + 1
}

const buildDefaultOfferedBid = (date: MarketDate): BidPtu[] => {
  const startOfDay = date.getStartOfDay()
  const startOfNextDay = startOfDay.plus({ day: 1 })
  const numberOfPtus = startOfNextDay.diff(startOfDay, 'hours').hours

  return Array.from({ length: numberOfPtus }, (_, index) => {
    const start = startOfDay.plus({ hours: index })
    const end = startOfDay.plus({ hours: index + 1 })
    return {
      ptu: { start, end },
      volume: { quantity: DEFAULT_CAPACITY_WATTS, unit: 'WATTS' },
    }
  })
}

export const combineOfferedAndForecast = (offer: BidPtu[], forecast?: ForecastPtu[]): OfferedAndForecastPtu[] =>
  offer.map((offeredPtu) => ({
    ptu: offeredPtu.ptu,
    offeredVolume: offeredPtu.volume,
    offeredPtuChunks: offeredPtu.ptuChunks,
    forecastedVolume: forecast?.find((forecastPtu) => forecastPtu.ptu.start.equals(offeredPtu.ptu.start))?.volume,
  }))

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import SpotOnSchedulesGrid from '@/features/resource/components/SpotOnSchedulesGrid'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceSpotOnEboilerDetails } from '@/features/resource/pages/ResourceSpotOnEboilerDetailsPage'

function SpotOnEboilerSchedulesPage() {
  const { resourceUuid } = useParams()
  const { t } = useTranslation()

  const { resource, scheduledResource, setActivePage } = useResourceSpotOnEboilerDetails()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_SCHEDULES.routePath)
  }, [])

  return (
    <MainContentContainer sx={{ marginBottom: 3 }}>
      <Stack flexDirection="row" justifyContent="space-between">
        <CustomTypography variant="h3">{resource?.resourceName}</CustomTypography>

        <CustomIconButton
          Icon={AddOutlinedIcon}
          aria-label={t('component.page_header.spot_on_for_eboilers.schedule_create')}
          color="primary"
          component={Link}
          to={`/resources/${resourceUuid}/spot-on/schedules/create`}
          variant="solid"
        />
      </Stack>
      <SpotOnSchedulesGrid scheduledResource={scheduledResource} />
    </MainContentContainer>
  )
}

export default SpotOnEboilerSchedulesPage

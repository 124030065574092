import axios from 'axios'

import environment from '@/environment'

export const GET_INSIGHT_PORTFOLIOS_API = 'GET_INSIGHT_PORTFOLIOS'

export async function getInsightPortfolios(): Promise<string[]> {
  const portfoliosApiUrl = `${environment.services.insightsManagerApiUrl}/portfolios`
  const response = await axios.get<string[]>(portfoliosApiUrl)
  return response.data
}

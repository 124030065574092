import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import ForecastXyBaselineChart from '@/features/report/components/ForecastXyBaselineChart'
import LiveXyBaselineChart from '@/features/report/components/LiveXyBaselineChart'
import { NoPortfolio } from '@/features/report/components/NoPortfolio'
import ReportsAppBar from '@/features/report/components/ReportsAppBar'
import RetrospectXyBaselineChart from '@/features/report/components/RetrospectXyBaselineChart'
import { useInsightPortfoliosQuery } from '@/features/report/hooks/useInsightPortfoliosQuery'

const INITIAL_PORTFOLIOS = ['GR-OPTIMUS-XY']
const TIMEZONE_EUROPE_ATHENS = 'Europe/Athens'

export const Reports = () => {
  const { t } = useTranslation()

  const { isEnabled } = useFeatureToggle()

  const { portfolios } = useInsightPortfoliosQuery({
    enabled: isEnabled('FLEXPORTAL_USE_PORTFOLIOS_FROM_ACTIVATIONS_BFF'),
  })

  const getInitialPortfolioSelection = () => {
    if (isEnabled('FLEXPORTAL_USE_PORTFOLIOS_FROM_ACTIVATIONS_BFF')) {
      return portfolios && portfolios.length > 0 ? portfolios![0] : undefined
    }
    return INITIAL_PORTFOLIOS[0]
  }

  const [selectedPortfolio, setSelectedPortfolio] = useState<string | undefined>(getInitialPortfolioSelection())

  useEffect(() => {
    setSelectedPortfolio(getInitialPortfolioSelection())
  }, [portfolios])

  return (
    <>
      <PageHeader
        appBarContent={
          <>
            {selectedPortfolio && (
              <ReportsAppBar
                portfolioOptions={portfolios ?? INITIAL_PORTFOLIOS}
                selectedPortfolio={selectedPortfolio}
                setSelectedPortfolio={setSelectedPortfolio}
              />
            )}{' '}
          </>
        }
        breadcrumbItems={[]}
        pageTag={'reports'}
        pageTitle={t('component.page_header.reports')}
      />
      {selectedPortfolio ? (
        <Stack direction="column" marginY={3} spacing={3}>
          <ForecastXyBaselineChart portfolio={selectedPortfolio} timezone={TIMEZONE_EUROPE_ATHENS} />
          <LiveXyBaselineChart portfolio={selectedPortfolio} timezone={TIMEZONE_EUROPE_ATHENS} />
          <RetrospectXyBaselineChart portfolio={selectedPortfolio} timezone={TIMEZONE_EUROPE_ATHENS} />
        </Stack>
      ) : (
        <NoPortfolio />
      )}
    </>
  )
}

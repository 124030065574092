import type { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

type GaugeBackgroundProps = {
  style?: CSSProperties
}

const GaugeBackground = ({ style }: GaugeBackgroundProps) => {
  const { t } = useTranslation()
  return (
    <svg fill="none" height="377" style={style} viewBox="0 0 578 377" width="578" xmlns="http://www.w3.org/2000/svg">
      <title>{t('customer_measurements.power_measurements.gouge_background_title')}</title>
      <path
        d="M26.1656 372.5C17.6165 345.949 13 317.641 13 288.258C13 136.237 136.569 13 289 13C441.431 13 565 136.237 565 288.258C565 317.641 560.383 345.949 551.834 372.5"
        stroke="url(#paint0_linear_551_90306)"
        strokeDasharray="10 10"
        strokeWidth="25"
      />
      <path
        d="M289.5 67C167.169 67 68 165.883 68 287.861C68 318.036 74.0691 346.799 85.0565 373H493.943C504.931 346.799 511 318.036 511 287.861C511 165.883 411.831 67 289.5 67Z"
        fill="#EEEEEE"
      />
      <path
        d="M257.68 122.387L257.711 111.612C257.713 111.001 256.903 110.799 256.623 111.34L248.101 127.778C247.9 128.166 248.177 128.631 248.61 128.632L254.406 128.649L254.402 128.659L254.371 139.433C254.37 140.045 255.179 140.247 255.46 139.706L263.982 123.268C264.183 122.88 263.905 122.414 263.472 122.413L257.677 122.397"
        fill="#FFD54F"
      />
      <path
        d="M323.68 137.387L323.711 126.612C323.713 126.001 322.903 125.799 322.623 126.34L314.101 142.778C313.9 143.166 314.177 143.631 314.61 143.632L320.406 143.649L320.402 143.659L320.371 154.433C320.37 155.045 321.179 155.247 321.46 154.706L329.982 138.268C330.183 137.88 329.905 137.414 329.472 137.413L323.677 137.397"
        fill="#FFD54F"
      />
      <path
        d="M291.605 143.976L291.654 126.966C291.657 126.002 290.378 125.682 289.935 126.536L276.482 152.486C276.165 153.099 276.603 153.833 277.287 153.835L286.435 153.862L286.43 153.878L286.381 170.886C286.378 171.851 287.657 172.171 288.1 171.317L301.553 145.366C301.87 144.754 301.432 144.019 300.748 144.017L291.6 143.991"
        fill="#FFD54F"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_551_90306"
          x1="303"
          x2="63.1789"
          y1="225.5"
          y2="435.627"
        >
          <stop stopColor="#757575" />
          <stop offset="1" stopColor="#DBDBDB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GaugeBackground

import axios from 'axios'

import environment from '@/environment'
import type { BffActivatedResource } from '@/features/activation/types/activatedResource'
import type { BffActivation } from '@/features/activation/types/activation'

const ACTIVATIONS_BFF_API_URL = `${environment.services.activationsBffApiUrl}`

export const GET_BFF_ACTIVATIONS_API_ID = 'GET_BFF_ACTIVATIONS'
export const GET_BFF_ACTIVATION_API_ID = 'GET_BFF_ACTIVATION'
export const GET_BFF_ACTIVATED_RESOURCES_API_ID = 'GET_BFF_ACTIVATED_RESOURCES'

export async function getActivations(siteId: string | undefined): Promise<BffActivation[]> {
  const response = await axios.get(`${ACTIVATIONS_BFF_API_URL}`, {
    params: {
      siteId,
    },
  })
  const activations = response.data

  if (!activations || activations.length === 0) {
    return []
  }

  return activations
}

export async function getActivation(activationId: string): Promise<BffActivation> {
  const response = await axios.get(`${ACTIVATIONS_BFF_API_URL}/${activationId}`, {})
  return response.data
}

export async function getActivatedResources(siteId?: string, activationId?: string): Promise<BffActivatedResource[]> {
  const response = await axios.get<BffActivatedResource[]>(`${ACTIVATIONS_BFF_API_URL}/${activationId}/resources`, {
    params: {
      siteId,
    },
  })
  const activatedResources = response.data

  if (!activatedResources || activatedResources.length === 0) {
    return []
  }

  return activatedResources.map((resource, index) => ({
    id: index,
    ...resource,
  }))
}

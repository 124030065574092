import { useIsFetchingBidHistories } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import { useIsCalculatingBidBasis } from '@/features/bidding/hooks/useCalculateBidBasisQuery'
import { useIsFetchingGroupedBids } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import { useIsFetchingBidQuery } from '@/features/bidding/hooks/useIsFetchingBidQuery'

export function useIsFetchingAnyBid(): boolean {
  const isFetchingBid = useIsFetchingBidQuery()
  const isCalculatingBidBasis = useIsCalculatingBidBasis()
  const isFetchingGroupedBids = useIsFetchingGroupedBids()
  const isFetchingBidHistories = useIsFetchingBidHistories()

  return isFetchingBid || isCalculatingBidBasis || isFetchingGroupedBids || isFetchingBidHistories
}

import { Box, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup } from '@mui/material'
import type { UseFormReturn } from 'react-hook-form'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TextFieldController from '@/components/inputs/TextFieldController'
import { USER_ROLES } from '@/constants/userRoles'
import MFASwitch from '@/features/user/components/form/MFASwitch'
import { DEFAULT_MFA_SETTINGS } from '@/features/user/constants/mfa'
import type { MfaPreferredOption, User } from '@/features/user/types/user'
import type { UserPersonalInfo } from '@/features/user/types/userPersonalInfo'

import UserProfileTotpOption from './UserProfileTotpOption'

type UserProfileSecuritySectionProps = {
  form: UseFormReturn<UserPersonalInfo, any, undefined>
  user: Partial<User>
}

function UserProfileSecuritySection({ form, user }: Readonly<UserProfileSecuritySectionProps>) {
  const { t } = useTranslation()

  const { setValue, getValues } = form

  const mfaSettings = getValues('mfaSettings') ?? DEFAULT_MFA_SETTINGS
  const selectedPreferredOption = getValues('mfaSettings.preferredOption')
  const isMFAEnabled = selectedPreferredOption !== null

  const hasAdministratorRole =
    user.role === USER_ROLES.ADMINISTRATORS.value || user.role === USER_ROLES.PARTNER_ADMINISTRATORS.value

  return (
    <FormProvider {...form}>
      <MFASwitch
        disabled={hasAdministratorRole}
        displayHelperText={false}
        error={false}
        isMFAEnabled={isMFAEnabled}
        onChange={(_, checked) => {
          if (!checked) {
            setValue('mfaSettings.preferredOption', null)
            return
          }

          setValue('mfaSettings.preferredOption', user.mfaSettings?.preferredOption ?? 'sms')
        }}
      />
      {isMFAEnabled && (
        <>
          <Box>
            <FormControl sx={{ marginBottom: 2, marginTop: 2 }}>
              <FormLabel id="mfa-method-radio-group" sx={(theme) => ({ ...theme.typography.h5 })}>
                {t('user_profile.settings.security.choose_preferred_method')}
              </FormLabel>
              <RadioGroup
                aria-labelledby="mfa-method-radio-group"
                value={selectedPreferredOption}
                onChange={(e) => setValue('mfaSettings.preferredOption', e.target.value as MfaPreferredOption)}
              >
                <FormControlLabel
                  control={<Radio />}
                  label={t('user_profile.settings.security.option.sms')}
                  value="sms"
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t('user_profile.settings.security.option.totp')}
                  value="totp"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {selectedPreferredOption === 'sms' && <SmsOption isMFAEnabled={user.isMFAEnabled} />}
          {selectedPreferredOption === 'totp' && (
            <UserProfileTotpOption
              hasPreferredOptionError={Boolean(form.formState.errors.mfaSettings?.preferredOption)}
              mfaSettings={mfaSettings}
              userId={user.id!}
              userUsername={user.username!}
            />
          )}
        </>
      )}
    </FormProvider>
  )
}

function SmsOption({ isMFAEnabled }) {
  const { t } = useTranslation()
  const { setValue } = useFormContext<UserPersonalInfo>()

  return (
    <Box>
      <TextFieldController
        label={t('user_profile.settings.form.phone_label')}
        name="phone"
        required={isMFAEnabled}
        slotProps={{
          input: {
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          },
        }}
        onChange={(event) => {
          // users are only allowed to enter numbers
          const value = event.target.value.replace(/\D/g, '')

          setValue('phone', value)
        }}
      />
    </Box>
  )
}

export default UserProfileSecuritySection

import { Outlet } from 'react-router-dom'

import type { Permission } from '@/features/authorization/contexts/PermissionsContext'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import NavigateToError from '@/features/error/pages/NavigateToError'

type ProtectedRouteProps = {
  permission: Permission
}

function ProtectedRoute({ permission }: Readonly<ProtectedRouteProps>) {
  const { permissions } = usePermissions()

  if (!permissions.has(permission)) {
    return <NavigateToError statusCode={403} />
  }

  return <Outlet />
}

export { ProtectedRoute }

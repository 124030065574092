import { AddOutlined, DeleteOutlined } from '@mui/icons-material'
import { Box, Divider, IconButton, Stack, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import TextFieldController from '@/components/inputs/TextFieldController'
import type { CustomerSettingsFormValues } from '@/features/customer/components/settings/CustomerSettingsForm'
import { EMAIL_REGEX } from '@/utils/email'

interface NoEmailRegisteredProps {
  onAddButtonClick: () => void
}

const EmptyEmailDisclaimer = ({ onAddButtonClick }: NoEmailRegisteredProps) => {
  return (
    <>
      <CustomTypography sx={{ ml: 2 }} variant={'body1'}>
        {t('customer_settings.form.notification_preferences.no_email')}
      </CustomTypography>
      <Divider />
      <Stack direction="row" justifyContent={'flex-end'}>
        <IconButton aria-label="add-email" size={'small'} onClick={onAddButtonClick}>
          <AddOutlined />
        </IconButton>
      </Stack>
    </>
  )
}

export const CustomerEmailNotificationAccordion = () => {
  const { watch, trigger, setValue } = useFormContext<CustomerSettingsFormValues>()

  const isEmailEnabled = watch('notificationPreferences.isEmailEnabled')
  const email = watch('notificationPreferences.email')

  const triggerEmailValidation = useCallback(() => {
    trigger('notificationPreferences.email')
  }, [trigger])

  useEffect(() => {
    triggerEmailValidation()
  }, [triggerEmailValidation, isEmailEnabled])

  return (
    <Stack gap={1}>
      <CustomTypography gutterBottom variant={'h3'}>
        {t('customer_settings.form.notification_preferences.email_description')}
      </CustomTypography>
      <Divider />
      {email === null ? (
        <EmptyEmailDisclaimer onAddButtonClick={() => setValue('notificationPreferences.email', '')} />
      ) : (
        <Stack alignItems={'flex-start'} direction="row">
          <TextFieldController
            controllerProps={{
              rules: {
                validate: (value) => {
                  if (!value && isEmailEnabled) {
                    return t('customer_settings.form.notification_preferences.email_required_error').toString()
                  }
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('customer_settings.form.notification_preferences.email_invalid_error').toString(),
                },
              },
            }}
            name="notificationPreferences.email"
          />

          <Tooltip
            title={
              isEmailEnabled
                ? t('customer_settings.form.notification_preferences.email_delete_disabled_reason')
                : undefined
            }
          >
            <Box>
              <IconButton
                aria-label="delete-email"
                disabled={isEmailEnabled}
                size={'small'}
                sx={{
                  ml: 1,
                }}
                onClick={() => {
                  setValue('notificationPreferences.email', null)
                }}
              >
                <DeleteOutlined />
              </IconButton>
            </Box>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  )
}

import axios from 'axios'

import environment from '@/environment'
import type { UpsertResourceSchedule } from '@/features/resource/types'

const BASE_RESOURCE_SCHEDULE_ACTIONS_API_URL = environment.services.resourceScheduleActionsApiUrl

export const upsertResourceSchedule = async (payload: UpsertResourceSchedule): Promise<any> => {
  const response = await axios.post(BASE_RESOURCE_SCHEDULE_ACTIONS_API_URL, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export const getResourceSchedule = async (
  resourceIds: string[],
  start: string, // Start time in UTC (ISO 8601 format)
  endExclusive: string, // End time in UTC (ISO 8601 format)
): Promise<any> => {
  const response = await axios.get(BASE_RESOURCE_SCHEDULE_ACTIONS_API_URL, {
    params: {
      resourceIds: resourceIds.join(','),
      start,
      endExclusive,
    },
  })
  return response.data
}

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomCardContent from '@/components/layouts/CustomCardContent'
import { useAlertContext } from '@/contexts/AlertContext'
import { ManualSteeringForm } from '@/features/resource/components/steering/ManualSteeringForm'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { updateBatterySoC } from '@/features/resource/endpoints/resources'
import { useBatteryInfoWithPollingQuery } from '@/features/resource/hooks/useBatteryInfoWithPollingQuery'
import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

const LABEL_COLOR = '#9e9e9e'

const StyledResourceDetailRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const StyledResourceDetailHeaderRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const BatterySteeringPage = () => {
  const { resourceUuid, controlPortId } = useParams()
  const { pushAlert } = useAlertContext()
  const { setActivePage } = useResourceDetails()
  const { t } = useTranslation()

  const [desiredSoC, setDesiredSoC] = useState<string>('')
  const [isSteeringActive, setIsSteeringActive] = useState(false)
  const [steeringType, setSteeringType] = useState<'SOC' | 'POWER' | null>(null)

  const { batteryInfo } = useBatteryInfoWithPollingQuery({ controlPortId: controlPortId! })
  const { steeringConfig } = useResourceWithPollingQuery({ id: resourceUuid! })

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_BATTERY_STEERING.routePath)
  }, [setActivePage])

  const handleSteeringTypeSelection = (type: 'SOC' | 'POWER') => {
    setSteeringType(type)
    setIsSteeringActive(true)
  }

  const handleSteeringComplete = () => {
    setIsSteeringActive(false)
    setSteeringType(null)
  }

  const handleSoCUpdate = async () => {
    const socValue = parseFloat(desiredSoC)
    if (socValue >= 0 && socValue <= 1) {
      try {
        await updateBatterySoC(resourceUuid!, socValue)
        pushAlert({
          message: t('resources.battery.success_update_soc'),
          severity: 'success',
        })
        handleSteeringComplete()
        setDesiredSoC('')
      } catch (error) {
        console.error('Failed to update battery SOC', error)
        pushAlert({
          message: t('resources.battery.errors_update_soc'),
          severity: 'error',
        })
      }
    } else {
      pushAlert({
        message: t('resources.battery.invalid_soc_value'),
        severity: 'error',
      })
    }
  }

  const columns = useMemo(
    () => [
      t('resources.battery.state_of_charge'),
      t('resources.battery.state_of_health'),
      t('resources.battery.rated_energy'),
      t('resources.battery.energy_to_full_charge'),
      t('resources.battery.max_power_from_grid'),
      t('resources.battery.max_power_to_grid'),
    ],
    [t],
  )

  if (!batteryInfo) return null

  const isInvalidSoC = desiredSoC === '' || parseFloat(desiredSoC) < 0 || parseFloat(desiredSoC) > 1

  return (
    <Card sx={{ marginY: 3 }}>
      <CustomCardContent>
        <CardHeader sx={{ paddingX: 0 }} title={t('resources.battery.information')} />
        <Table aria-label={t('resources.battery.information')} size="small">
          <TableHead>
            <StyledResourceDetailHeaderRow>
              {columns.map((column) => (
                <TableCell key={column}>
                  <CustomTypography color={LABEL_COLOR} variant="caption">
                    {column}
                  </CustomTypography>
                </TableCell>
              ))}
            </StyledResourceDetailHeaderRow>
          </TableHead>
          <TableBody>
            <StyledResourceDetailRow>
              <TableCell>{`${batteryInfo.stateOfCharge}`}</TableCell>
              <TableCell>{`${batteryInfo.stateOfHealth}`}</TableCell>
              <TableCell>{`${batteryInfo.ratedEnergy} W`}</TableCell>
              <TableCell>{`${batteryInfo.energyToFullCharge} W`}</TableCell>
              <TableCell>{`${batteryInfo.maxUsablePower} W`}</TableCell>
              <TableCell>{`${batteryInfo.minUsablePower} W`}</TableCell>
            </StyledResourceDetailRow>
          </TableBody>
        </Table>
        <Box mt={4}>
          {!isSteeringActive ? (
            <Box>
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" onClick={() => handleSteeringTypeSelection('SOC')}>
                  {t('resources.battery.start_soc_steering')}
                </Button>
                <Button variant="outlined" onClick={() => handleSteeringTypeSelection('POWER')}>
                  {t('resources.battery.start_manual_steering')}
                </Button>
              </Stack>
            </Box>
          ) : (
            <>
              {steeringType === 'SOC' && (
                <Card sx={{ bgcolor: 'rgba(20, 132, 160, 0.04)' }}>
                  <CardHeader title={t('resources.battery.set_soc')} />
                  <CardContent>
                    <Stack direction="row">
                      <Box flexBasis="25%">
                        <TextField
                          fullWidth
                          error={isInvalidSoC}
                          inputProps={{
                            step: '0.01',
                            min: 0,
                            max: 1,
                          }}
                          placeholder={t('resources.battery.enter_desired_soc')}
                          type="number"
                          value={desiredSoC}
                          onChange={(e) => setDesiredSoC(e.target.value)}
                        />
                      </Box>
                    </Stack>
                    <Stack direction="row" mt={2} spacing={2}>
                      <Button disabled={isInvalidSoC} variant="contained" onClick={handleSoCUpdate}>
                        {t('resources.battery.steer_to_soc')}
                      </Button>
                      <Button variant="outlined" onClick={handleSteeringComplete}>
                        {t('common.button.cancel')}
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              )}
              {steeringType === 'POWER' && (
                <ManualSteeringForm
                  isReleaseControlSupported={steeringConfig?.isReleaseControlSupported ?? false}
                  resourceId={resourceUuid!}
                  steeringDefaultNotDefined={steeringConfig?.steeringDefaultType === 'NONE'}
                  onSteeringComplete={handleSteeringComplete}
                />
              )}
            </>
          )}
        </Box>
      </CustomCardContent>
    </Card>
  )
}

export default BatterySteeringPage

import React from 'react'

import { createPricingVolumeLadder } from '@/features/bessDashboard/utils/biddingLadderLogic'
import type { Row } from '@/features/bessDashboard/utils/pricingTableGrid'

export function useMarketData(initialState: Row[]) {
  const [marketData, setMarketData] = React.useState<Record<string, Record<string, any>>>(() => {
    const acc: Record<string, Record<string, any>> = {}

    for (const item of initialState) {
      if (!acc[item.market_program_name]) {
        acc[item.market_program_name] = {}
      }
      acc[item.market_program_name][item.id] = item
    }

    return acc
  })

  const updateMarketData = (newValue: number, newLevel: number, marketProgram: string) => {
    setMarketData((prevData) => {
      const updatedProgramData = { ...prevData[marketProgram] }

      for (const currentItem of Object.values(updatedProgramData)) {
        currentItem.biddingLadder = createPricingVolumeLadder(
          currentItem.allocated_mw,
          currentItem.average_price,
          currentItem.lower_bound,
          currentItem.upper_bound,
          newValue,
          newLevel,
        )
      }

      return {
        ...prevData,
        [marketProgram]: updatedProgramData,
      }
    })
  }

  return { marketData, setMarketData, updateMarketData }
}

import type { DateTime } from 'luxon'

export function getGrafanaDisturbanceUrl({
  priceArea,
  startedAt,
  endedAt,
}: {
  priceArea: string | null
  startedAt: DateTime
  endedAt: DateTime | null
}) {
  const params = new URLSearchParams({
    orgId: '1',
    from: startedAt.toMillis().toString(),
  })

  if (priceArea) {
    params.set('var-PriceArea', priceArea.toLowerCase())
  }

  if (endedAt) {
    params.set('to', endedAt.toMillis().toString())
  }

  return `https://grafana.sympower.net/d/pmNVQz7Iz/disturbance-monitoring-new-fcr-requirements?${params.toString()}`
}

export function getGrafanaFcrnDisturbanceUrl({
  startedAt,
  endedAt,
}: {
  startedAt: DateTime
  endedAt: DateTime | null
}) {
  const params = new URLSearchParams({
    orgId: '1',
    from: startedAt.toMillis().toString(),
  })

  if (endedAt) {
    params.set('to', endedAt.toMillis().toString())
  }

  return `https://grafana.sympower.net/d/ae05dq05gqxhcd/realtime-bess-metrics?${params.toString()}`
}

import * as React from 'react'
import { useLocation } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import BidOfferPrice from '@/features/bidding/pages/BidOfferPrice'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'

const BidOfferPriceWrapper = () => {
  const location = useLocation()
  const groupParams = getGroupedBidParamsFromUrl(location)
  if (!groupParams) return <FullPageSpinner />

  return <BidOfferPrice groupParams={groupParams} />
}

export default BidOfferPriceWrapper

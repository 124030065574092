import type { Duration, DurationUnit } from 'luxon'
import { DateTime } from 'luxon'

export default function getDuration(startedAt: string, endedAt: string): Duration {
  const startedAtDate = DateTime.fromISO(startedAt)
  const endedAtDate = DateTime.fromISO(endedAt)
  return endedAtDate.diff(startedAtDate)
}

export function convertToTimeZoneDateTime(timeZone: string, dateStr: string) {
  return DateTime.fromISO(dateStr, { zone: DateTime.utc().zone }).setZone(timeZone)
}

export function formatDateTimeWithLocale(
  dateTime: DateTime,
  language: string,
  formatOption: Intl.DateTimeFormatOptions = DateTime.DATE_MED,
) {
  const locale = language === 'en' ? 'en-GB' : language
  return dateTime.setLocale(locale).toLocaleString(formatOption)
}

export function isBefore(date: DateTime, compareDate: DateTime, durationUnit: DurationUnit): boolean {
  return date.diff(compareDate, durationUnit)[durationUnit] < 0
}

export function isBeforeOrEqual(date: DateTime, compareDate: DateTime, durationUnit: DurationUnit): boolean {
  return date.diff(compareDate, durationUnit)[durationUnit] <= 0
}

export function isAfter(date: DateTime, compareDate: DateTime, durationUnit: DurationUnit): boolean {
  return date.diff(compareDate, durationUnit)[durationUnit] > 0
}

export function isAfterOrEqual(date: DateTime, compareDate: DateTime, durationUnit: DurationUnit): boolean {
  return date.diff(compareDate, durationUnit)[durationUnit] >= 0
}

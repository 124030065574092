import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ResourceSupportToolboxCard from '@/features/resource/components/ResourceSupportToolboxCard'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

const ResourceSupportToolboxPage = () => {
  const { resourceUuid } = useParams()
  const { setActivePage } = useResourceDetails()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_SUPPORT_TOOLBOX.routePath)
  }, [setActivePage])

  return <ResourceSupportToolboxCard resourceId={resourceUuid} />
}

export default ResourceSupportToolboxPage

import type { TFunction } from 'i18next'

const DECIMAL_PRICE_PRECISION = 100

export function convertToDecimalPrice(price: number | null | undefined) {
  if (!price) return 0
  return price / DECIMAL_PRICE_PRECISION
}

export function formatCapacity(t: TFunction, capacity: number) {
  return `${capacity.toFixed(2)} ${t('common.units.mwh')}`
}

export function formatRevenue(t: TFunction, revenue: number) {
  return `${t('customer_revenue.revenue.unit')}${revenue.toFixed(2)}`
}

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { BffActivatedResource } from '@/features/activation/types/activatedResource'
import { GET_BFF_ACTIVATED_RESOURCES_API_ID, getActivatedResources } from '@/features/customer/endpoints/bffActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivatedResourcesFromBffQueryParams = {
  siteId?: string
  activationId?: string
}
export type UseActivatedResourcesFromBffQueryResult = Omit<UseQueryResult, 'data'> & {
  bffActivatedResources: BffActivatedResource[] | null
}

export function useActivatedResourcesFromBffQuery(
  params: ActivatedResourcesFromBffQueryParams,
  options?: UseQueryOptions<BffActivatedResource[]>,
): UseActivatedResourcesFromBffQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BFF_ACTIVATED_RESOURCES_API_ID, params],
    queryFn: () => getActivatedResources(params.siteId, params.activationId),
  })

  return { bffActivatedResources: data, ...queryResult }
}

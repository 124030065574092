import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import CustomersGrid from '@/features/integration/components/CustomersGrid'

const IntegrationsCustomers = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeader isRoot pageTitle={t('common.customers')} />

      <CustomersGrid />
    </>
  )
}

export default IntegrationsCustomers

const _1_MW = 1000000

export function convertFromWToMW(volume: number | null | undefined) {
  if (!volume) return 0
  return volume / _1_MW
}

export function convertFromWToRoundedMW(measurement: number) {
  return `${roundToTwoDecimalPlaces(convertFromWToMW(measurement))}`
}

function roundToTwoDecimalPlaces(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

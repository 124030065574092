import { DateTime } from 'luxon'
import type { DateTime as DateTimeType } from 'luxon/src/datetime'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Period } from '@/constants/period'
import BaselineChart from '@/features/report/components/BaselineChart'
import {
  useInvalidateRetrospectXyBaselineQuery,
  useRetrospectXyBaselineQuery,
} from '@/features/report/hooks/useRetrospectXyBaselineQuery'
import { calculatePeriodStartTime } from '@/features/report/utils/utils'

const RETROSPECT_LINE_COLOR = '#673AB7'

type RetrospectXyBaselineChartProps = {
  portfolio: string
  timezone: string
}

function RetrospectXyBaselineChart({ portfolio, timezone }: Readonly<RetrospectXyBaselineChartProps>) {
  const { t } = useTranslation()

  const [startTime, setStartTime] = useState<DateTimeType>(calculatePeriodStartTime(Period.PAST_MONTH))
  const [endTime, setEndTime] = useState<DateTimeType>(DateTime.now())

  const { isLoading, timeData, baselineSeries } = useRetrospectXyBaselineQuery({ startTime, endTime, portfolio })

  const invalidateRetrospectXyBaselineQuery = useInvalidateRetrospectXyBaselineQuery()

  useEffect(() => {
    invalidateRetrospectXyBaselineQuery()
  }, [startTime, endTime, portfolio])

  return (
    <BaselineChart
      baselineSeries={baselineSeries}
      endTime={endTime}
      infoText={t('reports.baseline.complete.info')}
      initialPeriodSelection={Period.PAST_MONTH}
      isLoading={isLoading}
      lineColor={RETROSPECT_LINE_COLOR}
      setEndTime={setEndTime}
      setStartTime={setStartTime}
      startTime={startTime}
      timeData={timeData}
      timezone={timezone}
      title={t(`reports.baseline.complete.title`)}
    />
  )
}

export default RetrospectXyBaselineChart

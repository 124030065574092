import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import ActivationGroupsDataGrid from '@/features/activationGroup/components/ActivationGroupsDataGrid'

const ActivationGroupsIndex = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeader isRoot pageTitle={t('component.page_header.activation_groups')} />

      <ActivationGroupsDataGrid />
    </>
  )
}

export default ActivationGroupsIndex

import { DateTime } from 'luxon'

import { Period } from '@/constants/period'

export function calculatePeriodStartTime(period: Period) {
  switch (period) {
    case Period.PAST_MONTH:
      return DateTime.now().minus({ days: 30 })
    case Period.PAST_24_HOURS:
      return DateTime.now().minus({ hours: 24 })
    case Period.PAST_12_HOURS:
      return DateTime.now().minus({ hours: 12 })
    case Period.PAST_6_HOURS:
      return DateTime.now().minus({ hours: 6 })
    case Period.PAST_3_HOURS:
      return DateTime.now().minus({ hours: 3 })
    case Period.PAST_HOUR:
      return DateTime.now().minus({ hours: 1 })
    case Period.NEXT_24_HOURS:
    case Period.NEXT_48_HOURS:
    case Period.NEXT_72_HOURS:
    default:
      return DateTime.now()
  }
}

export function calculatePeriodEndTime(period: Period) {
  switch (period) {
    case Period.NEXT_24_HOURS:
      return DateTime.now().plus({ hours: 24 })
    case Period.NEXT_48_HOURS:
      return DateTime.now().plus({ hours: 48 })
    case Period.NEXT_72_HOURS:
      return DateTime.now().plus({ hours: 72 })
    case Period.PAST_MONTH:
    case Period.PAST_24_HOURS:
    case Period.PAST_12_HOURS:
    case Period.PAST_6_HOURS:
    case Period.PAST_3_HOURS:
    case Period.PAST_HOUR:
    default:
      return DateTime.now()
  }
}

export function formatDateTimeForPeriodSelection(dateTime: DateTime) {
  return dateTime.toFormat('dd-MM-yyyy, HH:mm')
}

export function formatDateTimeForTimeline(dateTime: DateTime) {
  const dayPart = dateTime.toFormat('yyyy-MM-dd')
  const timePart = dateTime.toFormat('HH:mm')

  return { dayPart, timePart }
}

import ReactGA from 'react-ga4'

export function setGoogleAnalyticsPageTag(pageTag?: string) {
  if (!pageTag) return

  ReactGA.send({
    hitType: 'pageview',
    page_tag: pageTag.replace(/\s+/g, '-').toLowerCase(),
    page_title: null,
  })
}

import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import type { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { AcceptedQuantityDiffCell } from '@/features/bidding/components/AcceptedQuantityDiffCell'
import BidVersionPtusToolbar from '@/features/bidding/components/BidVersionPtusToolbar'
import { OfferedCapacityDiffCell } from '@/features/bidding/components/OfferedCapacityDiffCell'
import { MAX_PTUS_PER_PAGE } from '@/features/bidding/constants'
import { useForecastQuery } from '@/features/bidding/hooks/useForecastQuery'
import type { Bid, Forecast, Ptu, Volume } from '@/features/bidding/types/bid'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { findVolumeForTime } from '@/features/bidding/utils/findVolumeForTime'

type Props = {
  bidSelectorComponent?: ReactElement
  bidVersion: Bid
  boughtBackVersion?: Bid
  isLoading: boolean
}

type PtuResult = {
  ptu: Ptu
  offeredVolume: Volume
  acceptedVolume?: Volume
  forecastedVolume?: Volume
}

const BidVersionPtusDataGrid: FC<Props> = ({ bidVersion, boughtBackVersion, isLoading, bidSelectorComponent }) => {
  const { t } = useTranslation()
  const { versionForecast } = useForecastQuery({
    date: bidVersion.deliveryDay,
    activationGroupUuid: bidVersion.activationGroupUuid,
  })

  return (
    <CustomDataGrid
      hideFooter
      columns={getColumns(bidVersion, boughtBackVersion, t)}
      data-testid={`activation-group-bid-${bidVersion.activationGroupUuid}`}
      getRowId={(row: PtuResult) => row.ptu.start.toString()}
      initialState={{ pagination: { paginationModel: { pageSize: MAX_PTUS_PER_PAGE, page: 0 } } }}
      isLoading={isLoading}
      rows={mapToPtuResults(bidVersion, versionForecast)}
      slotProps={{ toolbar: { bid: bidVersion, bidSelectorComponent: bidSelectorComponent } }}
      slots={{ toolbar: BidVersionPtusToolbar }}
      sx={{ '& .MuiDataGrid-footerContainer': { display: 'none !important;' } }}
    />
  )
}

const getColumns = (bidVersion: Bid, boughtBackVersion: Bid | undefined, t: TFunction): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: 'ptu.start',
      headerName: t('bidding.results.header.ptu'),
      flex: 1,
      valueFormatter: (value: DateTime) => value.toFormat('HH:mm'),
      valueGetter: (_, ptuResult: PtuResult) => ptuResult.ptu.start,
    },
  ]
  if (boughtBackVersion) {
    columns.push(...getBuybackColumns(boughtBackVersion, t))
  } else {
    columns.push(...getDifferenceColumns(!!bidVersion.acceptedBid, t))
  }
  return columns
}

const getDifferenceColumns = (hasAcceptedValues: boolean, t: TFunction): GridColDef[] => {
  const differenceColumns = [
    {
      field: 'ptu.forecastedWatts',
      headerName: t('bidding.results.header.forecast'),
      valueFormatter: (value: number | null) => `${value ? convertToRoundedMw(value) : '-'} MW`,
      valueGetter: (_, ptuResult: PtuResult) => ptuResult.forecastedVolume?.quantity ?? null,
      flex: 1,
    },
    {
      field: 'ptu.offeredWattsDiff',
      headerName: t('bidding.results.header.offered'),
      renderCell: (params: GridRenderCellParams<PtuResult>) => <OfferedCapacityDiffCell row={params.row} />,
      flex: 1,
    },
  ]

  if (hasAcceptedValues) {
    differenceColumns.push({
      field: 'ptu.acceptedWattsDiff',
      headerName: t('bidding.results.header.accepted'),
      renderCell: (params: GridRenderCellParams<PtuResult>) => <AcceptedQuantityDiffCell row={params.row} />,
      flex: 1,
    })
  }

  return differenceColumns
}

const getBuybackColumns = (boughtBackVersion: Bid, t: TFunction): GridColDef[] => [
  {
    field: 'ptu.offeredWatts',
    headerName: t('bidding.results.header.offered'),
    valueFormatter: (value: number) => `${convertToRoundedMw(value)} MW`,
    valueGetter: (_, ptuResult: PtuResult) => ptuResult.offeredVolume.quantity,
    flex: 1,
  },
  {
    field: 'ptu.acceptedWatts',
    headerName: t('bidding.results.header.accepted'),
    valueFormatter: (value: number | null) => `${value ? convertToRoundedMw(value) : '-'} MW`,
    valueGetter: (_, ptuResult: PtuResult) => getOriginalAcceptedAmount(ptuResult, boughtBackVersion),
    flex: 1,
  },
  {
    field: 'ptu.buyback',
    headerName: t('bidding.results.header.buyback'),
    valueFormatter: (value: number | null) => `${value ? convertToRoundedMw(value) : '-'} MW`,
    valueGetter: (_, ptuResult: PtuResult) => getBuybackAmount(ptuResult, boughtBackVersion),
    flex: 1,
  },
  {
    field: 'ptu.netResult',
    headerName: t('bidding.results.header.net_result'),
    valueFormatter: (value: number | null) => `${value ? convertToRoundedMw(value) : '-'} MW`,
    valueGetter: (_, ptuResult: PtuResult) => ptuResult.acceptedVolume?.quantity ?? null,
    flex: 1,
  },
]

const mapToPtuResults = (bidVersion: Bid, versionForecast?: Forecast | null): PtuResult[] =>
  bidVersion.offeredBid.map((offeredPtu) => ({
    ptu: offeredPtu.ptu,
    offeredVolume: offeredPtu.volume,
    forecastedVolume: versionForecast
      ? findVolumeForTime(offeredPtu.ptu.start, versionForecast.forecastPtus)
      : undefined,
    acceptedVolume: bidVersion.acceptedBid && findVolumeForTime(offeredPtu.ptu.start, bidVersion.acceptedBid),
  }))

const getOriginalAcceptedAmount = (currentPtuResult: PtuResult, boughtBackVersion: Bid) => {
  if (!boughtBackVersion.acceptedBid) return

  return findVolumeForTime(currentPtuResult.ptu.start, boughtBackVersion.acceptedBid)?.quantity
}

const getBuybackAmount = (currentPtuResult: PtuResult, boughtBackVersion: Bid) => {
  const originalAccepted = getOriginalAcceptedAmount(currentPtuResult, boughtBackVersion)
  if (!originalAccepted) return

  return originalAccepted - currentPtuResult.acceptedVolume!.quantity
}

export default BidVersionPtusDataGrid

import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createSchedule } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiCreateSchedule } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseCreateSpotOnScheduleMutationResult = Omit<
  UseMutationResult<ApiCreateSchedule, Error, ApiCreateSchedule>,
  'mutateAsync'
> & {
  createSchedule: UseMutationResult<ApiCreateSchedule, Error, ApiCreateSchedule>['mutateAsync']
}

export function useCreateSpotOnScheduleMutation(
  options?: UseMutationOptions<ApiCreateSchedule, ApiCreateSchedule>,
): UseCreateSpotOnScheduleMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiCreateSchedule) => createSchedule(variables),
  })

  return { createSchedule: mutateAsync, ...mutationResult }
}

import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import { DataGridSyncUrlManagerProvider } from '@/contexts/DataGridSyncUrlManagerContext'
import NavigateToError from '@/features/error/pages/NavigateToError'
import UserCreate from '@/features/user/pages/UserCreate'
import UserEdit from '@/features/user/pages/UserEdit'
import Users from '@/features/user/pages/Users'

export default function UsersRoutes() {
  const { t } = useTranslation()

  return (
    <Routes>
      <Route
        element={
          <DataGridSyncUrlManagerProvider>
            <PageHeader isRoot pageTag={'users'} pageTitle={t('component.page_header.users')} />

            <Users />
          </DataGridSyncUrlManagerProvider>
        }
        path="/"
      />
      <Route
        element={
          <>
            <PageHeader
              breadcrumbItems={[
                {
                  text: t('component.page_header.users'),
                  to: '/users',
                },
              ]}
              pageTag={'user-create'}
              pageTitle={t('component.page_header.user.create')}
            />
            <UserCreate />
          </>
        }
        path="/create"
      />
      <Route
        element={
          <>
            <PageHeader
              breadcrumbItems={[
                {
                  text: t('component.page_header.users'),
                  to: '/users',
                },
              ]}
              pageTag={'user-edit'}
              pageTitle={t('component.page_header.user.details')}
            />
            <UserEdit />
          </>
        }
        path="/:id"
      />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}

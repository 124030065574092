import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { DateTime } from 'luxon'

import { GET_LIVE_XY_BASELINE_API, getLiveXyBaseline } from '@/features/report/endpoints/insights'
import type { ApiBaseline, BaselineSerie } from '@/features/report/types/baseline'
import type { UseQueryOptions } from '@/types/queries'

export type UseBaselineQueryParams = {
  startTime: DateTime
  endTime: DateTime
  portfolio: string
}

export type UseBaselineQueryResult = Omit<UseQueryResult, 'data'> & {
  timeData: DateTime[]
  baselineSeries: BaselineSerie[]
}

export function useLiveXyBaselineQuery(
  params: UseBaselineQueryParams,
  options?: UseQueryOptions<ApiBaseline>,
): UseBaselineQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_LIVE_XY_BASELINE_API, { params }],
    queryFn: () => getLiveXyBaseline(params.startTime, params.endTime, params.portfolio),
  })

  return { timeData: data?.timeData ?? [], baselineSeries: data?.baselineSeries ?? [], ...queryResult }
}

export function useInvalidateLiveXyBaselineQuery() {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries({ queryKey: [GET_LIVE_XY_BASELINE_API] })
}

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_CUSTOMER_API_ID, getCustomer } from '@/features/integration/endpoints/customers'
import type { Customer } from '@/features/integration/types'
import type { UseQueryOptions } from '@/types/queries'

type UseCustomerQueryParams = {
  uuid?: string
}

export type UseCustomerQueryResult = Omit<UseQueryResult, 'data'> & {
  customer: Customer | null
}

export function useIntegrationCustomerQuery(
  params: UseCustomerQueryParams,
  options?: UseQueryOptions<Customer>,
): UseCustomerQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryFn: () => getCustomer(),
    queryKey: [GET_CUSTOMER_API_ID, params],
    enabled: Boolean(params.uuid),
  })

  return {
    ...queryResult,
    customer: data ?? null,
  }
}

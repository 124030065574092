import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { MagActivation } from '@/features/activation/types/activation'
import { GET_MAG_ACTIVATION_API_ID, getActivation } from '@/features/customer/endpoints/magActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivationFromMagQueryParams = {
  customerUuid: string
  activationId: number
  location?: string
}
export type UseActivationFromMagQueryResult = Omit<UseQueryResult, 'data'> & {
  magActivation: MagActivation | null
}

export function useActivationFromMagQuery(
  params: ActivationFromMagQueryParams,
  options?: UseQueryOptions<MagActivation>,
): UseActivationFromMagQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_MAG_ACTIVATION_API_ID, params],
    queryFn: () => getActivation(params.customerUuid, params.activationId, params.location),
  })

  return { magActivation: data, ...queryResult }
}

import type { DateTime } from 'luxon'

export function formatDateTimeForPeriodSelection(dateTime: DateTime) {
  return dateTime.toFormat('dd-MM-yyyy')
}

export function formatDate(dateString) {
  const date = new Date(dateString)
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function formatTimestampToUtcIso(timestamp) {
  return timestamp.toUTC().toISO()
}

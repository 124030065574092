import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { BffActivation } from '@/features/activation/types/activation'
import { GET_BFF_ACTIVATION_API_ID, getActivation } from '@/features/customer/endpoints/bffActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivationFromBffQueryParams = {
  activationId: string
}
export type UseActivationFromBffQueryResult = Omit<UseQueryResult, 'data'> & {
  bffActivation: BffActivation | null
}

export function useActivationFromBffQuery(
  params: ActivationFromBffQueryParams,
  options?: UseQueryOptions<BffActivation>,
): UseActivationFromBffQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BFF_ACTIVATION_API_ID, params],
    queryFn: () => getActivation(params.activationId),
  })

  return { bffActivation: data, ...queryResult }
}

import { useQuery } from '@tanstack/react-query'
import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

import {
  GET_SPOT_ON_SCHEDULE_PROGRAM_API_ID,
  getSpotOnScheduleProgram,
} from '@/features/resource/endpoints/scheduleBuilder'
import type { ScheduleProgram } from '@/features/resource/types/spotOnForEboilers'

export type State = {
  spotOnScheduleProgram: ScheduleProgram | null
}

export type SpotOnEboilerScheduleProgramProviderProps = {
  children: ReactNode
}

const initialState: State = {
  spotOnScheduleProgram: null,
}

const SpotOnEboilerContext = createContext<State>(initialState)

function SpotOnEboilerScheduleProgramProvider(props: Readonly<SpotOnEboilerScheduleProgramProviderProps>) {
  const { data: spotOnScheduleProgram } = useQuery({
    queryKey: [GET_SPOT_ON_SCHEDULE_PROGRAM_API_ID],
    queryFn: getSpotOnScheduleProgram,
  })

  const value = useMemo(
    () => ({
      spotOnScheduleProgram: spotOnScheduleProgram ?? null,
    }),
    [spotOnScheduleProgram],
  )

  return <SpotOnEboilerContext.Provider value={value} {...props} />
}

const useSpotOnEboilerScheduleProgram = () => {
  return useContext(SpotOnEboilerContext)
}
export { SpotOnEboilerScheduleProgramProvider, useSpotOnEboilerScheduleProgram }

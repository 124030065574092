import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import type { Customer, CustomerSettingsPayload } from '@/features/customer/types/customer'
import type { User } from '@/features/user/types/user'

// All different permissions that can be set in the application.
export type Permission =
  | 'activationGroups'
  | 'bidding'
  | 'disturbances'
  | 'exampleFeatures'
  | 'bessDashboard'
  | 'integrations'
  | 'partners'
  | 'resources'
  | 'customers'
  | 'customerDetails'
  | 'reports'
  | 'revenues'
  | 'powerMeasurements'
  | 'spotOnForEboilers'
  | 'users'

export type PermissionsProviderProps = {
  children: ReactNode
}

type PermissionHandlerParams = {
  loggedInUser: User
  customer: Customer | null
  customerSettings: CustomerSettingsPayload | null
}

type PermissionHandler = (params: PermissionHandlerParams) => boolean

type SetPermissionsAction = {
  type: 'SET_PERMISSIONS'
  payload: Set<Permission>
}

type ResetPermissionsAction = {
  type: 'RESET_PERMISSIONS'
}

type SetErrorAction = {
  type: 'SET_ERROR'
  payload: Error
}

type PermissionActions = SetPermissionsAction | ResetPermissionsAction | SetErrorAction

export type State = {
  isLoading: boolean
  error: Error | null
  permissions: Set<Permission>
}

function reducer(state: State, action: PermissionActions): State {
  switch (action.type) {
    case 'SET_PERMISSIONS':
      return { ...state, isLoading: false, permissions: action.payload }
    case 'RESET_PERMISSIONS':
      return { ...state, isLoading: false, permissions: new Set() }
    case 'SET_ERROR':
      return { ...state, isLoading: false, permissions: new Set(), error: action.payload }
    default:
      return state
  }
}

// High order function that checks if the user has one of the allowed roles.
function hasOneOfRoles(allowedRoles: string[]) {
  return ({ loggedInUser }: PermissionHandlerParams): boolean => {
    const userRole = loggedInUser?.role

    if (!userRole) {
      return false
    }

    return allowedRoles.includes(userRole)
  }
}

// Includes all the permissions and their respective handlers. The handlers are used to determine if a user has a specific permission.
// permissionHandlers is run once the application starts in order to define the permissions for the logged in user.
// Once the app is loaded, the permissions are set in the state and can be accessed by the components using the usePermissions hook.
const permissionHandlers: Record<Permission, PermissionHandler> = {
  ['activationGroups']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['bidding']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['disturbances']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['exampleFeatures']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['bessDashboard']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['integrations']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['partners']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['resources']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['customers']: hasOneOfRoles([USER_ROLES.RESOURCE_OWNERS.value]),
  ['customerDetails']: hasOneOfRoles([
    USER_ROLES.ADMINISTRATORS.value,
    USER_ROLES.PARTNER_ADMINISTRATORS.value,
    USER_ROLES.CUSTOMER_MANAGERS.value,
  ]),
  ['reports']: (params) => {
    const hasPartnerCodePermission = params.loggedInUser?.partnerCode === 'GR-OPTIMUS'

    return (
      hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value])(params) ||
      (hasOneOfRoles([USER_ROLES.PARTNER_ADMINISTRATORS.value, USER_ROLES.CUSTOMER_MANAGERS.value])(params) &&
        hasPartnerCodePermission)
    )
  },
  ['revenues']: (params) => {
    const { loggedInUser, customer, customerSettings } = params
    const hasLocationPermission = customer?.location === 'fi'
    const hasFinancialPermission = customerSettings?.isFinancialVisible ?? false
    const hasPartnerCodePermission = loggedInUser?.partnerCode === 'SYMPOWER'

    return (
      hasOneOfRoles([USER_ROLES.RESOURCE_OWNERS.value])(params) &&
      hasLocationPermission &&
      hasFinancialPermission &&
      hasPartnerCodePermission
    )
  },
  ['powerMeasurements']: (params) => {
    const { customer } = params
    const supportedCountries = ['FI', 'SE', 'NO']
    const hasSupportedCountry = supportedCountries.includes(customer?.countryCode ?? '')
    return hasOneOfRoles([USER_ROLES.RESOURCE_OWNERS.value])(params) && hasSupportedCountry
  },
  ['spotOnForEboilers']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['users']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value, USER_ROLES.PARTNER_ADMINISTRATORS.value]),
}

const initialState: State = {
  isLoading: true,
  permissions: new Set(),
  error: null,
}

const PermissionsContext = createContext<State>(initialState)

export function PermissionsProvider(props: Readonly<PermissionsProviderProps>) {
  const { loggedInUser, isStarting: isAuthLoading, error: authError } = useAuth()
  const firstAllowedRoId = loggedInUser?.allowedRoIds?.[0]
  const hasCustomer = loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value && !!firstAllowedRoId
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    customerSettings,
    isLoading: isCustomerSettingsLoading,
    error: customerSettingsError,
  } = useCustomerSettingsQuery(
    { uuid: firstAllowedRoId! },
    {
      enabled: hasCustomer,
    },
  )
  const {
    customer,
    isLoading: isCustomerLoading,
    error: customerError,
  } = useCustomerQuery(
    { uuid: firstAllowedRoId },
    {
      enabled: hasCustomer,
    },
  )
  const isPermissionsLoading = isAuthLoading || isCustomerSettingsLoading || isCustomerLoading

  useEffect(() => {
    // We wait until all async operations are done before setting the permissions
    if (!loggedInUser || isPermissionsLoading) {
      return
    }

    const permissions = new Set<Permission>()

    for (const [permission, handler] of Object.entries(permissionHandlers)) {
      // Adds the permission just when the permission handler returns true.
      if (handler({ loggedInUser, customer, customerSettings })) {
        permissions.add(permission as Permission)
      }
    }

    // Setting the permissions also sets the isLoading flag to false. This way we can be sure that the permissions are set and the app is ready to be used.
    dispatch({ type: 'SET_PERMISSIONS', payload: permissions })

    return () => {
      dispatch({ type: 'RESET_PERMISSIONS' })
    }
  }, [isPermissionsLoading, loggedInUser, customer, customerSettings])

  useEffect(() => {
    const error = customerSettingsError || customerError || authError

    if (error) {
      dispatch({ type: 'SET_ERROR', payload: error })
    }
  }, [customerSettingsError, customerError, authError])

  return <PermissionsContext.Provider value={state}>{props.children}</PermissionsContext.Provider>
}

export function usePermissions() {
  return useContext(PermissionsContext)
}

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import GenericPrequalificationsDataGrid from '@/features/activationGroup/components/GenericPrequalificationsDataGrid'
import PrequalificationsDataGrid from '@/features/activationGroup/components/PrequalificationsDataGrid'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useActivationGroupQuery } from '@/features/activationGroup/hooks/useActivationGroupQuery'
import { isGenericPrequalificationApi } from '@/features/activationGroup/utils/genericPrequalificationPredicates'

const ActivationGroupPrequalificationPatternDetails = () => {
  const { t } = useTranslation()
  const { activationGroupUuid = null, patternUuid } = useParams()
  const { activationGroup } = useActivationGroupQuery({ activationGroupUuid })

  if (!activationGroupUuid || !patternUuid || !activationGroup) {
    return null
  }

  const genericPrequalificationApi = isGenericPrequalificationApi(activationGroup)

  return (
    <>
      <PageHeader
        breadcrumbItems={[
          {
            text: t('component.page_header.activation_groups'),
            to: ActivationGroupRouteInformation.INDEX.navigationPath(),
          },
          {
            text: t('component.page_header.prequalification_patterns'),
            to: ActivationGroupRouteInformation.ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS.navigationPath(
              activationGroupUuid,
            ),
          },
        ]}
        pageTitle={t('component.page_header.prequalifications')}
      />

      {genericPrequalificationApi ? (
        <GenericPrequalificationsDataGrid patternUuid={patternUuid} />
      ) : (
        <PrequalificationsDataGrid patternUuid={patternUuid} />
      )}
    </>
  )
}

export default ActivationGroupPrequalificationPatternDetails

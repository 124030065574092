import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { findForecast, GET_FORECAST_API_ID } from '@/features/bidding/endpoints/bids'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { Forecast } from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import type { UseQueryOptions } from '@/types/queries'

export type ForecastQueryParams = {
  date: MarketDate
  activationGroupUuid: string
}
export type UseForecastQueryResult = Omit<UseQueryResult, 'data'> & {
  versionForecast: Forecast | null
}

export function useForecastQuery(
  params: ForecastQueryParams,
  options?: UseQueryOptions<Forecast | null>,
): UseForecastQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_FORECAST_API_ID, params],
    queryFn: () => findForecast(params.date, params.activationGroupUuid),
  })

  useErrorHandler(queryResult.isError)

  return { versionForecast: data, ...queryResult }
}

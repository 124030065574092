import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  initialWeeks: number
  onWeeksChange: (weeks: number) => void
}

const WeekSelector: FC<Props> = ({ initialWeeks, onWeeksChange }) => {
  const { t } = useTranslation()

  const [weeks, setWeeks] = useState(initialWeeks)

  const handleChange = (_event: any, newWeeks: number) => {
    setWeeks(newWeeks)
    onWeeksChange(newWeeks)
  }

  return (
    <ToggleButtonGroup
      exclusive
      aria-label={t('bidding.week_selector.title')}
      color="primary"
      data-testid="week-selector"
      size="small"
      value={weeks}
      onChange={handleChange}
    >
      <ToggleButton value={4}>{t('bidding.week_selector.one_month')}</ToggleButton>
      <ToggleButton value={3}>{t('bidding.week_selector.three_weeks')}</ToggleButton>
      <ToggleButton value={2}>{t('bidding.week_selector.two_weeks')}</ToggleButton>
      <ToggleButton value={1}>{t('bidding.week_selector.one_week')}</ToggleButton>
    </ToggleButtonGroup>
  )
}

export default WeekSelector

export function encodeFileUrlToBase64(fileUrl: string): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = () => {
      const base64 = reader.result as string
      resolve(base64)
    }
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => reader.readAsDataURL(blob))
  })
}

export async function decodeBase64ToUrl(base64: string): Promise<string> {
  const response = await fetch(base64)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

import type { AxiosResponse } from 'axios'
import axios from 'axios'

import environment from '@/environment'
import { FEATURE_TOGGLES } from '@/features/featureToggle/constants/featureToggleValuesForDevelopmentMode'
import type { FeatureToggles } from '@/features/featureToggle/types/featureToggle'

export const GET_FEATURE_TOGGLES_API_ID = 'GET_FEATURE_TOGGLES'

export async function getFeatureToggles(context?: Record<string, string>): Promise<FeatureToggles> {
  if (environment.isDevelopmentMode) {
    if (!context) {
      return Promise.resolve(FEATURE_TOGGLES)
    }
    const filteredToggles = FEATURE_TOGGLES.toggles.map((toggle) => {
      if (!toggle.contexts) {
        return toggle
      }

      // Check if all provided context values match the toggle's context rules
      const enabled = Object.entries(context).every(([key, value]) => {
        const allowedValues = toggle.contexts ? toggle.contexts[key] : undefined
        return !allowedValues || allowedValues.includes(value)
      })

      return {
        ...toggle,
        enabled: toggle.enabled && enabled,
      }
    })

    return Promise.resolve({
      toggles: filteredToggles,
    })
  }

  const response: AxiosResponse<FeatureToggles> = await axios.get(
    `${environment.services.featureTogglesManagerApiUrl}/proxy`,
    {
      headers: {
        'Extra-authorization': 'sympower-unleash-proxy',
      },
      params: context,
    },
  )

  return {
    toggles: response.data.toggles,
  }
}

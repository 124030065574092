import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import ReactECharts from 'echarts-for-react'
import type { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { COMMON_CHART_OPTIONS } from '@/features/bessDashboard/constants/chart'
import type { FormattedRow } from '@/features/bessDashboard/types'
import { filterByType } from '@/features/bessDashboard/utils/chart'
import { generateBoxPlotDataByMarketProgram } from '@/features/bessDashboard/utils/historicalPriceOverviewChart'
import { formatDateTimeForPeriodSelection } from '@/features/bessDashboard/utils/utils'

type HistoricalPriceOverviewChartProps = {
  title: string
  infoText: string
  unit?: string | null
  filterType?: string | null
  data: FormattedRow[]
  isLoading: boolean
  isFetching: boolean
  startTime: DateTime
  endTime: DateTime
}

const HistoricalPriceOverviewChart: React.FC<HistoricalPriceOverviewChartProps> = ({
  title,
  infoText,
  data,
  unit = '',
  filterType = null,
  startTime,
  endTime,
}) => {
  const { t } = useTranslation()

  const dataset = filterType ? filterByType(data, filterType) : data

  const { datasets, series } = generateBoxPlotDataByMarketProgram(dataset)

  const option = {
    ...COMMON_CHART_OPTIONS,
    legend: { top: '1%' },
    tooltip: {
      trigger: 'axis',
      confine: true,
    },
    xAxis: {
      type: 'category',
      name: 'Hour of Day',
      splitAreaShow: false,
      splitAreaColor: ['#eeeeee'],
    },
    yAxis: {
      type: 'value',
      name: 'Price',
      axisLabel: {
        formatter: (value) => `${Number(value).toFixed(0)} ${unit}`,
      },
    },
    dataset: datasets,
    series: series,
  }

  return (
    <Paper sx={{ p: 2, mb: 3 }} variant="outlined">
      <Stack spacing={2}>
        <Box display="flex" flexDirection="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">{title}</Typography>{' '}
          <Typography fontSize={16}>
            {`${formatDateTimeForPeriodSelection(startTime.setZone('UTC'))} `}
            <i>{t('common.period.to_lowercase')}</i>
            {` ${formatDateTimeForPeriodSelection(endTime.setZone('UTC'))}`}
          </Typography>
        </Box>

        <Stack direction="row" spacing={1}>
          <InfoOutlinedIcon color={'info'} width={'20px'} />
          <Typography fontSize={16} variant="body1">
            {infoText}
          </Typography>
        </Stack>

        <ReactECharts option={option} style={{ width: '100%', height: '500px' }} />
      </Stack>
    </Paper>
  )
}

export default HistoricalPriceOverviewChart

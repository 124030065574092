import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getForecasts, getPriceImpact } from '@/features/bessDashboard/endpoints/databricksMarketForecasts'
import type { FormattedRow } from '@/features/bessDashboard/types'
import type { UseQueryOptions } from '@/types/queries'

export type UseForecastQueryResult<T> = Omit<UseQueryResult, 'data'> & {
  data: T
}

export function useForecastQuery(
  rangeStart: string,
  rangeEnd: string,
  options?: UseQueryOptions<FormattedRow[]>,
): UseForecastQueryResult<FormattedRow[]> {
  const { data, ...queryResult } = useQuery<any>({
    ...options,
    queryFn: () => getForecasts(rangeStart, rangeEnd),
    queryKey: ['GET_MARKET_FORECASTS', rangeStart, rangeEnd],
  })

  return {
    ...queryResult,
    data: data ?? [],
  }
}

export function useImpactQuery(
  rangeStart: string,
  rangeEnd: string,
  options?: UseQueryOptions<FormattedRow[]>,
): UseForecastQueryResult<FormattedRow[]> {
  const { data, ...queryResult } = useQuery<FormattedRow[], Error>({
    ...options,
    queryFn: () => getPriceImpact(rangeStart, rangeEnd), // Pass the date argument to the query function
    queryKey: ['GET_VOLUME_PRICE_IMPACT', rangeStart, rangeEnd], // Ensure the query key is unique per date
  })

  return {
    ...queryResult,
    data: data ?? [],
  }
}

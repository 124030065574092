export function getHourInUTCFromTimestamp(timestamp) {
  const date = new Date(timestamp)
  return date.getUTCHours()
}

export function convertDataTo2DArray(data) {
  const result: number[][] = Array.from({ length: 24 }, () => [])

  for (const element of data) {
    const hour = getHourInUTCFromTimestamp(element.ds)
    if (typeof hour === 'number' && hour >= 0 && hour < 24) {
      result[hour].push(parseFloat(element.actual))
    }
  }

  return result.map((arr) => arr.filter((value) => !isNaN(value)))
}

export function generateBoxPlotDataByMarketProgram(data) {
  const datasets: any[] = []
  const series: any[] = []

  const order: string[] = ['FCR-D UP', 'FCR-D DOWN', 'FCR-N']

  const marketPrograms = [...(new Set(data.map((item) => item.market_program_name as string)) as Set<string>)].sort(
    (a, b) => order.indexOf(a) - order.indexOf(b),
  )

  marketPrograms.forEach((market_program_name) => {
    const subset = data.filter((row) => row.market_program_name === market_program_name)

    const rawData = convertDataTo2DArray(subset)

    datasets.push(
      {
        id: `source_${market_program_name}`,
        source: rawData,
      },
      {
        id: `boxplot_${market_program_name}`,
        fromDatasetId: `source_${market_program_name}`,
        type: 'boxplot',
        transform: { type: 'boxplot' },
      },
    )

    series.push({
      name: market_program_name,
      type: 'boxplot',
      datasetId: `boxplot_${market_program_name}`,
      encode: {
        y: ['Low', 'Q1', 'Q2', 'Q3', 'High'],
        itemName: market_program_name,
        tooltip: ['Low', 'Q1', 'Q2', 'Q3', 'High'],
      },
    })
  })

  return { datasets, series }
}

import { Box, CircularProgress, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomButton from '@/components/inputs/CustomButton'
import { TOMORROW } from '@/constants/dateTime'
import SpotOnEboilerDisplayedTimezone from '@/features/resource/components/SpotOnEboilerDisplayedTimezone'
import { useSpotOnEboilerScheduleProgram } from '@/features/resource/contexts/SpotOnEboilerScheduleProgramContext'
import { useSpotOnScheduleQuery } from '@/features/resource/hooks/useSpotOnScheduleQuery'
import type { ApiScheduledResource, Schedule } from '@/features/resource/types/spotOnForEboilers'

type SpotOnEboilerScheduleFormProps = {
  scheduledResource: ApiScheduledResource | null
}

function SpotOnEboilerScheduleForm({ scheduledResource }: Readonly<SpotOnEboilerScheduleFormProps>) {
  const { i18n, t } = useTranslation()

  const resourceId = scheduledResource?.resourceId ?? ''
  const { activeDay } = useParams()
  const { spotOnScheduleProgram } = useSpotOnEboilerScheduleProgram()

  const { isLoading, schedule } = useSpotOnScheduleQuery(
    { activeDay: activeDay!, resourceId },
    { enabled: Boolean(scheduledResource) },
  )

  const form = useForm<Schedule>()

  useEffect(() => {
    if (schedule) {
      form.setValue('periods', schedule.periods)
    }
  }, [schedule])

  const timeZone = spotOnScheduleProgram?.timeZone ?? 'Europe/Helsinki'

  async function handleSubmit() {
    // eslint-disable-next-line no-console
    console.log('submit')
  }

  const rows = form.watch('periods')
  const sortedRows = rows
    ? rows.toSorted(
        (a, b) =>
          DateTime.fromISO(a.startAt, { zone: timeZone }).toMillis() -
          DateTime.fromISO(b.startAt, { zone: timeZone }).toMillis(),
      )
    : []

  const columns: GridColDef[] = [
    {
      field: 'startAt',
      headerName: 'PTU',
      flex: 1,
      valueFormatter: (value: string) => {
        const startAt = DateTime.fromISO(value, { zone: timeZone }).toFormat('HH:mm')
        const endAt = DateTime.fromISO(value, { zone: timeZone }).plus({ hours: 1 }).toFormat('HH:mm')

        return `${startAt} - ${endAt}`
      },
    },
    {
      field: 'cost',
      headerName: t('spot_on_for_eboilers.schedule_create.table.cost'),
      flex: 1,
      valueFormatter: (value: string) => (value ? parseFloat(value).toFixed(2) : ''),
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <ToggleButtonGroup color="primary" size="small" value={params.row.targetConsumptionInWatts ? 'on' : 'auto'}>
          <ToggleButton
            key="on"
            value="on"
            // onClick={() => {}}
          >
            {t('common.status_on')}
          </ToggleButton>
          <ToggleButton
            key="auto"
            value="auto"
            // onClick={() => {}}
          >
            {t('common.status_auto')}
          </ToggleButton>
        </ToggleButtonGroup>
      ),
    },
  ]

  if (!scheduledResource || isLoading) {
    return (
      <Box alignItems="center" display="flex" height="200px" justifyContent="center">
        <CircularProgress title="Loading" />
      </Box>
    )
  }

  return (
    <Box paddingTop={2}>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
          <Stack gap={1}>
            <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <DatePicker
                  disabled={true}
                  format="DD"
                  label={t('spot_on_for_eboilers.schedule_create.active_day')}
                  value={activeDay ? DateTime.fromISO(activeDay) : TOMORROW}
                />
              </LocalizationProvider>
              <SpotOnEboilerDisplayedTimezone />
            </Stack>

            <CustomDataGrid
              disableColumnMenu
              disableColumnSorting
              hideFooter
              hideFooterPagination
              hideFooterRowCount
              aria-label={t('spot_on_for_eboilers.schedule_create.table_title')}
              columns={columns}
              getRowHeight={() => 52}
              getRowId={(row) => row.startAt}
              includeWrapper={false}
              rows={sortedRows}
            />
          </Stack>

          <Stack direction="row" marginTop={4}>
            <CustomButton type="submit" variant="contained">
              {/* <CustomButton disabled={isPending} type="submit" variant="contained"> */}
              {t('common.button.save')}
            </CustomButton>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  )
}

export default SpotOnEboilerScheduleForm

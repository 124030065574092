import { Stack } from '@mui/material'
import type { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import DataGridEditableTextField from '@/components/dataDisplay/DataGridEditableTextField'
import CustomButton from '@/components/inputs/CustomButton'
import SpotOnEboilerDisplayedTimezone from '@/features/resource/components/SpotOnEboilerDisplayedTimezone'
import { DAY_NAMES, DEFAULT_EXTRA_CRITERIA_WEEKDAYS } from '@/features/resource/constants/spotOnForBoilers'
import type { ExtraCriteria, ExtraCriteriaWeekday } from '@/features/resource/types/spotOnForEboilers'
import { transformDataForDataGrid } from '@/features/resource/utils/spotOnForEboilers'

type SpotOnEboilerNetworkChargesFormProps = {
  customerExtraCriteria: ExtraCriteria[]
}

function SpotOnEboilerNetworkChargesForm({ customerExtraCriteria }: SpotOnEboilerNetworkChargesFormProps) {
  const { t } = useTranslation()
  const networkChargesExtraCriteria = customerExtraCriteria.find((criteria) => criteria.type === 'cost')
  const form = useForm<any>({
    defaultValues: {
      extraCriteria: networkChargesExtraCriteria?.weekdays ?? DEFAULT_EXTRA_CRITERIA_WEEKDAYS,
    },
    // TODO: Add a zodResolver with the proper schema
  })

  const handleExtraCriteriaWeekdayChange =
    (params: GridRenderEditCellParams<ExtraCriteriaWeekday>) => (value: string) => {
      // An integer or a float number with up to two decimals
      const isValidPrice = /^\d+\.?\d{0,2}$/.test(value)
      if (value !== '' && !isValidPrice) return

      const { id, field } = params
      const currentData = form.getValues('extraCriteria')

      // Update the specific cell value
      const updatedData = currentData.map((row) => {
        const idxAsInt = parseInt(field)
        if (row.dayNumber === idxAsInt) {
          row.periods[id].value = value
        }

        return row
      })

      // Update form state
      form.setValue('extraCriteria', updatedData, { shouldDirty: true, shouldValidate: true })

      // Inform DataGrid of the change
      params.api.setEditCellValue({ id, field, value })
    }

  const extraCriteria = form.watch('extraCriteria')

  const rows = transformDataForDataGrid(extraCriteria)

  const columns: GridColDef[] = [
    { field: 'ptu', headerName: 'PTU', flex: 1 },
    ...extraCriteria.map((nc) => ({
      field: `${nc.dayNumber}`,
      headerName: DAY_NAMES[nc.dayNumber - 1], // The array of DAY_NAMES is 0-based, so -1 because dayOfWeek starts at 1 (Monday)
      flex: 1,
      editable: true,
      renderCell: (params) => <DataGridEditableTextField readonlyField value={params.formattedValue} />,
      renderEditCell: (params) => (
        <DataGridEditableTextField
          selectValueOnFocus
          value={params.value}
          onBlur={() => {
            params.api.stopCellEditMode({ id: params.id, field: params.field })
          }}
          onChange={handleExtraCriteriaWeekdayChange(params)}
        />
      ),
    })),
  ]

  return (
    <FormProvider {...form}>
      <Stack gap={1} marginBottom={2} marginTop={5}>
        <Stack flexDirection="row" justifyContent="space-between">
          <SpotOnEboilerDisplayedTimezone />
        </Stack>

        <CustomDataGrid
          disableColumnMenu
          disableColumnSorting
          hideFooter
          hideFooterPagination
          hideFooterRowCount
          aria-label={t('spot_on_for_eboilers.configuration_form.network_charges.table_title')}
          columns={columns}
          getRowHeight={() => 52}
          includeWrapper={false}
          rows={rows}
        />
      </Stack>
      {/* TODO: Include the disabled state */}
      <CustomButton type="submit" variant="contained">
        {t('common.button.save')}
      </CustomButton>
    </FormProvider>
  )
}

export default SpotOnEboilerNetworkChargesForm

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Box, Divider, Pagination, Stack } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { ContactCard } from '@/features/customer/components/companyInfo/ContactCard'
import { useInvalidateCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import type { Customer, CustomerContact } from '@/features/customer/types/customer'
import SearchInput from '@/features/search/components/Search'

interface CustomerContactsProps {
  customer?: Customer
  viewOnly?: boolean
}

const CONTACT_PAGE_SIZE = 5

function sortByDateCreatedDescendingComparator() {
  return (a: CustomerContact, b: CustomerContact) => {
    if (a.createdAt && b.createdAt) {
      return a.createdAt > b.createdAt ? -1 : 1
    }
    return 0
  }
}

interface SectionTitleProps {
  onAddClick: any
  viewOnly?: boolean
}
export const SectionTitle = ({ onAddClick, viewOnly }: SectionTitleProps) => (
  <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
    <CustomTypography fontSize={20} variant="h4">
      {t('customer_details.tabs.contact_info.contacts.title')}
    </CustomTypography>
    {!viewOnly && (
      <CustomIconButton
        Icon={AddOutlinedIcon}
        aria-label={'Create contact'}
        color="primary"
        size="small"
        variant={'solid'}
        onClick={onAddClick}
      />
    )}
  </Stack>
)

export const CustomerContacts = (props: CustomerContactsProps) => {
  const invalidateCustomerQuery = useInvalidateCustomerQuery()

  const [filteredContacts, setFilteredContacts] = useState<CustomerContact[]>([])
  const [contactsToShow, setContactsToShow] = useState<CustomerContact[]>([])
  const [currentPageIndex, setCurrentPageIndex] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [isCreateMode, setIsCreateMode] = useState(false)
  const [searchText, setSearchText] = useState('')

  // Set all contacts on customer change
  useEffect(() => {
    filterContacts(searchText)
  }, [props.customer])

  // Update number of pages when filtered contacts change
  useEffect(() => {
    setNumberOfPages(Math.ceil(filteredContacts?.length / CONTACT_PAGE_SIZE))
  }, [filteredContacts])

  useEffect(() => {
    const contacts = filteredContacts?.slice(
      (currentPageIndex - 1) * CONTACT_PAGE_SIZE,
      currentPageIndex * CONTACT_PAGE_SIZE,
    )
    setContactsToShow(contacts)
  }, [currentPageIndex, filteredContacts])

  const searchContacts = (searchText: string) => {
    setSearchText(searchText)
    filterContacts(searchText)
  }

  const filterContacts = (searchText: string) => {
    if (!searchText) {
      setFilteredContacts(props.customer?.contacts?.sort(sortByDateCreatedDescendingComparator()) || [])
      setCurrentPageIndex(1)
      return
    }
    const filteredContacts = props.customer?.contacts?.filter((contact) => {
      return (
        contact.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        contact.email?.toLowerCase().includes(searchText.toLowerCase()) ||
        contact.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
        contact.comment?.toLowerCase().includes(searchText.toLowerCase())
      )
    })
    setFilteredContacts(filteredContacts?.sort(sortByDateCreatedDescendingComparator()) || [])
    setCurrentPageIndex(1)
  }

  function showContactForm(customerUuid?: string, location?: string) {
    if (!customerUuid || !location) {
      return
    }

    if (!isCreateMode) return null

    return (
      <Box>
        <ContactCard
          customerUuid={customerUuid}
          isFormMode={true}
          location={location}
          onCancel={() => {
            setIsCreateMode(false)
          }}
          onChange={() => {
            setIsCreateMode(false)
            setSearchText('')
            invalidateCustomerQuery({ uuid: customerUuid })
          }}
        />
        <Divider sx={{ marginTop: 2 }} />
      </Box>
    )
  }

  if (!props.customer?.contacts?.length) {
    return (
      <>
        <SectionTitle viewOnly={props.viewOnly} onAddClick={() => setIsCreateMode(!isCreateMode)} />
        <CustomTypography variant="body1">
          {t('customer_details.tabs.contact_info.contacts.no_contacts')}
        </CustomTypography>
        {showContactForm(props.customer?.uuid, props.customer?.location)}
      </>
    )
  }

  return (
    <Stack alignItems={'stretch'} direction={'column'} justifyContent={'space-between'} minHeight={'100%'} spacing={2}>
      <Stack gap={2}>
        <SectionTitle viewOnly={props.viewOnly} onAddClick={() => setIsCreateMode(!isCreateMode)} />
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <SearchInput search={searchContacts} searchText={searchText} />
        </Box>
        <Divider />
        {showContactForm(props.customer?.uuid, props.customer?.location)}
        {!contactsToShow.length ? (
          <CustomTypography variant="body1">
            {t('customer_details.tabs.contact_info.contacts.no_contacts_found')}
          </CustomTypography>
        ) : (
          contactsToShow?.map((contact) => (
            <Box key={contact.id}>
              <ContactCard
                contact={contact}
                customerUuid={props.customer?.uuid}
                location={props.customer?.location}
                viewOnly={props.viewOnly}
                onChange={() => invalidateCustomerQuery({ uuid: props.customer!.uuid! })}
              />
              <Divider sx={{ marginTop: 2 }} />
            </Box>
          ))
        )}
      </Stack>

      {/* If number of pages is less than 1, hide*/}
      {numberOfPages > 1 && (
        <Stack alignItems={'center'} mt={2} spacing={2}>
          <Pagination
            count={numberOfPages}
            page={currentPageIndex}
            onChange={(event, page) => {
              setCurrentPageIndex(page)
              setContactsToShow(
                filteredContacts?.slice(
                  (page - 1) * CONTACT_PAGE_SIZE,
                  (page - 1) * CONTACT_PAGE_SIZE + CONTACT_PAGE_SIZE,
                ),
              )
            }}
          />
        </Stack>
      )}
    </Stack>
  )
}

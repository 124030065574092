import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import type { ChipProps } from '@mui/material/Chip'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

import type { ActivationGroupState } from '@/features/activationGroup/types'

type ActivationGroupStateChipProps = {
  state: ActivationGroupState | 'UNKNOWN'
}

const COMMON_PROPERTIES: Partial<ChipProps> = {
  color: 'primary',
  sx: { textTransform: 'uppercase' },
  variant: 'outlined',
}

/**
 * Displays the current state as a chip. If state is LIVE, a checkmark icon is displayed.
 */
const ActivationGroupStateChip = ({ state }: ActivationGroupStateChipProps) => {
  const { t } = useTranslation()

  if (state === 'LIVE') {
    return (
      <Chip
        {...COMMON_PROPERTIES}
        icon={<CheckCircleOutlinedIcon titleAccess={t('component.activation_group_state_chip.live_icon')} />}
        label={t('component.activation_group_state_chip.live')}
      />
    )
  }

  if (state === 'NOT_LIVE') {
    return <Chip {...COMMON_PROPERTIES} label={t('component.activation_group_state_chip.deactivated')} />
  }

  return <Chip {...COMMON_PROPERTIES} label={t('component.activation_group_state_chip.unknown')} />
}

export default ActivationGroupStateChip

import { Stack } from '@mui/material'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import CustomAccordion from '@/components/layouts/CustomAccordion'

type UserAccountAccordionProps = {
  children: ReactNode
}

const UserRightsAccordion = ({ children }: UserAccountAccordionProps) => {
  const { t } = useTranslation()

  return (
    <CustomAccordion defaultExpanded title={t('user_form.form.title_user_rights')}>
      <Stack direction="column" gap={3}>
        {children}
      </Stack>
    </CustomAccordion>
  )
}

export default UserRightsAccordion

import type { FeatureToggles } from '@/features/featureToggle/types/featureToggle'

export const FEATURE_TOGGLES: FeatureToggles = {
  toggles: [
    {
      name: 'TEST_TOGGLE_FOR_FRONTEND',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_CUSTOMERS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_PARTNERS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_BIDDING_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_BIDDING_SUBMIT_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_REPORTS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_RESOURCES_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_RESOURCE_SCHEDULER_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_MEASUREMENTS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_ACTIVATION_GROUPS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_DISTURBANCES_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_INTEGRATIONS_PAGE_ENABLED',
      enabled: true,
    },
    {
      name: 'CUSTOMER_USER_LOGIN_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_EXAMPLE_FEATURE_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_BESS_DASHBOARD_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_CUSTOMER_POWER_METRICS_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_MFA_TOTP_ENABLED',
      enabled: true,
    },
    {
      name: 'RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS',
      enabled: true,
      contexts: {
        userId: ['b138c9f3-8de4-4a1d-b8eb-3f2a1dcba8e9'],
      },
    },
    {
      name: 'RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES',
      enabled: true,
      contexts: {
        countryCode: ['gr'],
      },
    },
    {
      name: 'FLEXPORTAL_SPOT_ON_EBOILER_ENABLED',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_USE_ACTIVATIONS_BFF',
      enabled: true,
    },
    {
      name: 'FLEXPORTAL_USE_PORTFOLIOS_FROM_ACTIVATIONS_BFF',
      enabled: false,
    },
  ].map((feature) => ({
    ...feature,
    impressionData: false,
  })),
}

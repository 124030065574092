import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_INSIGHT_PORTFOLIOS_API, getInsightPortfolios } from '@/features/report/endpoints/portfolios'
import type { UseQueryOptions } from '@/types/queries'

export type UseInsightPortfoliosQueryResult = Omit<UseQueryResult, 'data'> & {
  portfolios: string[] | undefined
}

export function useInsightPortfoliosQuery(options?: UseQueryOptions<string[]>): UseInsightPortfoliosQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_INSIGHT_PORTFOLIOS_API, {}],
    queryFn: () => getInsightPortfolios(),
  })

  return { portfolios: data as string[] | undefined, ...queryResult }
}

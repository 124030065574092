import { FormControl } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import type { ComponentProps } from 'react'

type FormControlProps = ComponentProps<typeof FormControl>
type CustomFromControlProps = Omit<FormControlProps, 'variant'> & {
  variant?: FormControlProps['variant'] | 'outlinedWhite'
}

/**
 * It modifies the MUI FormControl component to add some Sympower customizations:
 *
 * - Adds a new 'outlinedWhite' variant.
 *
 * It is mainly used on the AppBar. If you need to add an input field inside the app bar you should wrap it with this
 * component.
 */
const CustomFormControl = ({ variant, sx, ...props }: CustomFromControlProps) => {
  const theme = useTheme()

  if (variant === 'outlinedWhite') {
    return (
      <FormControl
        sx={{
          ...sx,
          /* It forces icons inside the form to use white color */
          '& .MuiInputBase-root *': {
            color: theme.palette.common.white,
          },

          /* Common styles */

          // Change the background color on focus and on hover when the input is not disabled
          '& .MuiInputBase-root:not(.Mui-disabled):is(.Mui-focused), .MuiInputBase-root:not(.Mui-disabled):hover': {
            background: 'rgba(255, 255, 255, 0.12)',
          },

          '& .MuiInputBase-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            border: `2px solid rgba(255, 255, 255, 0.75)`,
          },

          '& .MuiInputBase-root:is(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            border: `2px solid white`,
          },

          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            color: 'rgba(255, 255, 255, 0.60)',
          },

          '& .MuiInputLabel-root:is(.MuiInputLabel-shrink)': {
            color: `${theme.palette.common.white}`,
          },

          /* Disable styles */
          '& .MuiInputBase-root:is(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            border: `2px solid rgba(255, 255, 255, 0.38)`,
          },

          '& .MuiInputLabel-root:is(.Mui-disabled):not(.MuiInputLabel-shrink)': {
            color: `rgba(255, 255, 255, 0.38)`,
          },

          '& .MuiAutocomplete-input:is(.Mui-disabled)': {
            color: `rgba(255, 255, 255, 0.38)`,
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.38)',
          },

          '& .MuiSelect-select:is(.Mui-disabled)': {
            color: `rgba(255, 255, 255, 0.38)`,
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.38)',
          },

          /* Error styles */
          '& .MuiInputBase-root:is(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${theme.palette.error.main}`,
          },
        }}
        {...props}
        variant="outlined"
      />
    )
  }

  return <FormControl {...props} />
}

export default CustomFormControl

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import environment from '@/environment'
import { DEFAULT_WHITELABEL_CONFIG } from '@/features/whitelabel/default'
import type { GetWhitelabelConfigurationResponse } from '@/features/whitelabel/endpoints/whitelabelConfigurations'
import {
  getProtectedWhitelabelConfiguration,
  getPublicWhitelabelConfiguration,
} from '@/features/whitelabel/endpoints/whitelabelConfigurations'
import type { WhitelabelConfiguration } from '@/features/whitelabel/types/whitelabelConfiguration'
import type { UseQueryOptions } from '@/types/queries'
import { decodeBase64ToUrl } from '@/utils/base64'

const API_ID = 'GET_WHITELABEL_CONFIGURATION_API_ID'

type WhitelabelConfigurationQueryParams = {
  hostname: string
  isAuthenticated?: boolean
}

export type UseWhitelabelConfigurationQueryResult = Omit<UseQueryResult, 'data'> & {
  whitelabelConfiguration: WhitelabelConfiguration | null
}

export function useWhitelabelConfigurationQuery(
  params: WhitelabelConfigurationQueryParams,
  options?: UseQueryOptions<WhitelabelConfiguration>,
): UseWhitelabelConfigurationQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [API_ID, params],
    queryFn: async () => {
      if (environment.useDefaultWhitelabelConfig) return DEFAULT_WHITELABEL_CONFIG

      try {
        const response = params.isAuthenticated
          ? await getProtectedWhitelabelConfiguration(params.hostname)
          : await getPublicWhitelabelConfiguration(params.hostname)

        return convertWhitelabelResponse(response)
      } catch {
        return DEFAULT_WHITELABEL_CONFIG
      }
    },
  })

  return { whitelabelConfiguration: data, ...queryResult }
}

async function convertWhitelabelResponse(
  response: GetWhitelabelConfigurationResponse,
): Promise<WhitelabelConfiguration> {
  const { publicConfig, protectedConfig } = response

  return {
    ...publicConfig,
    branding: {
      ...publicConfig.branding,
      coloredLogo: await decodeBase64ToUrl(publicConfig.branding.coloredLogo),
      monochromeLogo: await decodeBase64ToUrl(publicConfig.branding.monochromeLogo),
      favicon: await decodeBase64ToUrl(publicConfig.branding.favicon),
    },
    ...protectedConfig,
  }
}

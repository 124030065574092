import { TextField } from '@mui/material'
import type { TextFieldProps } from '@mui/material/TextField/TextField'
import type { ForwardedRef } from 'react'
import { forwardRef, useEffect, useState } from 'react'

export type FloatTextFieldProps = {
  textFieldProps?: TextFieldProps
  initialValue?: string
  onValueChange: (value: number | undefined) => void
  min?: number
  max?: number
  numberOfDecimals?: number
  error?: boolean
}

/**
 * Renders a float text field component.
 *
 * You can specify the minimum (inclusive), maximum (inclusive), and max number of decimals (defaults to 1 if not specified).
 * Whenever the value changes, and is a valid number meeting the requirements, `onValueChange` will be called.
 *
 * Anything inside `textFieldProps` will be passed to the MUI TextField
 *
 * @param {FloatTextFieldProps} props - The props for the float text field.
 * @return {ReactNode} The float text field component.
 */
const FloatTextField = (
  { initialValue, error, ...props }: FloatTextFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const [stringValue, setStringValue] = useState<string | undefined>(initialValue)
  const [isInvalidValue, setIsInvalidValue] = useState<boolean>(false)
  const [floatValue, setFloatValue] = useState<number | undefined>()
  const validationRegex = new RegExp('^\\-?\\d*\\.?\\d{0,' + (props.numberOfDecimals ?? 2) + '}$')

  useEffect(() => {
    props.onValueChange(floatValue)
  }, [floatValue])

  useEffect(() => {
    validateAndUpdateFloatValue(stringValue)
  }, [stringValue])

  const handleOnChange = (event: { target: { value: any } }) => {
    const value = event.target.value
    const isValidNumber = validationRegex.test(value)

    if (isValidNumber) {
      setStringValue(value)
    }
  }

  const handleOnBlur = () => {
    const parsedFloat = validateAndUpdateFloatValue(stringValue)
    setStringValue(parsedFloat?.toString())
  }

  const meetsMinimum = (value: number) => {
    if (props.min == undefined) {
      return true
    }
    return value >= props.min
  }

  const meetsMaximum = (value: number) => {
    if (props.max == undefined) {
      return true
    }
    return value <= props.max
  }

  const validateAndUpdateFloatValue = (value?: string): number | undefined => {
    if (value == undefined) {
      setIsInvalidValue(false)
      setFloatValue(undefined)
      return undefined
    }

    const parsedToFloat = parseFloat(value)
    if (isNaN(parsedToFloat)) {
      setIsInvalidValue(true)
      setFloatValue(undefined)
      return undefined
    }

    if (!meetsMinimum(parsedToFloat)) {
      setIsInvalidValue(true)
      setFloatValue(props.min)
      return props.min
    }

    if (!meetsMaximum(parsedToFloat)) {
      setIsInvalidValue(true)
      setFloatValue(props.max)
      return props.max
    }

    setIsInvalidValue(false)
    setFloatValue(parsedToFloat)
    return parsedToFloat
  }

  return (
    <TextField
      {...props.textFieldProps}
      error={error || isInvalidValue}
      inputProps={{ ref, ...props.textFieldProps?.inputProps }}
      value={stringValue ?? ''}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
    />
  )
}

export default forwardRef(FloatTextField)

import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { useIntegrationCustomersQuery } from '@/features/integration/hooks/useIntegrationCustomersQuery'
import type { Customer } from '@/features/integration/types'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

import CustomerIntegrationsCell from './CustomerIntegrationsCell'
import IntegrationStatusChip from './IntegrationStatusChip'

type CustomerRenderCell = GridRenderCellParams<Customer>

function CustomersGrid() {
  const { t } = useTranslation()
  const [paginationModel, setPaginationModel] = useState(DEFAULT_DATA_GRID_PAGINATION_MODEL)
  const { customers, isLoading, totalCount } = useIntegrationCustomersQuery({ pagination: paginationModel })

  const columns: GridColDef<Customer>[] = [
    {
      field: 'id',
      headerName: t('common.id'),
      flex: 1,
    },
    {
      field: 'name',
      headerName: t('common.customer'),
      flex: 1,
    },
    {
      field: 'siteName',
      headerName: t('common.site'),
      flex: 1,
    },
    {
      field: 'partnerName',
      headerName: t('common.partner'),
      flex: 1,
    },
    {
      field: 'currentPowerConsumption',
      headerName: t('integrations.customers.table.header.current_power_consumption'),
      flex: 1,
      valueFormatter: (value: Customer['currentPowerConsumption']) => convertEnergyPower(value, 'megawatts'),
    },
    {
      field: 'verifiedCapacity',
      headerName: t('integrations.customers.table.header.verified_capacity'),
      flex: 1,
      valueFormatter: (value: Customer['verifiedCapacity']) => convertEnergyPower(value, 'megawatts'),
    },
    {
      field: 'integrations',
      headerName: t('common.integrations'),
      flex: 2,
      renderCell: ({ row: { integrations } }: CustomerRenderCell) => (
        <CustomerIntegrationsCell integrations={integrations} />
      ),
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      renderCell: ({ row: { integrationErrors, status } }: CustomerRenderCell) => (
        <IntegrationStatusChip hasErrors={integrationErrors.length > 0} status={status} />
      ),
    },
  ]

  return (
    <CustomDataGrid
      disableColumnMenu
      disableColumnSorting
      aria-label={t('common.customers')}
      columns={columns}
      getRowId={(row) => row.id}
      isLoading={isLoading}
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount}
      rows={customers}
      title={t('common.customers')}
      onPaginationModelChange={setPaginationModel}
    />
  )
}

export default CustomersGrid

import { Stack } from '@mui/material'
import Paper from '@mui/material/Paper'
import type { GridCellModesModel, GridCellParams, GridRowParams } from '@mui/x-data-grid'
import { GridCellModes, GridRow } from '@mui/x-data-grid'
import type { DataGridProProps } from '@mui/x-data-grid-pro'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import DataGridSkeleton from '@/components/dataDisplay/DataGridSkeleton'
import { DEFAULT_DATA_GRID_PAGE_SIZE, DEFAULT_DATA_GRID_PAGE_SIZE_OPTIONS } from '@/constants/datagrid'
import { useDataGridSyncUrlManager } from '@/contexts/DataGridSyncUrlManagerContext'
import { shouldShowPagination } from '@/utils/datagrid/pagination'

export interface CustomDataGridProps
  extends Omit<DataGridProProps, 'onRowClick' | 'rowSelection' | 'rowSelectionModel' | 'loading'> {
  includeWrapper?: boolean
  isLoading?: boolean
  clickableRows?: {
    isRowClickable?: (row: GridRowParams) => boolean
    navigateTo?: (row: GridRowParams) => string
    isRowSelectable?: (row: GridRowParams) => boolean
    onRowClick?: DataGridProProps['onRowClick']
    onRowSelectionModelChange?: DataGridProProps['onRowSelectionModelChange']
  }
  pageSize?: number
  rowSelection?: {
    multiple: boolean
    model: DataGridProProps['rowSelectionModel']
  }
  disableGutterTop?: boolean
  disableGutterBottom?: boolean
  disableDefaultHeaderBackground?: boolean
  title?: string
  // This element will be displayed on the right side of the title.
  titleExtraInfo?: JSX.Element
}

export const DEFAULT_FILTERS = {
  filter: {
    filterModel: {
      items: [],
      quickFilterValues: [],
    },
  },
}

const CustomDataGrid = ({
  includeWrapper = true,
  isLoading = false,
  slots,
  initialState,
  clickableRows,
  pageSizeOptions,
  sx,
  rowSelection,
  disableGutterBottom = false,
  disableGutterTop = false,
  disableDefaultHeaderBackground = false,
  title,
  titleExtraInfo,
  ...dataGridProps
}: CustomDataGridProps) => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})
  const dataGridSyncUrlManager = useDataGridSyncUrlManager()
  // Default data grid props can be overridden by passing props to CustomDataGrid
  const defaultDataGridProps: Partial<DataGridProProps> = {
    autoHeight: true,
    hideFooterSelectedRowCount: true,
    rowSelection: false,
    density: 'standard',
  }
  const canSelectMultipleRows = rowSelection?.multiple ?? false
  const commonStyles = {
    p: 3,
    mb: disableGutterBottom ? 0 : 3,
    mt: disableGutterTop ? 0 : 3,
  }

  function isRowClickable(params: GridRowParams) {
    if (!clickableRows) return false

    return clickableRows?.isRowClickable ? clickableRows.isRowClickable(params) : true
  }

  function isRowSelectable(params: GridRowParams) {
    if (!clickableRows) return false

    return clickableRows?.isRowSelectable ? clickableRows.isRowSelectable(params) : true
  }

  /**
   * the combination of handleCellClick and handleCellModesModelChange makes possible to open edit mode of a cell
   * with just one click. the default behaviour of the DataGrid is to open edit mode only after double click.
   */
  function handleCellClick(params: GridCellParams) {
    if (!params.colDef.editable) return

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      }
    })
  }

  function handleCellModesModelChange(newModel: GridCellModesModel) {
    setCellModesModel(newModel)
  }

  if (isLoading) {
    return (
      <DataGridSkeleton
        includeWrapper={includeWrapper}
        numberOfColumns={dataGridProps.columns?.length ?? 0}
        numberOfRows={DEFAULT_DATA_GRID_PAGE_SIZE}
        sx={commonStyles}
        withToolbar={Boolean(slots?.toolbar)}
      />
    )
  }

  const rowSlot = clickableRows?.navigateTo
    ? {
        row: (params) => (
          <Link to={clickableRows.navigateTo!(params)}>
            <GridRow {...params} />
          </Link>
        ),
      }
    : {}

  // Set pagination initial state if paginationModel is not provided
  const paginationInitialState = !dataGridProps.paginationModel
    ? { pagination: { paginationModel: { pageSize: DEFAULT_DATA_GRID_PAGE_SIZE } } }
    : {}

  const Content = (
    <>
      <Stack sx={{ flexDirection: 'row', gap: 2, justifyContent: 'space-between' }}>
        {title && (
          <CustomTypography gutterBottom variant="h5">
            {title}
          </CustomTypography>
        )}
        {titleExtraInfo}
      </Stack>

      <DataGridPro
        {...defaultDataGridProps}
        {...dataGridProps}
        disableColumnFilter
        disableColumnMenu
        cellModesModel={cellModesModel}
        getRowClassName={(params) => (isRowClickable(params) ? 'clickable' : 'non-clickable')}
        initialState={{
          ...paginationInitialState,
          ...initialState,
        }}
        isRowSelectable={(params) => isRowSelectable(params) || false}
        loading={isLoading}
        pageSizeOptions={pageSizeOptions ?? DEFAULT_DATA_GRID_PAGE_SIZE_OPTIONS}
        pagination={shouldShowPagination({ dataGridProps, initialState })}
        rowSelection={Boolean(rowSelection)}
        rowSelectionModel={rowSelection?.model}
        slots={{
          ...slots,
          ...rowSlot,
        }}
        sx={{
          ...sx,
          border: 'none',
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none !important;',
          },
          '& .MuiDataGrid-row.clickable:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(20, 132, 160, 0.04)',
          },
          '& .MuiDataGrid-row.non-clickable': {
            cursor: 'default',
            backgroundColor: 'inherit',
          },
          '& .MuiDataGrid-row--detailPanelExpanded': {
            background: 'rgba(20, 132, 160, 0.08)',
          },
          '& .MuiDataGrid-columnHeader': {
            background: disableDefaultHeaderBackground ? '' : 'white',
          },
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
            display: canSelectMultipleRows ? null : 'none',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            alignItems: 'center',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            display: 'flex',
            padding: '0px 10px',
          },
          '& .MuiDataGrid-detailPanel': {
            background: 'rgba(20, 132, 160, 0.04)',
          },
          '& .MuiDataGrid-filler': {
            backgroundColor: 'white',
          },
        }}
        onCellClick={handleCellClick}
        onCellModesModelChange={handleCellModesModelChange}
        onFilterModelChange={(filterModel, ...args) => {
          dataGridSyncUrlManager.syncFilterSearchParams(filterModel)
          dataGridProps.onFilterModelChange?.(filterModel, ...args)

          // When pagination is enabled, reset the page to 0 when filters change
          if (!dataGridProps.hideFooterPagination) {
            const { api } = args[0]

            api.setPage(0)
          }
        }}
        onPaginationModelChange={(paginationModel, ...args) => {
          dataGridSyncUrlManager.syncPaginationSearchParams(paginationModel)
          dataGridProps.onPaginationModelChange?.(paginationModel, ...args)
        }}
        onRowClick={(params, ...rest) => {
          if (!clickableRows || !isRowClickable(params) || !clickableRows.onRowClick) return

          clickableRows.onRowClick(params, ...rest)
        }}
        onRowSelectionModelChange={(selectedModels, ...rest) => {
          if (!clickableRows?.onRowSelectionModelChange) return

          function getLastModel() {
            return selectedModels.length > 0 ? [selectedModels[selectedModels.length - 1]] : []
          }

          // If multiple selection is not allowed, only the last selected model will be passed to the callback
          clickableRows.onRowSelectionModelChange(canSelectMultipleRows ? selectedModels : getLastModel(), ...rest)
        }}
        onSortModelChange={(sortModel, ...args) => {
          dataGridSyncUrlManager.syncSortSearchParams(sortModel)
          dataGridProps.onSortModelChange?.(sortModel, ...args)
        }}
      />
    </>
  )

  if (includeWrapper) {
    return <Paper sx={commonStyles}>{Content}</Paper>
  }

  return Content
}

export default CustomDataGrid

import { IconChevronLeft, IconChevronRight } from '@aws-amplify/ui-react/internal'
import { Box } from '@mui/material'
import type { TFunction } from 'i18next'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { ActivationGroupState } from '@/features/activationGroup/types'
import BidOfferCapacityTabs from '@/features/bidding/components/capacity/offer/BidOfferTabs'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import CreateBidButton from '@/features/bidding/components/CreateBidButton'
import ExternalReferenceButtons from '@/features/bidding/components/ExternalReferenceButtons'
import SubmitButton from '@/features/bidding/components/SubmitButton'
import { ALL_RESULTS } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useBidHistoriesPageQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import { usePortfoliosQuery } from '@/features/bidding/hooks/usePortfoliosQuery'
import type { Portfolio } from '@/features/bidding/types/bid'
import getBiddingFlow, { BiddingFlow } from '@/features/bidding/utils/getBiddingFlow'
import { getLatestBid } from '@/features/bidding/utils/getLatestBid'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import {
  buildBidConfirmationLink,
  buildBidPriceLink,
} from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'
import { anyBidOutOfBiddableVolumeRange } from '@/features/bidding/utils/validation/anyBidOutOfBiddableVolumeRange'

const BidOfferCapacity = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { selectedCountry } = useBiddingContext()
  const groupParams = getGroupedBidParamsFromUrl(location)

  const { portfolios } = usePortfoliosQuery({ countryCode: selectedCountry })
  const { activationGroups } = useActivationGroupsQuery({ countryCode: selectedCountry })

  const { bidHistoriesPage } = useBidHistoriesPageQuery(
    {
      pagination: { pageSize: ALL_RESULTS, page: 0 },
      sorting: [],
      filter: {
        items: groupParams
          ? [
              { field: 'portfolio', operator: 'equals', value: groupParams.portfolioCode },
              { field: 'marketProgram', operator: 'equals', value: groupParams.marketProgram },
              { field: 'deliveryDay', operator: 'equals', value: groupParams.deliveryDay },
            ]
          : [],
      },
      countryFilter: selectedCountry,
    },
    { enabled: !!groupParams },
  )

  useEffect(() => {
    if (!groupParams) navigate('/bidding', { replace: true })
  }, [groupParams, navigate])

  if (!bidHistoriesPage || !groupParams || !portfolios || !activationGroups) {
    return <FullPageSpinner />
  }

  const bidHistories = bidHistoriesPage.bidHistories
  const bidActivationGroups = activationGroups
    .filter(
      (activationGroup) =>
        activationGroup.marketProgram === groupParams.marketProgram &&
        activationGroup.portfolio.code === groupParams.portfolioCode &&
        activationGroup.state === ActivationGroupState.LIVE,
    )
    .toSorted((a, b) => a.code.localeCompare(b.code))
  const latestBids = bidHistories.map((bidHistory) => getLatestBid(bidHistory.bids))
  const portfolio = portfolios.find((portfolio: Portfolio) => portfolio.code === groupParams.portfolioCode)!
  const biddingFlow = getBiddingFlow(portfolio)

  return (
    <>
      <BidOfferHeader groupParams={groupParams} portfolio={portfolio} t={t} />
      <BidOfferCapacityTabs
        bidActivationGroups={bidActivationGroups}
        bidHistories={bidHistories}
        groupParams={groupParams}
      />
      <ButtonBar>
        <CustomButton
          key="backButton"
          startIcon={<IconChevronLeft />}
          variant={'outlined'}
          onClick={() => navigate(-1)}
        >
          {t('common.navigation.back')}
        </CustomButton>
        {biddingFlow === BiddingFlow.CAPACITY_FLOW && (
          <CreateBidButton
            dialogProps={{
              description: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.description'),
              title: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.title'),
            }}
            disabled={latestBids.length !== bidActivationGroups.length}
            shouldShowConfirmation={anyBidOutOfBiddableVolumeRange(latestBids, activationGroups)}
            onSubmit={() => navigate(buildBidConfirmationLink(groupParams))}
          />
        )}
        {biddingFlow === BiddingFlow.CAPACITY_AND_PRICE_FLOW && (
          <SubmitButton
            buttonProps={{
              startIcon: <IconChevronRight />,
              disabled: latestBids.length !== bidActivationGroups.length,
            }}
            dialogProps={{
              description: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.description'),
              title: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.title'),
            }}
            shouldShowConfirmation={anyBidOutOfBiddableVolumeRange(latestBids, activationGroups)}
            onSubmit={() => navigate(buildBidPriceLink(groupParams))}
          >
            {t('bidding.create_bid.bid_hourly_prices')}
          </SubmitButton>
        )}
      </ButtonBar>
    </>
  )
}

const BidOfferHeader: FC<{ portfolio: Portfolio; groupParams: GroupedBidParams; t: TFunction }> = ({
  portfolio,
  groupParams,
  t,
}) => (
  <PageHeader
    appBarContent={
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Box display="flex" flexDirection="row" sx={{ flex: 1, justifyContent: 'left' }}>
          <CountrySelector disabled />
        </Box>
        <ExternalReferenceButtons marketProgram={groupParams.marketProgram} portfolio={portfolio} />
      </Box>
    }
    breadcrumbItems={[
      { text: t('component.page_header.bidding'), to: '/bidding' },
      { text: t('component.page_header.bid.todo_list'), to: '/bidding/todo-list' },
    ]}
    pageTitle={t('component.page_header.bid.create.label', {
      portfolio: groupParams.portfolioCode,
      marketProgram: t(`bidding.market_program.${groupParams.marketProgram}`),
    })}
  />
)

export default BidOfferCapacity

import type { HubCallback } from '@aws-amplify/core'
import type { AuthHubEventData } from '@aws-amplify/core/src/Hub/types/AuthTypes'
import * as Sentry from '@sentry/react'
import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { USER_ROLES } from '@/constants/userRoles'
import { COGNITO_FIELDS } from '@/features/authentication/constants'
import { logCognitoExceptionInSentry, logMessageInSentry } from '@/features/sentry/utils/sendExceptions'
import { useUserQuery } from '@/features/user/hooks/useUserQuery'
import type { User } from '@/features/user/types/user'

interface AuthProviderProps {
  children: ReactNode
  loggedInUser?: User
}

type State = {
  isLogged: boolean
  isStarting: boolean
  loggedInUser: User | null
  error: Error | null
  logout: () => void
  refreshSession: () => void
}

const initialState = {
  isLogged: false,
  isStarting: true,
  loggedInUser: null,
  error: null,
  logout: () => {},
  refreshSession: () => {},
}

const AuthContext = createContext<State>(initialState)

function hasFlexPortalAccess(role: string) {
  const enabledUserRoles = [
    USER_ROLES.ADMINISTRATORS,
    USER_ROLES.PARTNER_ADMINISTRATORS,
    USER_ROLES.RESOURCE_OWNERS,
    USER_ROLES.CUSTOMER_MANAGERS,
  ]
  return enabledUserRoles.some((userRole) => userRole.value === role)
}

// Error messages
const ERROR_MESSAGE_WRONG_CREDENTIALS = 'Incorrect username or password.'
const ERROR_MESSAGE_USER_DOES_NOT_EXIST = 'User does not exist.'

function AuthProvider(props: Readonly<AuthProviderProps>) {
  const [isLogged, setIsLogged] = useState(false)
  const [isStarting, setIsStarting] = useState(true)
  const [loggedInUser, setLoggedInUser] = useState<User | null>(props.loggedInUser ?? null)
  const [userId, setUserId] = useState<string | null>(null)
  const { user, error, isLoading } = useUserQuery({ id: userId as string }, { enabled: userId !== null })
  const navigate = useNavigate()

  useEffect(() => {
    const listener: HubCallback<'auth', AuthHubEventData> = async (eventData) => {
      const { event, message } = eventData.payload

      if (event === 'signedIn') {
        signInWithAmplify()
        return
      }

      if (!message) return

      if (message !== ERROR_MESSAGE_WRONG_CREDENTIALS && message !== ERROR_MESSAGE_USER_DOES_NOT_EXIST) {
        logCognitoExceptionInSentry(eventData.payload)
      }
    }

    const cancelListener = Hub.listen('auth', listener)

    return () => {
      cancelListener()
    }
  }, [signInWithAmplify])

  useEffect(() => {
    if (!isStarting) return

    signInWithAmplify()
  }, [isStarting, signInWithAmplify])

  useEffect(() => {
    if (!user) {
      return
    }

    if (!user.isActive) {
      logMessageInSentry('User is not active', { extra: { payload: user } })

      signOutWithAmplifyAndShowError()

      return
    }

    if (!user.role || !hasFlexPortalAccess(user.role)) {
      logMessageInSentry('User does not have a necessary role.', {
        extra: { payload: user },
      })

      signOutWithAmplifyAndShowError()

      return
    }

    setLoggedInUser(user)
    setIsLogged(true)
    setIsStarting(false)

    // Include the authenticated user in Sentry context to enhance possible captured errors.
    Sentry.setUser({
      id: user.id,
      role: user.role,
      username: user.username,
    })
  }, [user])

  useEffect(() => {
    if (error) {
      resetAuthState()
    }
  }, [error])

  useEffect(() => {
    if (!isLogged) {
      setUserId(null)
    }
  }, [isLogged])

  async function signInWithAmplify() {
    try {
      const { tokens } = await fetchAuthSession()
      const tokenPayload = tokens?.idToken?.payload ?? null

      if (!tokenPayload) {
        signOutWithAmplifyAndShowError()
        return
      }

      setUserId((tokenPayload[COGNITO_FIELDS.USER_ID] as string) ?? null)
    } catch {
      await logout()
    }
  }

  async function signOutWithAmplifyAndShowError() {
    try {
      await signOutAndRevokeToken()
    } finally {
      resetAuthState()
      Sentry.setUser(null)
    }
  }

  async function refreshSession() {
    await fetchAuthSession({ forceRefresh: true })
  }

  // It logs out the user and it revokes the refresh token from all devices. Signing out a user from all its devices prevents an attacker to have unlimited access. Currently, the
  // refresh token expiration time is 5 minutes, which reduces the time where a potential attacker can have access to the platform using a token
  //
  // You can check Amplify documentation about this topic here:
  // https://docs.amplify.aws/react/build-a-backend/auth/concepts/tokens-and-credentials/#token-revocation
  async function signOutAndRevokeToken() {
    await signOut({ global: true })
  }

  async function logout() {
    await signOutAndRevokeToken()

    navigate('/')
    resetAuthState()
  }

  function resetAuthState() {
    setLoggedInUser(null)
    setUserId(null)
    setIsLogged(false)
    setIsStarting(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        isStarting: isStarting || isLoading,
        loggedInUser,
        logout,
        refreshSession,
        error,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthContext, AuthProvider, useAuth }

import type { BreadcrumbsProps } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import type { ElementType } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import CustomLink from '@/components/navigation/CustomLink'
import type { Breadcrumb } from '@/types/breadcrumb'

type CustomBreadcrumbsProps = BreadcrumbsProps & {
  items: Breadcrumb[]
}

/**
 * CustomBreadcrumbs component uses MUI Breadcrumbs component and adds some Sympower custom behaviors:
 *
 * - Adds an extra prop "items" to render every item as a RouterLink to have navigation, but keeping the Link style
 * of MUI.
 */
function CustomBreadcrumbs({ items, sx, ...breadcrumbProps }: CustomBreadcrumbsProps) {
  const hasItems = items.length > 0
  const styles = { marginTop: 1, ...sx }

  if (!hasItems) {
    return null
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={styles} {...breadcrumbProps}>
      {items?.map((item) => (
        <CustomLink
          key={`${item.text}:${item.to}`}
          color="primary"
          component={RouterLink as ElementType}
          to={item.to}
          underline="hover"
        >
          {item.text}
        </CustomLink>
      ))}
      {/* The empty span is used for the breadcrumbs to include the "/" separator at the end */}
      <span></span>
    </Breadcrumbs>
  )
}

export default CustomBreadcrumbs

import { CircularProgress, Grid } from '@mui/material'
import type { DateTime as DateTimeType } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import PageHeader from '@/components/layouts/PageHeader'
import { ONE_DAY_AGO, TODAY } from '@/constants/dateTime'
import LatestMeasurementsDataGrid from '@/features/measurement/components/LatestMeasurementsDataGrid'
import MeasuredPowerChart from '@/features/measurement/components/MeasuredPowerChart'
import MeasuredPowerFilters from '@/features/measurement/components/MeasuredPowerFilters'
import { useMeasuredPowerQuery } from '@/features/measurement/hooks/useMeasuredPowerQuery'

function MeasurementsIndex() {
  const { t } = useTranslation()

  const [showOnlyTotal, setShowOnlyTotal] = useState(false)
  const [startTime, setStartTime] = useState<DateTimeType>(ONE_DAY_AGO)
  const [endTime, setEndTime] = useState<DateTimeType>(TODAY)

  const { isLoading, timeData, measuredPowerSeries } = useMeasuredPowerQuery({ startTime, endTime })

  return (
    <>
      <PageHeader isRoot pageTitle={t('component.page_header.measurements')} />

      <MainContentContainer>
        <MeasuredPowerFilters
          endTime={endTime}
          setEndTime={setEndTime}
          setShowOnlyTotal={setShowOnlyTotal}
          setStartTime={setStartTime}
          showOnlyTotal={showOnlyTotal}
          startTime={startTime}
        />
        {isLoading ? (
          <Grid container alignItems="center" direction="row" justifyContent="center" sx={{ height: '400px' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <MeasuredPowerChart
            measuredPowerSeries={measuredPowerSeries}
            showOnlyTotal={showOnlyTotal}
            timeData={timeData}
          />
        )}
      </MainContentContainer>

      <LatestMeasurementsDataGrid
        endTime={endTime}
        isLoading={isLoading}
        measuredPowerSeries={measuredPowerSeries}
        startTime={startTime}
      />
    </>
  )
}

export default MeasurementsIndex

import type { CSSProperties } from 'react'

type CloudBackground1Props = {
  style?: CSSProperties
}

const WhiteCloud = ({ style }: CloudBackground1Props) => {
  return (
    <svg fill="none" height="41" style={style} viewBox="0 0 164 41" width="164" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M163.915 39.3757C165.168 30.2345 152.318 22.7134 142.663 21.9312C138.06 21.5593 133.277 22.2646 128.916 20.7407C119.464 17.4322 115.691 5.17062 106.469 1.26581C99.5771 -1.65372 91.6406 0.945216 84.9576 4.32212C78.2725 7.70116 71.6682 11.9522 64.202 12.431C57.7063 12.8499 50.6551 10.4305 44.9724 13.615C40.7391 15.9895 38.5193 20.8903 34.618 23.7799C30.7998 26.6097 25.8173 27.2081 21.0818 27.4667C16.3441 27.7254 11.4404 27.7724 7.12623 29.7558C2.81208 31.7392 -0.757164 36.3215 0.138868 41L163.915 39.3757Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}

export default WhiteCloud

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { MagActivation } from '@/features/activation/types/activation'
import { GET_MAG_ACTIVATIONS_API_ID, getActivations } from '@/features/customer/endpoints/magActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivationsFromMagQueryParams = {
  customerUuid: string
  location?: string
}
export type UseActivationsFromMagQueryResult = Omit<UseQueryResult, 'data'> & {
  magActivations: MagActivation[] | null
}

export function useActivationsFromMagQuery(
  params: ActivationsFromMagQueryParams,
  options?: UseQueryOptions<MagActivation[]>,
): UseActivationsFromMagQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_MAG_ACTIVATIONS_API_ID, params],
    queryFn: () => getActivations(params.customerUuid, params.location),
  })

  return { magActivations: data, ...queryResult }
}

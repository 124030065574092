import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_SPOT_ON_CUSTOMER_API_ID, getExtraCriteria } from '@/features/resource/endpoints/scheduleBuilder'
import type { ExtraCriteria } from '@/features/resource/types/spotOnForEboilers'
import type { UseQueryOptions } from '@/types/queries'

type UseSpotOnExtraCriteriaQueryParams = {
  scheduleProgramId: string
  customerId: string
}

export type UseSpotOnExtraCriteriaQueryResult = Omit<UseQueryResult, 'data'> & {
  extraCriteria: ExtraCriteria[]
}

export function useSpotOnExtraCriteriaQuery(
  params: UseSpotOnExtraCriteriaQueryParams,
  options?: UseQueryOptions<ExtraCriteria[] | null>,
): UseSpotOnExtraCriteriaQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_SPOT_ON_CUSTOMER_API_ID, params],
    queryFn: () => getExtraCriteria(params.scheduleProgramId, params.customerId),
  })

  return {
    extraCriteria: data ?? [],
    ...queryResult,
  }
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import PageHeader from '@/components/layouts/PageHeader'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { MarketProgramFilterTabs } from '@/features/customer/components/availability/MarketProgramFilterTabs'
import { RestrictionsDataGrid } from '@/features/customer/components/availability/restriction/RestrictionsDataGrid'
import { ServiceRulesDataGrid } from '@/features/customer/components/availability/serviceRule/ServiceRulesDataGrid'
import { NoMarketProgram } from '@/features/customer/components/NoMarketProgram'
import { useCustomerMarketProgramsQuery } from '@/features/customer/hooks/useCustomerMarketProgramsQuery'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { MarketProgramContract } from '@/features/customer/types/marketProgramContract'
import { sortMarketProgramContracts } from '@/features/customer/utils/sortMarketPrograms'
import type { MarketProgramType } from '@/types/marketProgramType'

export const Availability = () => {
  const { t } = useTranslation()
  const [selectedMarketProgram, setSelectedMarketProgram] = useState<MarketProgramContract>()

  const { loggedInUser } = useAuth()
  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid },
    { enabled: Boolean(customerUuid) },
  )

  const { marketProgramContracts, isFetching: isFetchingCustomerMarketPrograms } = useCustomerMarketProgramsQuery({
    uuid: customerUuid,
  })
  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery({
    uuid: customerUuid,
  })

  if (
    !customer ||
    isFetchingCustomer ||
    !customerSettings ||
    isFetchingCustomerSettings ||
    isFetchingCustomerMarketPrograms
  )
    return null

  const activeMarketPrograms = sortMarketProgramContracts(
    t,
    marketProgramContracts?.filter((contract) => !contract.isDisabled) ?? [],
  )

  if (activeMarketPrograms.length === 0) {
    return <NoMarketProgram />
  }

  const customerMarketProgramTypes = activeMarketPrograms.map((program) => program.service.type as MarketProgramType)

  const marketProgram = selectedMarketProgram ?? activeMarketPrograms[0]
  const marketProgramType = selectedMarketProgram?.service.type ?? activeMarketPrograms[0].service.type

  const handleChange = (newValue: MarketProgramType) => {
    setSelectedMarketProgram(activeMarketPrograms.find((program) => program.service.type === newValue))
  }

  return (
    <>
      <PageHeader pageTag={'availability'} pageTitle={t('component.page_header.availability')} />
      <MainContentContainer>
        <MarketProgramFilterTabs
          activeMarketPrograms={customerMarketProgramTypes}
          value={marketProgramType}
          onChange={handleChange}
        />
        {isMarketProgramAvailability(marketProgram.service) ? (
          <ServiceRulesDataGrid
            customerLocation={customer.location ?? ''}
            customerTimeZone={customerSettings.localization.timeZone ?? ''}
            customerUuid={customer.uuid ?? ''}
            selectedMarketProgram={marketProgram}
          />
        ) : (
          <RestrictionsDataGrid
            customerLocation={customer.location ?? ''}
            customerTimeZone={customerSettings.localization.timeZone ?? ''}
            customerUuid={customer.uuid ?? ''}
            selectedMarketProgram={marketProgram.service}
          />
        )}
      </MainContentContainer>
    </>
  )
}

function isMarketProgramAvailability(program: MarketProgram) {
  return !program.isReserve || program.reserve === 'afrr'
}

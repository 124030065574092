import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import Page from '@/components/layouts/Page'
import IntegrationCustomerDetailsTabs from '@/features/integration/components/IntegrationCustomerDetailsTabs'
import { IntegrationsRouteInformation } from '@/features/integration/constants'
import { useIntegrationCustomerQuery } from '@/features/integration/hooks/useIntegrationCustomerQuery'
import type { Customer, IntegrationCustomerDetailsPageConfig } from '@/features/integration/types'

type ContextType = {
  customer: Customer | null
  pageConfig: IntegrationCustomerDetailsPageConfig | null
  setPageConfig: Dispatch<SetStateAction<IntegrationCustomerDetailsPageConfig>>
}

const IntegrationCustomerDetails = () => {
  const { t } = useTranslation()
  const { customerUuid } = useParams()
  const { customer, isLoading, isFetched } = useIntegrationCustomerQuery({ uuid: customerUuid })
  const [pageConfig, setPageConfig] = useState<IntegrationCustomerDetailsPageConfig>({
    title: '',
    activeTab: IntegrationsRouteInformation.CUSTOMER_CONTROLLERS.routePath,
  })

  if (!customer && isFetched) {
    // TODO: Show not found page
    return null
  }

  return (
    <Page
      isLoading={isLoading}
      pageHeaderProps={{
        breadcrumbItems: [
          {
            text: t('common.integrations'),
            to: IntegrationsRouteInformation.INDEX.navigationPath,
          },
          {
            text: t('common.customers'),
            to: IntegrationsRouteInformation.CUSTOMERS.navigationPath,
          },
          {
            text: customer?.name ?? '',
          },
        ],
        pageTitle: pageConfig.title,
      }}
    >
      <>
        <IntegrationCustomerDetailsTabs customerUuid={customerUuid!} pageConfig={pageConfig} />

        <Outlet
          context={
            {
              pageConfig,
              setPageConfig,
              customer,
            } satisfies ContextType
          }
        />
      </>
    </Page>
  )
}

export function useIntegrationCustomerDetails() {
  return useOutletContext<ContextType>()
}

export default IntegrationCustomerDetails

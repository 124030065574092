import type { GridColDef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { DURATION_FORMAT, HOUR_h_MINUTE_m_SECONDS_s_FORMAT } from '@/constants/dateTimeFormats'
import { ActivationDetailsToolbar } from '@/features/activation/components/ActivationDetailsToolbar'
import type { ActivatedResource } from '@/features/activation/types/activatedResource'
import {
  convertBffActivatedResourcesToStandard,
  convertMagActivatedResourcesToStandard,
  convertPresentActivationToStandard,
} from '@/features/activation/utils/converters'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useActivatedResourcesFromBffQuery } from '@/features/customer/hooks/useActivatedResourcesFromBffQuery'
import { useActivatedResourcesFromMagQuery } from '@/features/customer/hooks/useActivatedResourcesFromMagQuery'
import { useActivationFromBffQuery } from '@/features/customer/hooks/useActivationFromBffQuery'
import { useActivationFromMagQuery } from '@/features/customer/hooks/useActivationFromMagQuery'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import type { Customer } from '@/features/customer/types/customer'
import getDuration, { convertToTimeZoneDateTime } from '@/utils/time'

const DASH_VALUE = '-'

function formatActivatedResourceDuration(startTime?: string, endTime?: string) {
  if (!startTime || !endTime) {
    return DASH_VALUE
  }
  return getDuration(startTime, endTime).toFormat(DURATION_FORMAT).replace('00h ', '')
}

function formatResourceActivationTime(customerTimeZone: string, iso?: string) {
  return iso ? convertToTimeZoneDateTime(customerTimeZone, iso).toFormat(HOUR_h_MINUTE_m_SECONDS_s_FORMAT) : DASH_VALUE
}

function getMarketPrograms(customer?: Customer) {
  return new Map(customer?.services?.map((mp) => [mp.id, mp.type]))
}

function isNumeric(activationId: any) {
  return !isNaN(Number(activationId))
}

export const ActivationDetails = () => {
  const { loggedInUser } = useAuth()
  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery({
    uuid: customerUuid,
  })

  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid },
    { enabled: Boolean(customerUuid) },
  )

  const { t } = useTranslation()
  const { id } = useParams()

  const { magActivation } = useActivationFromMagQuery(
    {
      customerUuid: customerUuid,
      activationId: Number(id),
      location: customer ? customer.location : undefined,
    },
    { enabled: !!customer && !isFetchingCustomer && isNumeric(id) },
  )

  const { bffActivation } = useActivationFromBffQuery(
    {
      activationId: id!,
    },
    { enabled: !!customer && !isFetchingCustomer && !isNumeric(id) },
  )

  const activation = convertPresentActivationToStandard(
    magActivation,
    bffActivation,
    getMarketPrograms(customer ?? undefined),
  )

  const { magActivatedResources, isFetching: isFetchingMagActivatedResources } = useActivatedResourcesFromMagQuery(
    {
      location: customer ? customer.location : undefined,
      activationId: magActivation?.id,
      serviceId: magActivation?.serviceId,
      customerUuid: customerUuid,
    },
    { enabled: !!magActivation && !!customer && !isFetchingCustomer },
  )
  const { bffActivatedResources, isFetching: isFetchingBffActivatedResources } = useActivatedResourcesFromBffQuery(
    {
      siteId: customerUuid,
      activationId: id!,
    },
    { enabled: !!bffActivation && !!customer && !isFetchingCustomer },
  )

  const activatedResources = magActivatedResources
    ? convertMagActivatedResourcesToStandard(magActivatedResources)
    : convertBffActivatedResourcesToStandard(bffActivatedResources ?? [])

  const columns: GridColDef[] = [
    {
      field: 'resourceName',
      headerName: t('customer_details.activations.details.resource.label'),
      flex: 1,
    },
    {
      field: 'sectionName',
      headerName: t('customer_details.activations.details.section.label'),
      flex: 1,
      valueGetter: (value: ActivatedResource['sectionName']) => {
        return value ?? DASH_VALUE
      },
    },
    {
      field: 'activatedAt',
      headerName: t('customer_details.activations.details.start_time.label'),
      flex: 1,
      valueFormatter: (value: ActivatedResource['activatedAt']) => {
        return formatResourceActivationTime(customerSettings?.localization?.timeZone ?? 'UTC', value)
      },
    },
    {
      field: 'deactivatedAt',
      headerName: t('customer_details.activations.details.end_time.label'),
      flex: 1,
      valueFormatter: (value: ActivatedResource['deactivatedAt']) => {
        return formatResourceActivationTime(customerSettings?.localization?.timeZone ?? 'UTC', value)
      },
    },
    {
      field: 'duration',
      headerName: t('customer_details.activations.details.duration.label'),
      flex: 1,
      valueGetter: (_, activatedResource: ActivatedResource) => {
        return formatActivatedResourceDuration(activatedResource.activatedAt, activatedResource.deactivatedAt)
      },
    },
  ]

  if (!customer || isFetchingCustomer || isFetchingCustomerSettings || (!magActivation && !bffActivation)) return null

  return (
    <CustomDataGrid
      columns={columns}
      getRowId={(row) => row.id + row.activatedAt}
      initialState={{
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
        sorting: {
          sortModel: [],
        },
      }}
      isLoading={isFetchingMagActivatedResources || isFetchingBffActivatedResources}
      rows={activatedResources ?? []}
      slotProps={{
        toolbar: {
          activation: activation,
          customerTimeZone: customerSettings?.localization?.timeZone ?? 'UTC',
        },
      }}
      slots={{
        toolbar: ActivationDetailsToolbar,
      }}
    />
  )
}

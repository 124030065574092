import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceQuery } from '@/features/resource/hooks/useResourceQuery'
import { useSpotOnScheduledResourceQuery } from '@/features/resource/hooks/useSpotOnScheduledResourceQuery'
import ResourceSpotOnEboilerDetailsTabs from '@/features/resource/pages/components/ResourceSpotOnEboilerDetailsTabs'
import type { Resource } from '@/features/resource/types'
import type { ApiScheduledResource } from '@/features/resource/types/spotOnForEboilers'

type ContextType = {
  resource: Resource | null
  scheduledResource: ApiScheduledResource | null
  resourceUuid: string
  activeTab: string | null
  setActivePage: Dispatch<SetStateAction<string>>
}

function ResourceSpotOnEboilerDetailsPage() {
  const { t } = useTranslation()
  const { resourceUuid } = useParams()
  const { resource } = useResourceQuery({ id: resourceUuid! }, { enabled: Boolean(resourceUuid) })
  const { scheduledResource } = useSpotOnScheduledResourceQuery(
    { resourceId: resource?.resourceID ?? '' },
    { enabled: Boolean(resource) },
  )

  const [activeTab, setActiveTab] = useState<string>(
    ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_CONFIGURATION.routePath,
  )

  if (!resourceUuid) {
    return null
  }

  return (
    <>
      <PageHeader
        breadcrumbItems={[
          {
            text: t('spot_on_for_eboilers.title'),
            to: '/resources/spot-on',
          },
        ]}
        pageTitle={t('resources.tabs.title')}
      />
      {activeTab !== '' && (
        <ResourceSpotOnEboilerDetailsTabs activeTab={activeTab} hasScheduledResource={Boolean(scheduledResource)} />
      )}

      <Outlet
        context={
          {
            resource: resource ?? null,
            scheduledResource: scheduledResource ?? null,
            resourceUuid,
            activeTab,
            setActivePage: setActiveTab,
          } satisfies ContextType
        }
      />
    </>
  )
}

export function useResourceSpotOnEboilerDetails() {
  return useOutletContext<ContextType>()
}

export default ResourceSpotOnEboilerDetailsPage

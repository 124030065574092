import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { getGridDateOperators } from '@mui/x-data-grid/colDef/gridDateOperators'
import { getGridSingleSelectOperators } from '@mui/x-data-grid/colDef/gridSingleSelectOperators'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ChipList from '@/components/dataDisplay/ChipList'
import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { COLUMN_WITH_SMALL_ICON_WIDTH } from '@/constants/layout'
import { AVAILABILITY_STATUSES, AVAILABILITY_STATUSES_LIST } from '@/features/availability/types/availabilityStatus'
import type { ResourceLevelRestriction } from '@/features/availability/types/restriction'
import { AvailabilityStatusChip } from '@/features/customer/components/availability/datagrid/AvailabilityStatusChip'
import { getGridDateIncludesOperators } from '@/features/customer/components/availability/datagrid/includesFilterOperators'
import {
  DEFAULT_DATA_GRID_UNAVAILABLITY_FILTER_MODEL,
  UnavailabilityDataGridToolbar,
} from '@/features/customer/components/availability/datagrid/resourceLevelUnavailaibility/UnavailabilityDataGridToolbar'
import { ResourceLevelRestrictionDetailsCreateDialog } from '@/features/customer/components/availability/restriction/resourceLevelUnavailaibility/ResourceLevelRestrictionDetailsCreateDialog'
import { ResourceLevelRestrictionDetailsDialog } from '@/features/customer/components/availability/restriction/resourceLevelUnavailaibility/ResourceLevelRestrictionDetailsDialog'
import { ResourceLevelRestrictionDetailsEditDialog } from '@/features/customer/components/availability/restriction/resourceLevelUnavailaibility/ResourceLevelRestrictionDetailsEditDialog'
import type { Resource } from '@/features/customer/components/availability/types'
import { formatResourceName } from '@/features/customer/components/availability/utils'
import {
  useInvalidateResourceLevelRestrictionsWithPollingQuery,
  useResourceLevelRestrictionsWithPollingQuery,
} from '@/features/customer/hooks/resourceLevelUnavailaibility/useResourceLevelRestrictionsWithPollingQuery'
import { convertToTimeZoneDateTime, formatDateTimeWithLocale } from '@/utils/time'

interface ResourceLevelRestrictionsDataGridProps {
  customerUuid: string
  customerTimeZone: string
  customerLocation: string
  viewOnly?: boolean
}

export const ResourceLevelRestrictionsDataGrid = (props: ResourceLevelRestrictionsDataGridProps) => {
  const { t, i18n } = useTranslation()
  const [selectedRestriction, setSelectedRestriction] = useState({} as ResourceLevelRestriction)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [createFormDialogOpen, setCreateFormDialogOpen] = useState(false)
  const [editFormDialogOpen, setEditFormDialogOpen] = useState(false)
  const invalidateResourceLevelRestrictionsQuery = useInvalidateResourceLevelRestrictionsWithPollingQuery()

  const handleOpen = (restriction: ResourceLevelRestriction) => {
    setSelectedRestriction(restriction)
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
    setSelectedRestriction({} as ResourceLevelRestriction)
  }

  const { restrictions } = useResourceLevelRestrictionsWithPollingQuery({
    customerUuid: props.customerUuid,
  })

  const handleCancelRestrictionSubmit = async () => {
    setSelectedRestriction({} as ResourceLevelRestriction)
    setCreateFormDialogOpen(false)
    setEditFormDialogOpen(false)
    await invalidateResourceLevelRestrictionsQuery()
  }

  function getRestrictionColumns(t: TFunction, customerTimeZone: string): GridColDef<ResourceLevelRestriction>[] {
    return [
      {
        field: 'startDate',
        headerName: t('customer_details.tabs.availability.unavailable_from'),
        flex: 1,
        type: 'date',
        filterOperators: [
          ...getGridDateOperators(),
          ...getGridDateIncludesOperators(customerTimeZone, customerTimeZone),
        ],
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'endDate',
        headerName: t('customer_details.tabs.availability.unavailable_to'),
        flex: 1,
        type: 'date',
        filterOperators: [
          ...getGridDateOperators(),
          ...getGridDateIncludesOperators(customerTimeZone, customerTimeZone),
        ],
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'timeOfDay',
        headerName: t('customer_details.tabs.availability.start_end_time'),
        flex: 1,
        sortable: false,
        valueGetter: (_, restriction: ResourceLevelRestriction) => `${restriction.startTime} - ${restriction.endTime}`,
      },
      {
        field: 'type',
        headerName: t('customer_details.tabs.availability.recurrence'),
        flex: 1,
        valueFormatter: (value: ResourceLevelRestriction['type']) =>
          value === 'repeating-daily'
            ? t('customer_details.tabs.availability.unavailability_data_grid.cells.recurrence.daily')
            : '—',
      },
      {
        field: 'resources',
        headerName: t('common.resources'),
        flex: 0.5,
        sortable: false,
        valueGetter: (_, restriction: ResourceLevelRestriction) =>
          restriction.resources?.map((wire) => formatResourceName(wire)),
        renderCell: (params: GridRenderCellParams<ResourceLevelRestriction>) => {
          return (
            <ChipList
              chips={
                params.row.resources?.map((resource) => ({
                  id: resource.id?.toString() || '',
                  label: formatResourceName(resource),
                  sx: { cursor: 'inherit' },
                })) ?? []
              }
              color="secondary"
              limit={0}
              size="small"
            />
          )
        },
      },
      {
        field: 'updatedAt',
        headerName: t('customer_details.tabs.availability.last_updated'),
        flex: 1,
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        type: 'singleSelect',
        valueOptions: [...AVAILABILITY_STATUSES_LIST],
        sortComparator: (v1, v2) => {
          return AVAILABILITY_STATUSES[v1].sortingOrder - AVAILABILITY_STATUSES[v2].sortingOrder
        },
        filterOperators: [...getGridSingleSelectOperators()],
        renderCell: (params: GridRenderCellParams<ResourceLevelRestriction>) => {
          return <AvailabilityStatusChip status={params.value} />
        },
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        width: COLUMN_WITH_SMALL_ICON_WIDTH,
        renderCell: (params: GridRenderCellParams<ResourceLevelRestriction>) => {
          return (
            params.row.status !== 'expired' &&
            !props.viewOnly && (
              <CustomIconButton
                Icon={EditOutlinedIcon}
                aria-label="edit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedRestriction(params.row)
                  setEditFormDialogOpen(true)
                }}
              />
            )
          )
        },
      },
    ]
  }

  return (
    <>
      <CustomDataGrid
        disableGutterTop
        clickableRows={{
          onRowClick: (params) => {
            handleOpen(params.row)
          },
        }}
        columnVisibilityModel={{
          createdAt: false,
        }}
        columns={getRestrictionColumns(t, props.customerTimeZone)}
        includeWrapper={false}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'status',
                sort: 'asc',
              },
              {
                field: 'startDate',
                sort: 'desc',
              },
            ],
          },
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          filter: {
            filterModel: DEFAULT_DATA_GRID_UNAVAILABLITY_FILTER_MODEL,
          },
        }}
        rows={restrictions ?? []}
        slotProps={{
          toolbar: {
            availableResources: getUniqueResoucreLevelRestrictedResources(restrictions ?? []),
            customerTimeZone: props.customerTimeZone,
            onAddClick: () => setCreateFormDialogOpen(true),
          },
        }}
        slots={{
          toolbar: UnavailabilityDataGridToolbar,
        }}
        sx={{ pt: 2 }}
      />

      <ResourceLevelRestrictionDetailsDialog
        customerTimeZone={props.customerTimeZone}
        open={dialogOpen}
        restriction={selectedRestriction}
        onClose={handleClose}
      />
      <ResourceLevelRestrictionDetailsCreateDialog
        customerLocation={props.customerLocation}
        customerTimeZone={props.customerTimeZone}
        customerUuid={props.customerUuid}
        open={createFormDialogOpen}
        onClose={() => handleCancelRestrictionSubmit()}
      />
      <ResourceLevelRestrictionDetailsEditDialog
        customerLocation={props.customerLocation}
        customerTimeZone={props.customerTimeZone}
        customerUuid={props.customerUuid}
        open={editFormDialogOpen}
        restriction={selectedRestriction}
        onClose={() => handleCancelRestrictionSubmit()}
      />
    </>
  )
}

function getUniqueResoucreLevelRestrictedResources(restrictions: ResourceLevelRestriction[]) {
  const resources = restrictions.flatMap((restriction) => restriction.resources)
  const uniqueResources: Resource[] = []
  const seen = new Set()

  for (const resource of resources) {
    const key = `${resource.id}-${formatResourceName(resource)}`
    if (!seen.has(key)) {
      seen.add(key)
      uniqueResources.push({ id: resource.id, name: formatResourceName(resource) })
    }
  }

  return uniqueResources.sort((a, b) => a.name.localeCompare(b.name))
}

function getDateValueGetter(customerTimeZone: string) {
  return (value: string) => convertToTimeZoneDateTime(customerTimeZone, value)
}

function getDateFormatter(language: string) {
  return (value: DateTime) => formatDateTimeWithLocale(value, language)
}

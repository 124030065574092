import { Box, Grid2 as Grid, InputAdornment, Stack } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TextFieldController from '@/components/inputs/TextFieldController'
import { USER_ROLES } from '@/constants/userRoles'
import MFASwitch from '@/features/user/components/form/MFASwitch'
import type { User } from '@/features/user/types/user'

function SecuritySectionWithoutOptions() {
  const { t } = useTranslation()
  const { control, setValue, getValues, trigger } = useFormContext<User>()

  const role = getValues('role')

  function triggerPhoneValidation() {
    trigger('phone')
  }

  return (
    <Grid container>
      <Grid size={{ md: 5, sm: 6, xs: 12 }}>
        <Stack gap={3}>
          <Controller
            control={control}
            name="isMFAEnabled"
            render={({ field: { value, name, onChange }, fieldState: { invalid, error } }) => (
              <MFASwitch
                displayHelperText
                disabled={role === USER_ROLES.ADMINISTRATORS.value || role === USER_ROLES.PARTNER_ADMINISTRATORS.value}
                error={invalid}
                helperText={error?.message}
                isMFAEnabled={value ?? false}
                name={name}
                onChange={(_, checked) => {
                  onChange(checked)
                  triggerPhoneValidation()
                }}
              />
            )}
          />
          <Box>
            <TextFieldController
              label={t('user_form.form.phone_label')}
              name="phone"
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">+</InputAdornment>,
                },
              }}
              onChange={(event) => {
                // users are only allowed to enter numbers
                const value = event.target.value.replace(/\D/g, '')

                setValue('phone', value)
              }}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default SecuritySectionWithoutOptions

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_USER_API_ID, getUser } from '@/features/user/endpoints/users'
import type { User } from '@/features/user/types/user'
import type { UseQueryOptions } from '@/types/queries'

type UserQueryParams = {
  id: string
}
export type UseUserQueryResult = Omit<UseQueryResult, 'data'> & {
  user?: User
}

export function useUserQuery(params: UserQueryParams, options?: UseQueryOptions<User>): UseUserQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_USER_API_ID, params],
    queryFn: () => getUser(params.id),
  })

  return { user: data, ...queryResult }
}

export function useInvalidateUserQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_USER_API_ID] })
}

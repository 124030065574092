import { CircularProgress, Grid2 as Grid, Stack } from '@mui/material'
import { setUpTOTP } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import type { MfaSettings } from '@/features/user/types/user'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

import UserProfileTotpValidateCode from './UserProfileTotpValidateCode'

type UserProfileTotpOptionProps = {
  hasPreferredOptionError: boolean
  mfaSettings: MfaSettings
  userId: string
  userUsername: string
}

function UserProfileTotpOption({
  hasPreferredOptionError,
  mfaSettings,
  userId,
  userUsername,
}: Readonly<UserProfileTotpOptionProps>) {
  const { t } = useTranslation()
  const [sharedSecret, setSharedSecret] = useState('')
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const { branding } = useWhitelabelConfigurationContext()

  useEffect(() => {
    const setUpData = async () => {
      const totpSetupDetails = await setUpTOTP()
      const setupUri = totpSetupDetails.getSetupUri(branding.name, userUsername)
      setSharedSecret(totpSetupDetails.sharedSecret)
      setQrCodeUrl(setupUri.href ?? null)
    }

    setUpData()
  }, [])

  return (
    <Grid container>
      <Grid size={{ md: 6, xs: 12 }}>
        <Stack gap={2}>
          <CustomTypography variant="body2">{t('user_profile.settings.security.scan_qrcode_text')}</CustomTypography>
          {qrCodeUrl ? <QRCode value={qrCodeUrl} /> : <CircularProgress />}
          <CustomTypography sx={{ fontSize: '12px' }} variant="body2">
            {sharedSecret}
          </CustomTypography>
          <CustomTypography variant="body2">{t('user_profile.settings.security.validate_code_text')}</CustomTypography>
          <UserProfileTotpValidateCode
            hasPreferredOptionError={hasPreferredOptionError}
            mfaSettings={mfaSettings}
            userId={userId}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default UserProfileTotpOption

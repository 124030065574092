import type { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { z } from 'zod'

const luxonDateTime = z.preprocess((arg) => {
  if (arg instanceof DateTime) {
    return arg.toJSDate()
  }
  return arg
}, z.date())

export const ResourceSteeringDefaultsSchema = z.object({
  defaultConsumptionLevel: z.number().nullable(),
  steeringDefaultType: z.enum(['NONE', 'SET_TO_DEFAULT_LEVEL', 'RELEASE_CONTROL']),
})

export const ResourceSteeringMultistepSchema = z.object({
  isMultistepResource: z.boolean(),
  multistepStrategy: z.enum(['EXCLUSIVE', 'ADDITIVE', 'UNDEFINED']).nullable(),
})

export const SteeringCapabilitiesSchema = z.object({
  isReleaseControlSupported: z.boolean(),
  minReleaseControlTimeInSeconds: z.number().nullable(),
})

const SteeringRangeEntrySchema = z
  .object({
    valueType: z.enum(['ABSOLUTE', 'DYNAMIC']),
    value: z.number().nullable(),
    minSecondsOnThisLevel: z.number().nullable(),
    maxSecondsOnThisLevel: z.number().nullable(),
  })
  .refine(
    (data) => {
      if (data.valueType === 'ABSOLUTE' && data.value === null) {
        return false
      }
      return true
    },
    {
      message: 'Value must be specified for ABSOLUTE value type',
      path: ['value'],
    },
  )

const ResourceMultistepMappingSchema = z.object({
  stepValue: z.number().nullable(),
  controlPortId: z.string().nullable(),
})

export const ResourceSteeringRangeSchema = (usedControlPorts: Set<string>) =>
  z
    .object({
      max: SteeringRangeEntrySchema,
      min: SteeringRangeEntrySchema,
      step: SteeringRangeEntrySchema,
      multistepMappings: z.array(ResourceMultistepMappingSchema).optional(),
    })
    .refine(
      (data) => {
        return (
          data.max.valueType === 'ABSOLUTE' && data.min.valueType === 'ABSOLUTE' && data.step.valueType === 'ABSOLUTE'
        )
      },
      {
        message: 'All values must be of ABSOLUTE type, we have deprecated DYNAMIC values',
        path: ['maxmin'],
      },
    )
    .refine(
      (data) => {
        return data.multistepMappings?.every((mapping) => mapping.controlPortId !== null)
      },
      {
        message: 'All mappings steps should have control port assigned',
        path: ['multistepMappings'],
      },
    )
    .refine(
      (data) => {
        const controlPortOccurrences = new Map<string, number>()

        data.multistepMappings?.forEach((mapping) => {
          if (mapping.controlPortId) {
            controlPortOccurrences.set(
              mapping.controlPortId,
              (controlPortOccurrences.get(mapping.controlPortId) || 0) + 1,
            )
          }
        })

        return ![...controlPortOccurrences.entries()].some(
          ([controlPortId, count]) => count > 1 || usedControlPorts.has(controlPortId),
        )
      },
      {
        message: 'A control port can only be used once in the multistep mappings',
        path: ['multistepMappings'],
      },
    )

export type ResourceSteeringRangeSchemaType =
  Parameters<typeof ResourceSteeringRangeSchema>[0] extends Set<string>
    ? z.infer<ReturnType<typeof ResourceSteeringRangeSchema>> & {
        maxmin?: { message: string }
      }
    : never

export const ManualSteeringFormSchema = z
  .object({
    steeringCommandType: z.enum(['SET_TARGET_CONSUMPTION_LEVEL', 'RELEASE_CONTROL']),
    targetConsumptionLevel: z.number().nullable(),
    steerUntil: luxonDateTime.nullable(),
    force: z.boolean().default(false),
  })
  .refine(
    (data) => {
      if (data.steeringCommandType === 'SET_TARGET_CONSUMPTION_LEVEL' && data.targetConsumptionLevel === null) {
        return false
      }
      return true
    },
    {
      message: 'Target consumption level must be specified for SET_TARGET_CONSUMPTION_LEVEL command type',
      path: ['targetConsumptionLevel'],
    },
  )
  .refine(
    (data) => {
      if (data.steerUntil) {
        return data.steerUntil > new Date()
      }
      return true
    },
    {
      message: 'Steer until date must be in the future',
      path: ['steerUntil'],
    },
  )

export const getUpsertResourceScheduleSchema = (t: TFunction) =>
  z.object({
    scheduleJson: z
      .string()
      .min(1, t('resources.scheduler.upsert_schedule_form.schedule_json_required_error'))
      .transform((val) => {
        try {
          return JSON.parse(val)
        } catch (e) {
          console.error('JSON parsing error:', e)
          throw new Error(t('resources.scheduler.upsert_schedule_form.schedule_json_parse_error'))
        }
      }),
  })

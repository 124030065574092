import Typography from '@mui/material/Typography'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTimeRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro'
import type { DateTime } from 'luxon'
import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CustomFormControl from '@/components/inputs/CustomFormControl'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { Period } from '@/constants/period'
import type { TimeRange } from '@/features/activation/types/timeRange'
import {
  calculatePeriodStartAndEndTime,
  formatDateTimeForPeriodSelection,
} from '@/features/activation/utils/periodUtils'

type ActivationTimeRangeFilterProps = {
  dateOfFirstActivation: DateTime | undefined
  periodSelection: Period
  timeRange: TimeRange
  timezone?: string
  setTimeRange: Dispatch<SetStateAction<TimeRange>>
  setPeriodSelection: Dispatch<SetStateAction<Period>>
}

function ActivationTimeRangeFilter({
  dateOfFirstActivation,
  periodSelection,
  setPeriodSelection,
  timeRange,
  setTimeRange,
  timezone,
}: Readonly<ActivationTimeRangeFilterProps>) {
  const { t, i18n } = useTranslation()

  const handleChange = (event) => {
    setPeriodSelection(event.target.value)
    timeRange = calculatePeriodStartAndEndTime(event.target.value, timeRange, dateOfFirstActivation, timezone ?? 'UTC')
    setTimeRange(timeRange)
  }

  return (
    <>
      <CustomSelectField
        label={t('common.period.label')}
        options={[
          { id: 'all-time', value: Period.ALL_TIME, label: t('common.period.all_time') },
          { id: 'twelve-months', value: Period.PAST_12_MONTHS, label: t('common.period.past_12_months') },
          { id: 'past-month', value: Period.PAST_MONTH, label: t('common.period.past_month') },
          { id: 'past-24h', value: Period.PAST_24_HOURS, label: t('common.period.past_24_hours') },
          { id: 'custom', value: Period.CUSTOM, label: t('common.period.custom') },
        ]}
        size="medium"
        value={periodSelection}
        variant="outlinedWhite"
        onChange={handleChange}
      />
      {periodSelection === Period.CUSTOM ? (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <CustomFormControl variant="outlinedWhite">
            <DateTimeRangePicker
              ampm={false}
              format={'dd-MM-yyyy, HH:mm'}
              label={t('common.period.time_range')}
              slots={{ field: SingleInputDateTimeRangeField }}
              sx={{ width: '350px' }}
              timezone={timezone}
              value={[timeRange.from, timeRange.to]}
              onChange={(newValue) => {
                if (newValue[0] && newValue[1]) {
                  setTimeRange({ from: newValue[0]!, to: newValue[1]! })
                }
              }}
            />
          </CustomFormControl>
        </LocalizationProvider>
      ) : (
        <Typography fontSize={16}>
          {`${formatDateTimeForPeriodSelection(timeRange.from)} `}
          <i>{t('common.period.to_lowercase')}</i>
          {` ${formatDateTimeForPeriodSelection(timeRange.to)}`}
        </Typography>
      )}
    </>
  )
}

export default ActivationTimeRangeFilter

export const MAXIMUM_MW_PER_PTU = 1000

export const DEFAULT_CURRENCY = 'EUR'

export const QUANTILE_MAPPING = {
  '0.1': 'lower_bound',
  '0.9': 'upper_bound',
  '0.5': 'average_price',
}

export const MARKET_PROGRAM_NAMES = [
  {
    id: '0',
    value: 'FCR-D UP',
    label: 'FCR-D UP',
  },
  {
    id: '1',
    value: 'FCR-D DOWN',
    label: 'FCR-D DOWN',
  },
  {
    id: '2',
    value: 'FCR-N',
    label: 'FCR-N',
  },
]

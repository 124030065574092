export const PricingTableBiddingLadderGridCell = ({ biddingLadder }) => {
  // Calculate the total volume to determine volume percentage
  const totalVolume = biddingLadder.reduce((sum, bid) => sum + bid.allocatedVolume, 0)

  if (!biddingLadder || biddingLadder.length === 0 || totalVolume === 0) {
    return (
      <div
        style={{
          fontStyle: 'italic',
          color: 'gray',
          textAlign: 'center',
          width: '100%',
        }}
      >
        No Allocated Capacity
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
      {biddingLadder.map((bid) => {
        // Calculate volume percentage for height of the background bar
        const volumePercentage = (bid.allocatedVolume / totalVolume) * 100

        return (
          <div
            key={bid.level}
            style={{
              position: 'relative',
              width: '50px',
              fontSize: '10px',
              height: '52px',
              paddingTop: '4px',
              // textAlign: 'center',
            }}
          >
            {/* Level positioned in the top-left corner */}
            <div
              style={{ position: 'absolute', top: 2, left: 0, fontSize: '10px', lineHeight: '10px', color: '#94a3b8' }}
            >
              L{bid.level}
            </div>

            {/* Background bar with height based on volume percentage */}
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: `${volumePercentage}%`, // Set the height based on volume percentage
                backgroundColor: '#94a3b8', // Adjust color as needed
                opacity: 0.4,
                zIndex: -1, // Behind the price and volume text
                borderRadius: '2px',
              }}
            ></div>

            {/* Price and Volume next to each other */}
            <div
              style={{
                // position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-between',
                gap: '8px',
                paddingTop: '16px',
              }}
            >
              <span style={{ fontSize: '12px', lineHeight: '10px' }}>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 1,
                }).format(bid.priceLevel)}
              </span>
              <span
                style={{ fontSize: '12px', lineHeight: '10px' }}
              >{`${bid.allocatedVolume?.toFixed(1) ?? '-'} MW`}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

import { Box } from '@mui/material'
import type { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NOW } from '@/constants/dateTime'
import { DURATION_WITH_PLUS_PREFIX_FORMAT } from '@/constants/dateTimeFormats'
import { Period } from '@/constants/period'
import { ActivationsChart } from '@/features/activation/components/ActivationsChart'
import { ActivationsDataGrid } from '@/features/activation/components/ActivationsDataGrid'
import type { Activation } from '@/features/activation/types/activation'
import type { TimeRange } from '@/features/activation/types/timeRange'
import { getDistinctMarketPrograms } from '@/features/activation/utils/activationCharts'
import { formatLocalizedDateTimeMedWithSeconds } from '@/features/activation/utils/formatLocalizedDateTimeMedWithSeconds'
import { getMarketProgramTypes } from '@/features/activation/utils/getMarketProgramTypes'
import { mergeActivations } from '@/features/activation/utils/merge'
import { calculatePeriodStartAndEndTime } from '@/features/activation/utils/periodUtils'
import { NoMarketProgram } from '@/features/customer/components/NoMarketProgram'
import { useActivationsFromBffQuery } from '@/features/customer/hooks/useActivationsFromBffQuery'
import { useActivationsFromMagQuery } from '@/features/customer/hooks/useActivationsFromMagQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import { useMarketProgramsQuery } from '@/features/customer/hooks/useMarketProgramsQuery'
import CustomerActivationsAppBar from '@/features/customer/pages/components/CustomerActivationsAppBar'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'
import type { MarketProgramType } from '@/types/marketProgramType'
import getDuration, { convertToTimeZoneDateTime } from '@/utils/time'

function getFormattedDuration(startedAt?: string, endedAt?: string): string {
  if (!startedAt || !endedAt) {
    return '-'
  }
  return getDuration(startedAt, endedAt)
    .toFormat(DURATION_WITH_PLUS_PREFIX_FORMAT)
    .replace('00h', '')
    .replace('00m', '')
}

export const CustomerActivations = () => {
  const { customer, customerDetailsCommonBreadcrumbs, isFetchingCustomer, setPageConfig } = useCustomerDetails()
  const { t, i18n } = useTranslation()
  const [dateOfFirstActivation, setDateOfFirstActivation] = useState<DateTime | undefined>(undefined)
  const [timeRange, setTimeRange] = useState<TimeRange>({ from: NOW.minus({ years: 1 }), to: NOW })
  const [periodSelection, setPeriodSelection] = useState<Period>(Period.PAST_12_MONTHS)
  const [isLoading, setIsLoading] = useState(true)

  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery({
    uuid: customer.uuid!,
  })

  const { magActivations, isFetching: isFetchingMagActivations } = useActivationsFromMagQuery(
    {
      customerUuid: customer.uuid!,
      location: customer.location,
    },
    { enabled: Boolean(customer.uuid) && !isFetchingCustomer },
  )

  const { bffActivations, isFetching: isFetchingBffActivations } = useActivationsFromBffQuery(
    {
      siteId: customer.uuid!,
    },
    { enabled: Boolean(customer.uuid) && !isFetchingCustomer },
  )

  const { marketPrograms, isFetching: isFetchingMarketPrograms } = useMarketProgramsQuery()

  const isLoadingCustomer = !customer || isFetchingCustomer || isFetchingCustomerSettings
  const isLoadingActivationData = isFetchingMagActivations || isFetchingBffActivations || isFetchingMarketPrograms

  useEffect(() => {
    if (!isLoadingCustomer && !isLoadingActivationData && customerSettings?.localization.timeZone) {
      setTimeRange(
        calculatePeriodStartAndEndTime(
          periodSelection,
          timeRange,
          dateOfFirstActivation,
          customerSettings?.localization.timeZone,
        ),
      )
      setIsLoading(false)
    }
  }, [isLoadingCustomer, isLoadingActivationData])

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.activations'),
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      appBarContent: (
        <CustomerActivationsAppBar
          activationsData={activationsToShow ?? []}
          dateOfFirstActivation={dateOfFirstActivation}
          isLoading={isLoading}
          periodSelection={periodSelection}
          setPeriodSelection={setPeriodSelection}
          setTimeRange={setTimeRange}
          timeRange={timeRange}
          timezone={customerSettings?.localization.timeZone}
        />
      ),
      activeTab: CustomerDetailsRouteInformation.ACTIVATIONS.navigationPath,
    })
  }, [dateOfFirstActivation, timeRange, periodSelection, isLoadingCustomer, isLoadingActivationData])

  const mergedActivations: Activation[] = useMemo(() => {
    return mergeActivations(magActivations, bffActivations, marketPrograms)
  }, [magActivations, bffActivations, marketPrograms])

  const firstActivation = mergedActivations?.[mergedActivations.length - 1] ?? null

  useEffect(() => {
    if (firstActivation?.startedAt) {
      setDateOfFirstActivation(
        convertToTimeZoneDateTime(customerSettings?.localization.timeZone ?? 'UTC', firstActivation.startedAt),
      )
    }
  }, [firstActivation])

  const activationsToShow: Activation[] | undefined = useMemo(
    () =>
      mergedActivations?.filter(
        (a) =>
          a.endedAt &&
          DateTime.fromISO(a.endedAt, { zone: DateTime.utc().zone }) > timeRange.from &&
          DateTime.fromISO(a.endedAt, { zone: DateTime.utc().zone }) < timeRange.to,
      ),
    [mergedActivations, timeRange],
  )

  const columns: GridColDef<Activation>[] = [
    {
      field: 'startedAt',
      headerName: t('customer_details.activations.resources_activated'),
      flex: 1,
      valueFormatter: (value: Activation['startedAt']) =>
        formatLocalizedDateTimeMedWithSeconds(customerSettings?.localization.timeZone ?? '', i18n.language, value),
    },
    {
      field: 'endedAt',
      headerName: t('customer_details.activations.resources_deactivated'),
      flex: 1,
      valueGetter: (value: Activation['endedAt'], activation: Activation) => {
        return value ? getFormattedDuration(activation.startedAt, value) : '-'
      },
    },
    {
      field: 'marketProgram',
      headerName: t('common.market_program.label'),
      flex: 1,
      type: 'singleSelect',
      valueOptions: Array.from(getMarketProgramTypes(marketPrograms).values()).map((mp) => mp as MarketProgramType),
      getOptionLabel: (value) => (value ? t(`common.market_program.${value as MarketProgramType}`) : '-'),
    },
  ]

  if (isLoadingCustomer) return null

  const marketProgramTypes = getMarketProgramTypes(marketPrograms)
  const marketProgramsWithoutDetails: MarketProgramType[] = ['mfrrda-down', 'mfrrda-up']

  if (!isFetchingMarketPrograms && marketProgramTypes.size === 0) {
    return <NoMarketProgram />
  }

  return (
    <Box mt={3} sx={{ width: '100%' }}>
      <ActivationsChart
        activations={activationsToShow ?? []}
        fromDate={timeRange.from}
        isLoading={isLoading}
        marketPrograms={getDistinctMarketPrograms(activationsToShow ?? [])}
        toDate={timeRange.to}
      />
      <ActivationsDataGrid
        columns={columns}
        isLoading={isLoading}
        marketProgramOptions={getDistinctMarketPrograms(activationsToShow ?? [])}
        rowNavigateTo={(params: GridRowParams<Activation>) => {
          if (!params.row.marketProgram || marketProgramsWithoutDetails.includes(params.row.marketProgram!)) return ''
          const path = CustomerDetailsRouteInformation.ACTIVATION_DETAILS.navigationPath
            .replace(':activationId', params.row.id!.toString())
            .replace(':customerUuid', customer.uuid as string)

          return `/${path}`
        }}
        rows={activationsToShow ?? []}
      />
    </Box>
  )
}

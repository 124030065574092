import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'

const IntegrationsIndex = () => {
  const { t } = useTranslation()

  return <PageHeader isRoot pageTitle={t('common.integrations')} />
}

export default IntegrationsIndex

import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import { RRule } from 'rrule'
import { ALL_WEEKDAYS } from 'rrule/dist/esm/weekday'

import {
  AFRR_LIMIT_VALUE_DIVISOR,
  FFR_LIMIT_VALUE_DIVISOR,
  SPOT_PRICE_LIMIT_VALUE_DIVISOR,
} from '@/features/availability/constants/serviceRuleRates'
import type { AnalogWire } from '@/features/availability/types/analogWire'
import type { RelayWire } from '@/features/availability/types/relayWire'
import type { ResourceMinView } from '@/features/availability/types/resourceMinView'
import type { MarketProgramType } from '@/types/marketProgramType'
import { convertToTimeZoneDateTime, formatDateTimeWithLocale } from '@/utils/time'

export function formatResourceName(resource: RelayWire | AnalogWire | ResourceMinView): string {
  return resource.name ?? `Unknown resource [${resource.id}]`
}

export function getLimitValueColumnHeader(t: TFunction, marketProgramType: MarketProgramType | undefined) {
  switch (marketProgramType) {
    case 'afrr-up':
    case 'afrr-down':
      return t('customer_details.tabs.availability.availability_details.limit_value.price_per_mw.label')
    case 'max-price':
      return t('customer_details.tabs.availability.availability_details.limit_value.price.label')
    case 'ffr-up':
      return t('customer_details.tabs.availability.availability_details.limit_value.frequency.label')
    default:
      return ''
  }
}

export function formatLimitValue(
  t: TFunction,
  marketProgramType: MarketProgramType | undefined,
  limitValue: number,
): string {
  switch (marketProgramType) {
    case 'afrr-up':
    case 'afrr-down':
      return `${t('customer_details.tabs.availability.availability_details.limit_value.price.unit')} ${
        limitValue / AFRR_LIMIT_VALUE_DIVISOR
      }`
    case 'max-price':
      return `${t('customer_details.tabs.availability.availability_details.limit_value.price.unit')} ${
        limitValue / SPOT_PRICE_LIMIT_VALUE_DIVISOR
      }`
    case 'ffr-up':
      return `${limitValue / FFR_LIMIT_VALUE_DIVISOR} Hz`
    default:
      return 'N/A'
  }
}

export function getSelectedDays(recurrence) {
  try {
    const rRule = RRule.fromString(recurrence)
    if (rRule.options.byweekday) {
      return rRule.options.byweekday.map((day) => ALL_WEEKDAYS[day])
    } else {
      return ALL_WEEKDAYS
    }
  } catch {
    return []
  }
}

export function formatLocalizedDateMed(customerTimeZone: string, language: string, dateTime?: DateTime) {
  if (!dateTime) {
    return '-'
  }
  const timeZoneDateTime = convertToTimeZoneDateTime(customerTimeZone, dateTime.toString())
  return formatDateTimeWithLocale(timeZoneDateTime, language)
}

import type {
  ActivatedResource,
  BffActivatedResource,
  MagActivatedResource,
} from '@/features/activation/types/activatedResource'
import type { Activation, BffActivation, MagActivation } from '@/features/activation/types/activation'
import type { MarketProgramType } from '@/types/marketProgramType'

export function convertMagActivationToStandard(activation: MagActivation, marketProgram?: MarketProgramType) {
  return {
    id: activation.id,
    startedAt: activation.startedAt,
    endedAt: activation.endedAt,
    marketProgram: marketProgram,
  } as Activation
}

export function convertBffActivationToStandard(activation: BffActivation) {
  return {
    ...activation,
    marketProgram: activation.marketProgram?.toLowerCase().replaceAll('_', '-'),
  } as Activation
}

export function convertPresentActivationToStandard(
  magActivation: MagActivation | null,
  bffActivation: BffActivation | null,
  marketPrograms: Map<number | undefined, MarketProgramType | undefined>,
): Activation | undefined {
  if (magActivation) return convertMagActivationToStandard(magActivation, marketPrograms.get(magActivation?.serviceId))
  if (bffActivation) return convertBffActivationToStandard(bffActivation)
  return undefined
}

export function convertMagActivatedResourcesToStandard(magActivatedResources: MagActivatedResource[]) {
  return magActivatedResources.map(
    (resource) =>
      ({
        id: resource.id,
        resourceName: resource.wireName,
        sectionName: resource.wireSectionName,
        activatedAt: resource.activatedAt,
        deactivatedAt: resource.deactivatedAt,
      }) as ActivatedResource,
  )
}

export function convertBffActivatedResourcesToStandard(bffActivatedResources: BffActivatedResource[]) {
  return bffActivatedResources?.map(
    (resource) =>
      ({
        id: resource.resourceId,
        resourceName: resource.resourceName,
        activatedAt: resource.activatedAt,
        deactivatedAt: resource.deactivatedAt,
      }) as ActivatedResource,
  )
}

import { Stack } from '@mui/material'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import CustomAccordion from '@/components/layouts/CustomAccordion'
import type { MfaPreferredOption } from '@/features/user/types/user'

type UserSecurityAccordionProps = {
  children: ReactNode
  preferredOption: MfaPreferredOption
}

const UserSecurityAccordion = ({ children, preferredOption }: UserSecurityAccordionProps) => {
  const { t } = useTranslation()

  return (
    <CustomAccordion
      defaultExpanded
      {...(preferredOption && {
        statusProps: {
          color: 'success',
          label: t(`user_form.form.mfa_is_active_${preferredOption}`),
        },
      })}
      title={t('user_form.form.title_security')}
    >
      <Stack direction="column">{children}</Stack>
    </CustomAccordion>
  )
}

export default UserSecurityAccordion

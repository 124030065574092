import type { DateTime, DurationLike } from 'luxon'

export const ptusBetweenDates = (fromDate: DateTime, toDate: DateTime) => {
  const ptuDuration: DurationLike = { hour: 1 }
  let currentPtu = fromDate.plus({ hour: 0 })
  const ptus: DateTime[] = [currentPtu]
  while (currentPtu < toDate) {
    ptus.push(currentPtu.plus(ptuDuration))
    currentPtu = currentPtu.plus(ptuDuration)
  }
  return ptus
}
